import { DataGrid } from '@mui/x-data-grid';
import './Table.css';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
const Table = ({
  columns,
  data,
  rows,
  pageSize,
  getRowId,
  onSelectionCheckBox,
  hiddenSection,
  loading,
  setPage,
  totalRow,
  hideFooterSelectedRowCount
}) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    if ((rows.length === 0) & !loading) {
      const overlay = document.querySelector(
        '.MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-overlay'
      );
      if (overlay) {
        overlay.style.opacity = 1;
        setRender(!render);
      }
    }
  }, [rows]);



  return (
    <Box sx={{ mt: '8px', width: '100%', backgroundColor: '#fff' }}>
      <DataGrid
        rows={rows}
        getRowId={getRowId}
        onPageChange={(page) => setPage(page)}
        columns={columns}
        rowCount={totalRow}
        paginationMode="server"
        className={`${Math.ceil(totalRow / pageSize) < 2 && !data?.length && 'table_data'} ${
          loading && 'loading'
        }`}
        loading={loading}
        keepNonExistentRowsSelected
        pageSize={pageSize}
        getRowHeight={() => 'auto'}
        hideFooterSelectedRowCount={hideFooterSelectedRowCount}
        autoHeight={true}
        checkboxSelection={!hiddenSection}
        onSelectionModelChange={(id) => onSelectionCheckBox(id)}
        hideFooterPagination={Math.ceil(totalRow / pageSize) < 2}
      />
    </Box>
  );
};

export default Table;
