import dayjs from 'dayjs';

export const convertDate = (date, pattern) => {
  return dayjs(new Date(date)).format(pattern);
};

export const roundNumber = (number) => {
  const factor = Math.pow(10, 2);
  let tempNumber = (number * factor).toFixed(8); // Convert to string to handle precision issues
  tempNumber = Math.round(tempNumber);

  // Convert back to the original scale
  const result = tempNumber / factor;

  let formattedNumber = parseFloat(result).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return formattedNumber || '0.00';
};
