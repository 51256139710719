import { Typography } from '@material-ui/core';
import { Box } from '@mui/material';
import SelectDropdown from '../../SelectDropdown/SelectDropdown';
import '../ModalEdit.css';

export default function ModalEditUser({
  data,
  setData,
  validate,
  onKeyDownHandler,
  access,
  setValidate,
  fakePass,
  inputRef,
  setFakePass,
  noEdit
}) {
  return (
    <div>
      {noEdit ? (
        <Box sx={{ textAlign: 'center', position: 'absolute', top: 0, padding: '16px' }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Can't edit account as admin!
          </Typography>
        </Box>
      ) : (
        <Box>
          <div className="addUser_form_input">
            <div className="label">
              <p>
                Access <span>*</span>
              </p>
              <SelectDropdown
                data={access}
                selectType="selectAccount"
                textDefault="Admin"
                row={data.access}
                setData={(value) => {
                  setData((pre) => {
                    return { ...pre, access: value };
                  });
                }}
              />
            </div>
            <div className="label">
              <p>
                User Name <span>*</span>
              </p>
              <div className="user_password_edit">
                <input
                  onKeyDown={onKeyDownHandler}
                  type="text"
                  value={data.username}
                  onChange={(e) => {
                    setValidate(false);
                    setData((pre) => {
                      return { ...pre, username: e.target.value };
                    });
                  }}
                />
                <div className={`${data.userId ? 'disable' : ''}`}></div>
              </div>
              {validate && !data.username && (
                <span className="mess-error">Please enter username</span>
              )}
            </div>
            <div className="label">
              <p>
                Password <span>*</span>
              </p>
              <input
                onKeyDown={onKeyDownHandler}
                type="text"
                value={data.password || fakePass}
                ref={inputRef}
                onChange={(e) => {
                  setFakePass(e.target.value);
                  setValidate(false);
                  setData((pre) => {
                    return { ...pre, password: e.target.value };
                  });
                }}
              />
              {validate && !data.password && !fakePass && (
                <span className="mess-error">Please enter password</span>
              )}
              {validate && data.password && data.password.length && (
                <span className="mess-error">Minimum is 8 characters</span>
              )}
            </div>
          </div>
        </Box>
      )}
    </div>
  );
}
