import Logo from '../../Assets/Images/login-logo.jpg';
import './StockPrint.css';

export default function StockPrint({ data }) {
  const datePrint = () => {
    const date = new Date();
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    const monthReal = date.getMonth() + 1;
    const month = monthReal > 9 ? monthReal : '0' + monthReal;
    return day + '/' + month + '/' + date.getFullYear();
  };
  const dateTime = datePrint();

  return (
    <div className="invoice">
      <div className="invoice_header">
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        <div className="infor">
          <h1>Mainland Aquarium Pte Ltd</h1>
          <div className="invoice_no">
            <p>UEN:202207753H</p>
            <p>GST No: 202207753H</p>
          </div>
          <p>Northlink Building, 10 Admiralty Street #05-23 (S)757695</p>
          <p>Tel: (+65) 9187 8583</p>
          <p>Email: Mainlandaquariumpl@gmail.com</p>
        </div>
      </div>
      <h2>STOCKLIST</h2>
      <div className="invoice_company stock_print">
        <p>
          <span>Company:</span>
          <span></span>
        </p>
        <p>
          <span></span>
          <span></span>
        </p>
      </div>
      <div className="invoice_company stock_print">
        <p>
          <span>Address:</span>
          <span></span>
        </p>
        <p>
          <span>Date:</span>
          <span>{dateTime}</span>
        </p>
      </div>
      <div className="invoice_table">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Fish Name</th>
              <th>Size</th>
              <th>Pricing</th>
              <th>Order Quantity</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item?.Fish?.fishName}</td>
                <td>{item?.Fish?.size}</td>
                <td>
                  <div>
                    <span>$</span>
                    <span>{item.pricing.toFixed(2)}</span>
                  </div>
                </td>
                <td>{item.orderQuantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
