/* eslint-disable react-hooks/exhaustive-deps */
import CheckIcon from '@mui/icons-material/Check';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import html2pdf from 'html2pdf.js';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { addTaxOrder, getOrders, printOrder, refundOrder } from '../../Api/order';
import { ORDER_STATUS } from '../../common/constants';
import FulfillmentCp from '../../components/Fulfillment/FulfillmentCp';
import Loading from '../../components/Loading/Loading';
import TabPanel from '../../components/TabPane/TabPane';
import { updateInventoryByFish } from './../../Api/inventory';
import './Fulfillment.css';
import ModalEdit from '../../components/ModalEdit/ModalEdit';
import ModalGst from '../../components/ModalGST/ModalGST';
import Invoice from '../../components/Invoice/Invoice';

export default function Fulfillment() {
  const queryOrder = {
    skip: 0,
    take: 10,
    orderBy: 'desc',
    deliveryOrderNo: '',
    deliveryDate: '',
    companyName: '',
    orderStatus: [ORDER_STATUS.FULFILLMENT_CREATED]
  };
  const [query, setQuery] = useState(queryOrder);
  const [loading, setLoading] = useState(false);
  const [orderSelect, setOrderSelect] = useState([]);
  const [orderPrint, setOrderPrint] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowTotal, setRowTotal] = useState(0);
  const [value, setValue] = useState(0);
  const [dataOrder, setDataOrder] = useState();
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalGst, setOpenModalGst] = useState(false);
  const [viewOnly, setViewOnLy] = useState(false);
  const [orderPrints, setOrderPrints] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [gstFinal, setGstFinal] = useState({ gst: '', isGst: true, default: '' });
  const componentRef = useRef();

  const access = localStorage.getItem('access');
  const labelRef = useRef(null);

  useEffect(() => {
    const newQuery = { ...query, skip: query.take * page };
    getOrdersList(newQuery);
  }, [page, query]);

  const columnsFulfill = [
    { field: 'no', headerName: 'No.', minWidth: 50, maxWidth: 50, type: 'number' },
    {
      field: 'deliveryDate',
      headerName: 'Delivery Date',
      minWidth: 120,
      maxWidth: 120,
      valueGetter: (params) => new Date(params.row.deliveryDate).toLocaleDateString()
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.Supplier?.companyName
    },
    {
      field: 'marking',
      headerName: 'Marking',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p key={index}>{order.marking}</p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'fishName',
      headerName: 'Description',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p key={index}>{order.Fish?.fishName}</p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'size',
      headerName: 'Size',
      minWidth: 80,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p key={index}>{order.originSize || order.Fish.size}</p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      type: 'number',
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p style={{ textAlign: 'right' }} key={index}>
                {order.quantity?.toLocaleString('vi-VN')}
              </p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'tankNo',
      headerName: 'Tank No.',
      type: 'number',
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p style={{ textAlign: 'right' }} key={index}>
                {order.Fish?.Inventory.map((e) => e.tankNo).join(', ')}
              </p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'noStock',
      headerName: 'No Stock',
      minWidth: 90,
      maxWidth: 90,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p key={index} className="icon-nostock">
                {order.noStock ? <CheckIcon sx={{ color: 'green' }} /> : null}
              </p>
            ))}
          </div>
        );
      }
    }
  ];

  const columnsPrint = [
    { field: 'no', headerName: 'No.', minWidth: 50, maxWidth: 50, type: 'number' },
    {
      field: 'deliveryDate',
      headerName: 'Delivery Date',
      minWidth: 120,
      maxWidth: 120,
      valueGetter: (params) => new Date(params.row.deliveryDate).toLocaleDateString()
    },
    {
      field: 'invoiceNo',
      headerName: 'invoiceNo',
      minWidth: 100,
      maxWidth: 100,
      valueGetter: (params) => params.row.invoiceNo
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.Supplier?.companyName
    },
    {
      field: 'marking',
      headerName: 'Marking',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p key={index}>{order.marking}</p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'fishName',
      headerName: 'Description',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p key={index}>{order.Fish?.fishName || order.fishName}</p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'size',
      headerName: 'Size',
      minWidth: 80,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p key={index}>{order.originSize || order.Fish.size}</p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      type: 'number',
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p style={{ textAlign: 'right' }} key={index}>
                {order.quantity?.toLocaleString('vi-VN')}
              </p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'printBy',
      headerName: 'Print By',
      minWidth: 90,
      maxWidth: 90,
      valueGetter: (params) => params.row?.User?.username
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div className="action-refunds">
          <RestartAltIcon
            sx={{ color: '#d32f2f' }}
            onClick={() => {
              setDataOrder(params.row);
              setOpenModalEdit(true);
              setViewOnLy(false);
            }}
          />
          <VisibilityIcon
            onClick={() => {
              setDataOrder(params.row);
              setOpenModalEdit(true);
              setViewOnLy(true);
            }}
          />
          <PrintIcon
            onClick={() => {
              setOpenModalGst(true);
              setOrderPrints([params.row]);
              setInvoiceNo(params.row?.invoiceNo);
            }}
          />
        </div>
      )
    }
  ];

  const PrintLable = useReactToPrint({
    content: () => labelRef.current,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName('html')[0];
        await html2pdf()
          .set({
            margin: 0,
            filename: `Label_${Math.floor(100000 + Math.random() * 900000)}`,
            jsPDF: { unit: 'cm', format: [10, 2.5], orientation: 'l' }
          })
          .from(html)
          .save();
      }
    }
  });

  const getListFIshUpdate = async (listInven) => {
    let listTankNo = Array.from(new Set(listInven.map((inven) => inven.tankNo)));
    let updateList = listTankNo.map((item) => ({ tankNo: item, quantity: 0 }));
    updateList.forEach((inven1) => {
      listInven.forEach((inven2) => {
        if (inven1.tankNo === inven2.tankNo) {
          inven1.quantity = inven1.quantity + inven2.quantity;
          inven1.fishId = inven2.fishId;
        }
      });
    });
    updateList.forEach((inven) => {
      handleUpdateInventoryByFish(inven);
    });
  };

  const handlePrintOrder = async () => {
    let noStock = false;
    orderPrint.forEach((order) => {
      order.OrderItem.forEach((item) => {
        if (item.noStock) {
          noStock = true;
          return;
        }
      });
    });
    if (noStock) {
      toast.error('Cannot print order no stock!', { autoClose: 2000, theme: 'colored' });
      return;
    }
    let inventory = [];
    orderPrint.forEach((order) => {
      order.OrderItem.forEach((item) => {
        inventory.push({
          tankNo: item.Fish?.Inventory[0].tankNo,
          fishId: item.Fish?.id,
          quantity: item.quantity
        });
      });
    });
    getListFIshUpdate(inventory);
    setLoading(true);
    const order = {
      orderStatus: ORDER_STATUS.FULFILLMENT_PRINT
    };
    try {
      const response = await printOrder(order, orderSelect);
      if (response) {
        setQuery((pre) => ({ ...pre, orderStatus: [ORDER_STATUS.FULFILLMENT_PRINT] }));
        PrintLable();
        toast.success('Printed Label successfully!', { autoClose: 2000, theme: 'colored' });
        setValue(1);
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const handleRePrint = () => {
    const noStock = orderPrint.some((e) => e.noStock === true);
    if (noStock) {
      toast.error('Cannot print order no stock!', { autoClose: 2000, theme: 'colored' });
      return;
    }
    PrintLable();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setOrderSelect([]);
    setRows([]);
    if (newValue === 0) {
      setQuery(queryOrder);
    } else {
      setQuery((pre) => ({ ...pre, orderStatus: [ORDER_STATUS.FULFILLMENT_PRINT] }));
    }
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  const getOrdersList = async (param) => {
    setLoading(true);
    try {
      const response = await getOrders(param);
      if (response) {
        const data = response.data.orders.map((item, index) => ({
          ...item,
          no: page * query.take + index + 1
        }));
        setRowTotal(response.data.count);
        setRows(data);
        setLoading(false);
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const selectOrder = (orderId) => {
    let orderList = [];
    rows.forEach((row) => {
      orderId.forEach((id) => {
        if (row.deliveryOrderNo === id) {
          orderList.push(row);
        }
      });
    });
    setOrderPrint(orderList);
    setOrderSelect(orderId);
  };

  const handleUpdateInventoryByFish = async (inventory) => {
    try {
      await updateInventoryByFish(inventory.fishId, inventory.quantity);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAction = async () => {
    setLoading(true);
    const newOrderItem = dataOrder.OrderItem.map((item) => ({ ...item }));
    const newOrder = {
      items: newOrderItem,
      deliveryOrderNo: dataOrder?.deliveryOrderNo,
      supplierId: dataOrder?.supplierId,
      remark: dataOrder.remark,
      deliveryDate: dataOrder.deliveryDate
    };
    try {
      const response = await refundOrder(dataOrder.deliveryOrderNo, newOrder);
      if (response) {
        setLoading(false);
        toast.success('Refunds successfully!', { autoClose: 2000, theme: 'colored' });
        const indexOrder = rows.findIndex(
          (item) => item.deliveryOrderNo === dataOrder.deliveryOrderNo
        );
        const newRows = JSON.parse(JSON.stringify(rows));
        newRows[indexOrder] = dataOrder;
        setRows(newRows);
        setOpenModalEdit(false);
        setOrderSelect([]);
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      setLoading(false);
    }
  };

  const handleTaxOrder = async (orderId, body) => {
    try {
      await addTaxOrder(orderId, body);
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
    }
  };

  const createInvoice = useReactToPrint({
    content: () => componentRef.current,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName('html')[0];
        await html2pdf()
          .set({
            margin: [1, 6, 0, 6],
            filename:
              `Invoice_${invoiceNo}` || `Invoice_${Math.floor(100000 + Math.random() * 900000)}`
          })
          .from(html)
          .toPdf()
          .get('pdf')
          .then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10.5);
              pdf.setTextColor('#000');
              pdf.text(
                'Page ' + i,
                pdf.internal.pageSize.getWidth() / 2.15,
                pdf.internal.pageSize.getHeight() - 5.85
              );
            }
          })
          .save();
      }
    }
  });

  const handleCreateInvoice = () => {
    createInvoice();
    handleTaxOrder(invoiceNo, { tax: !!gstFinal.gst });
    setOpenModalGst(false);
    toast.success('Create invoice successfully!', { autoClose: 2000, theme: 'colored' });
  };

  return (
    <div className="fulfilment_page">
      {loading ? <Loading open={loading} /> : null}
      {openModalEdit ? (
        <ModalEdit
          open={openModalEdit}
          handleClose={() => setOpenModalEdit(false)}
          data={dataOrder}
          setData={setDataOrder}
          handleAction={handleAction}
          viewOnly={viewOnly}
          page="ORDER"
        />
      ) : null}
      {openModalGst ? (
        <ModalGst
          open={openModalGst}
          handleClose={() => setOpenModalGst(false)}
          handleAction={handleCreateInvoice}
          setGstFinal={setGstFinal}
          gstFinal={gstFinal}
        />
      ) : null}
      <Box sx={{ width: '100%' }}>
        {access === 'admin' && (
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Fulfillment" {...a11yProps(0)} />
              <Tab label="Printed Label" {...a11yProps(1)} />
            </Tabs>
          </Box>
        )}
        <TabPanel value={value} index={0}>
          <FulfillmentCp
            colums={columnsFulfill}
            rows={rows}
            loading={loading}
            query={query}
            rowTotal={rowTotal}
            setPage={setPage}
            orderSelect={orderSelect}
            setQuery={setQuery}
            selectOrder={selectOrder}
            orderPrint={orderPrint}
            handlePrintOrder={handlePrintOrder}
            labelRef={labelRef}
            value="printlabel"
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FulfillmentCp
            colums={columnsPrint}
            rows={rows}
            loading={loading}
            query={query}
            rowTotal={rowTotal}
            setPage={setPage}
            orderSelect={orderSelect}
            setQuery={setQuery}
            selectOrder={selectOrder}
            orderPrint={orderPrint}
            handlePrintOrder={handleRePrint}
            labelRef={labelRef}
            value="reprint"
          />
        </TabPanel>
      </Box>
      <div id="content" ref={componentRef}>
        <Invoice data={orderPrints} gst={gstFinal} invoiceNo={invoiceNo} />
      </div>
    </div>
  );
}
