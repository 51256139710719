import { API_BASE, parseQuery } from './apiConfig';

export const getFishes = async (param) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.get(`api/fish${parseQuery(param)}`, token);
  return data;
};

export const getFishById = async (id) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.get(`api/fish/${id}`, token);
  return data;
};

export const createFish = async (fish) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.post('api/fish', fish, token);
  return data;
};

export const updateFish = async (fishId, fish) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.patch(`api/fish/${fishId}`, fish, token);
  return data;
};

export const updateReOder = async (listFishId, reorder) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  let url = '';
  listFishId.forEach((id, index) => {
    if (index < listFishId.length - 1) {
      url += `id=${id}&`;
    } else {
      url += `id=${id}`;
    }
  });
  const { data } = await API_BASE.patch(`api/fish?${url}`, reorder, token);
  return data;
};

export const deleteFish = async (fishId) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.delete(`api/fish/${fishId}`, token);
  return data;
};

export const deleteFishes = async (listFishId) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  let url = '';
  listFishId.forEach((id, index) => {
    if (index < listFishId.length - 1) {
      url += `id=${id}&`;
    } else {
      url += `id=${id}`;
    }
  });
  const { data } = await API_BASE.delete(`api/fish?${url}`, token);
  return data;
};
