import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Box, Button, TextField, createFilterOptions } from '@mui/material';
import { useEffect, useState } from 'react';
import SelectDropdown from '../../SelectDropdown/SelectDropdown';
import '../ModalEdit.css';
import './ModalEditOrder.css';
import { ROUTER } from '../../../common/constants';
import { useLocation } from 'react-router-dom';

export default function ModalEditOrder({
  data,
  setData,
  validDate,
  validate,
  onKeyDownHandler,
  fishes,
  companies,
  inputRef,
  valueDeliveryDate,
  selectDeliveryOrder,
  setValidate
}) {
  const duplicateData = {
    duplicate: false,
    fishName: '',
    index: '',
    size: '',
    fishId: ''
  };

  let [duplicateFish, setDuplicateFish] = useState(duplicateData);
  let [oldFishes, setOldFishes] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const listFishOrder = data.OrderItem.map((item) => item.fishId);
    setOldFishes(listFishOrder);
  }, []);

  const addDescription = () => {
    const newItems = [...data.OrderItem, { fishId: '', quantity: '', originPrice: '' }];
    setData((pre) => ({
      ...pre,
      OrderItem: newItems
    }));
  };

  const changePriceOrderItem = (e, index) => {
    if (
      !isNaN(e.target.value) ||
      !e.target.value ||
      (location.pathname === ROUTER.FULFILLMENT && Number(e.target.value) >= 0)
    ) {
      const value = e.target.value === '' ? '' : +e.target.value;
      const newOrders = JSON.parse(JSON.stringify(data));
      const newItems = [...newOrders.OrderItem];
      newItems[index] = {
        ...newItems[index],
        originPrice: value
      };
      setData((pre) => {
        return { ...pre, OrderItem: newItems };
      });
    }
  };

  const changeQuantityOrderItem = (e, index) => {
    if (
      Number(e.target.value) ||
      !e.target.value ||
      (location.pathname === ROUTER.FULFILLMENT && Number(e.target.value) >= 0)
    ) {
      const value = e.target.value === '' ? '' : +e.target.value;
      const newOrders = JSON.parse(JSON.stringify(data));
      const newItems = [...newOrders.OrderItem];
      newItems[index] = {
        ...newItems[index],
        quantity: value
      };
      setData((pre) => {
        return { ...pre, OrderItem: newItems };
      });
    }
  };

  const changeMarkingOrderItem = (value, index, key) => {
    const newOrders = JSON.parse(JSON.stringify(data));
    const newItems = [...newOrders.OrderItem];
    newItems[index] = {
      ...newItems[index],
      [key]: value
    };
    setData((pre) => {
      return { ...pre, OrderItem: newItems };
    });
  };
  const changeSizeOrderItem = (e, index) => {
    const value = e.target.value;
    const newOrders = JSON.parse(JSON.stringify(data));
    const newItems = [...newOrders.OrderItem];
    newItems[index] = {
      ...newItems[index],
      originSize: value
    };
    setData((pre) => {
      return { ...pre, OrderItem: newItems };
    });
  };

  const changeFishOrderItem = (value, index) => {
    const newOrders = JSON.parse(JSON.stringify(data));
    const newItems = [...newOrders.OrderItem];
    // const duplicate = data.OrderItem.some(
    //   (order) =>
    //     (order.fishId === value?.id && value?.id) || (order?.Fish?.id === value?.id && value?.id)
    // );
    // if (duplicate) {
    //   const checkDuplicate = {
    //     duplicate: true,
    //     fishId: value.id,
    //     index: index,
    //     size: value.size,
    //     fishName: value.fishName
    //   };
    //   setDuplicateFish(checkDuplicate);
    // } else {
    //   setDuplicateFish(duplicateData);
    // }
    if (newItems.Fish) {
      newItems[index].Fish.id = value?.id;
      newItems[index].Fish.fishName = value?.fishName;
    } else {
      newItems[index] = {
        ...newItems[index],
        fishId: Number(value?.id),
        fishName: value?.fishName,
        size: value?.size,
        originPrice: value?.priceOut
      };
      setData((pre) => {
        return { ...pre, OrderItem: newItems };
      });
    }
  };

  const removeItemOrder = (index) => {
    if (data.OrderItem.length === 1) {
      return;
    }
    const newOrderItem = data.OrderItem.filter((order, i) => i !== index);
    if (duplicateFish.index === index) {
      setDuplicateFish(duplicateData);
    }
    setData((pre) => ({
      ...pre,
      OrderItem: newOrderItem
    }));
  };

  const defaultValue = (order) => {
    if (order.Fish) {
      return order.Fish;
    } else if (order.fishId) {
      return order;
    } else {
      return null;
    }
  };

  const defaultValueCompany = (company) => {
    if (company.Supplier) {
      return company.Supplier;
    } else if (company.SupplierId) {
      return company;
    } else {
      return null;
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => option.fishName
  });

  const filterCompany = createFilterOptions({
    stringify: (option) => option.companyName
  });
  const formatSize = (originSize, size) => {
    if (originSize == '' || originSize) {
      return originSize;
    }
    return size;
  };

  return (
    <div>
      <Box className="modal-order" sx={{ p: '0 0.7rem' }}>
        <div className="addOrder_form_input">
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <div className="label label-company">
              <p>
                Company Name <span>*</span>
              </p>
              <Autocomplete
                id="combo-box-company"
                options={companies}
                getOptionLabel={(option) => {
                  return option?.companyName;
                }}
                filterOptions={filterCompany}
                defaultValue={() => defaultValueCompany(data)}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option?.supplierId}>
                      {option?.companyName}
                    </li>
                  );
                }}
                className={`${location.pathname === ROUTER.FULFILLMENT ? 'disable' : ''}`}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                sx={{ width: '100%' }}
                onOpen={() => setValidate(false)}
                onChange={(e, value) => {
                  setData((pre) => {
                    return {
                      ...pre,
                      Supplier: {
                        ...pre.Supplier,
                        companyName: value?.companyName
                      },
                      supplierId: +value?.supplierId || ''
                    };
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Company" variant="outlined" />
                )}
              />
              {/* <SelectDropdown
                data={companies}
                selectType="selectCompany"
                textDefault="Select Company"
                row={data}
                setData={(value) =>
                  setData((pre) => {
                    return { ...pre, supplierId: +value };
                  })
                }
              /> */}
              {validate && !data.supplierId && (
                <span className="mess-error">Please enter company name</span>
              )}
            </div>

            <div className="label label-delivery-date">
              <p>Delivery Date</p>
              <div className="delivery_date" onClick={() => inputRef.current.showPicker()}>
                <input
                  onKeyDown={onKeyDownHandler}
                  type="text"
                  value={valueDeliveryDate(data.deliveryDate, 'text') || ''}
                  readOnly
                />
                <CalendarMonthIcon className="calendar_icon" />
                <input
                  onKeyDown={onKeyDownHandler}
                  ref={inputRef}
                  value={valueDeliveryDate(data.deliveryDate, 'date') || ''}
                  type="date"
                  onChange={(e) => {
                    selectDeliveryOrder(e.target.value);
                  }}
                />
              </div>
              {validDate && <span className="mess-error">Invalid delivery date</span>}
            </div>
          </Box>
          <div className="label orderList" id="order">
            <div className="label-order">
              <div className="label-description">
                <p>
                  Description <span>*</span>
                </p>
                <Button
                  onClick={addDescription}
                  className="button-action"
                  sx={{
                    backgroundColor: '#262b40',
                    color: '#fff',
                    minWidth: 'auto',
                    height: 'fit-content',
                    p: '0',
                    '&:hover': {
                      backgroundColor: ' #757575'
                    }
                  }}
                >
                  <AddIcon sx={{ fontSize: '1.2rem' }} />
                </Button>
              </div>
              <div className="label-size">
                <p>Size</p>
              </div>
              <div className="label-price">
                <p>
                  Price <span>*</span>
                </p>
              </div>
              <div className="label-quantity">
                <p>
                  Quantity <span>*</span>
                </p>
              </div>
              <div className="label-marking">
                <p>Marking</p>
              </div>
            </div>
            {data?.OrderItem?.map((order, index) => (
              <Box
                sx={{ display: 'flex', gap: '10px', marginBottom: '8px', alignItems: 'flex-start' }}
                className="modal-order-container"
                key={index}
              >
                <Box
                  sx={{
                    flex: 2,
                    '& .select_dropdown': {
                      height: '42px'
                    }
                  }}
                >
                  <Autocomplete
                    id="combo-box-demo"
                    options={fishes}
                    getOptionLabel={(option) => {
                      if (order?.Fish?.id) {
                        return `${order.Fish?.fishName} (${order.Fish?.size})`;
                      } else {
                        return order.fishName ? `${order.fishName}(${order.size})` : '';
                      }
                    }}
                    filterOptions={filterOptions}
                    defaultValue={() => defaultValue(order)}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.fishName}({option.size})
                        </li>
                      );
                    }}
                    className={`${
                      oldFishes.includes(order.fishId) && location.pathname === ROUTER.FULFILLMENT
                        ? 'disable'
                        : ''
                    }`}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    sx={{ width: '245px' }}
                    onOpen={() => setValidate(false)}
                    onChange={(e, value) => {
                      changeFishOrderItem(value, index);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Description" variant="outlined" />
                    )}
                  />
                  {validate && !order.fishId && !order?.Fish?.id && (
                    <span className="mess-error">Please enter Description</span>
                  )}
                </Box>
                <Box className="quantity-box">
                  <input
                    className={`input-quantity ${validate && !order.size ? 'no-size' : ''}`}
                    onKeyDown={onKeyDownHandler}
                    type="text"
                    placeholder="Size"
                    // value={order.originSize || order.size}
                    value={formatSize(order.originSize, order.Fish?.size || order.size)}
                    onChange={(e) => {
                      changeSizeOrderItem(e, index);
                    }}
                  />
                </Box>

                <Box className="price-box">
                  <input
                    className={`input-price ${validate && !order.originPrice ? 'no-price' : ''}`}
                    onKeyDown={onKeyDownHandler}
                    type="number"
                    placeholder="Price"
                    value={order.originPrice ?? ''}
                    onChange={(e) => {
                      changePriceOrderItem(e, index);
                    }}
                  />
                </Box>
                <Box className="quantity-box">
                  <input
                    className={`input-quantity ${validate && !order.quantity ? 'no-quantity' : ''}`}
                    onKeyDown={onKeyDownHandler}
                    type="text"
                    placeholder="Quantity"
                    value={order.quantity ?? ''}
                    onChange={(e) => {
                      changeQuantityOrderItem(e, index);
                    }}
                  />
                </Box>

                <Box className="marking-box" sx={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    onKeyDown={onKeyDownHandler}
                    type="text"
                    value={order.marking || ''}
                    onChange={(e) => {
                      changeMarkingOrderItem(e.target.value, index, 'marking');
                    }}
                  />
                  <ClearIcon
                    sx={{ cursor: 'pointer', fontSize: '1rem', color: '#d32f2f' }}
                    onClick={() => removeItemOrder(index)}
                    className="button-action"
                  />
                </Box>
              </Box>
            ))}
            <div className="label label-remark">
              <p>Remarks</p>
              <input
                onKeyDown={onKeyDownHandler}
                type="text"
                placeholder="Input Remarks"
                value={data.remark || ''}
                onChange={(e) => {
                  setData((pre) => {
                    return { ...pre, remark: e.target.value };
                  });
                }}
              />
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}
