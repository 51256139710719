/* eslint-disable react-hooks/exhaustive-deps */
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import { toast } from 'react-toastify';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { Grid } from '@mui/material';
import { getFishes, updateReOder } from '../../Api/fish';

import {
  getEmptyTanks,
  getInventories,
  updateInventory,
  updateManyInventories,
  deleteInventory
} from '../../Api/inventory';

import Loading from '../../components/Loading/Loading';
import TabPanel from '../../components/TabPane/TabPane';
import Table from '../../components/Table/Table';
import './Inventory.css';

export default function Inventory() {
  const query = {
    skip: 0,
    take: 10,
    orderBy: 'desc',
    tankNo: ''
  };
  const queryOutStock = {
    skip: 0,
    take: 10,
    orderBy: 'desc',
    isOutOfStock: true
  };
  const inventoryParam = {
    tankNo: '',
    fishId: '',
    quantity: '',
    secondFishId: '',
    secondFishQuantity: ''
  };
  const [queyInventory, setQueryInventory] = useState(query);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [queyStock, setQueryStock] = useState(queryOutStock);
  const [loading, setLoading] = useState(false);
  const [inventories, setInventories] = useState([]);
  const [tankNumber, setTankNumber] = useState(0);
  const [fishes, setFishes] = useState([]);
  const [outStock, setOutStock] = useState([]);
  const [outStockUpdate, setOutStockUpdate] = useState([]);
  const [outStockId, setOutStockId] = useState([]);
  const [inventoriesId, setInventoriesId] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [totalOutStock, setTotalOutStock] = useState(0);
  const [value, setValue] = useState(0);
  const defaultDeletedItem = { supplierId: '', invoiceNo: '', amount: '', paid: false, orderBy: 'desc' };
  const [deletedItem, setDeletedItem] = useState(defaultDeletedItem);
  const [inventoryUpdate, setInventoryUpdate] = useState(inventoryParam);
  const [validate, setValidate] = useState(false);
  const [tankInput, setTankInput] = useState(1);
  const [newFishId, setNewFishId] = useState(0);
  const [quantityInput, setQuantityInput] = useState('');
  const [data, setData] = useState([]);

  const getListInventories = async (param) => {
    setLoading(true);
    try {
      const response = await getInventories(param);
      if (response) {
        const inventory = response.data.inventories.map((inven) => {
          if (inven.quantity < 0) {
            inven.quantity = 0;
          }
          return {
            ...inven,
            noOfDay: getNoOfDay(inven.updateAt, inven.quantity)
          };
        });
        setTotalRow(response.data.count);
        setInventories(inventory);
        setInventoryUpdate(inventoryParam);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      setLoading(false);
    }
  };

  const handleUpdateInventory = async () => {
    if (!inventoryUpdate.quantity) {
      toast.error('Quantity of fish in tankNo must be greater than 0!', {
        autoClose: 2000,
        theme: 'colored'
      });
      return;
    }
    setLoading(true);
    try {
      const response = await updateInventory(inventoryUpdate.tankNo, inventoryUpdate);
      if (response) {
        getListInventories(queyInventory);
        getEmptyTanksNumber();
        setInventoriesId([]);
        getFishList({ inInventory: false });
        toast.success('Save successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      console.log(error);
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      setLoading(false);
    }
  };

  const handleAddFishToVirtualInventory = async (newInventory) => {
    if (!newInventory.quantity) {
      toast.error('Quantity of fish in tankNo must be greater than 0!', {
        autoClose: 2000,
        theme: 'colored'
      });
      return;
    }
    setLoading(true);
    try {
      newInventory.parentTankNoId = newInventory.tankNo;
      const response = await updateInventory(newInventory.tankNo, newInventory);
      if (response) {
        getListInventories(queyInventory);
        getEmptyTanksNumber();
        setInventoriesId([]);
        getFishList({ inInventory: false });
        toast.success('Save successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      console.log(error);
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      setLoading(false);
    }
  };

  const handleUpdateManyInventories = async () => {
    setLoading(true);
    let inventoriesList = [];
    inventoriesId.forEach((tankNo) => {
      inventories.forEach((inven) => {
        if (inven.tankNo === tankNo) {
          inventoriesList.push(inven);
        }
      });
    });
    const notQuantity = inventoriesList.some((inven) => inven.quantity === 0);
    const notFish = inventoriesList.some((inven) => Boolean(inven.fishId) === false);
    if (notQuantity) {
      setLoading(false);
      toast.error('Quantity of fish in tankNo must be greater than 0!', {
        autoClose: 2000,
        theme: 'colored'
      });
    } else if (notFish) {
      toast.error("Haven't imported description yet!", { autoClose: 2000, theme: 'colored' });
      return;
    } else {
      try {
        const response = await updateManyInventories({ inventories: inventoriesList });
        if (response) {
          getListInventories(queyInventory);
          getEmptyTanksNumber();
          getFishList({ inInventory: false });
          setInventoriesId([]);
          toast.success('Save successfully!', { autoClose: 2000, theme: 'colored' });
        }
      } catch (error) {
        console.log(error);
        const mess =
          error?.response?.data?.errors ||
          error?.response?.data?.message ||
          'Something went wrong!';
        toast.error(mess, { autoClose: 2000, theme: 'colored' });
        setLoading(false);
      }
    }
  };

  const getEmptyTanksNumber = async () => {
    try {
      const response = await getEmptyTanks();
      if (response) {
        setTankNumber(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteInventory = async () => {
    setLoading(true);
    let response;
    try {
      response = await deleteInventory(deletedItem.tankNo);
      if (response) {
        setDeletedItem(defaultDeletedItem);
        setOpenModalDelete(false);
        loadData(value, page);
        toast.success('Delete successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const getFishList = async (param) => {
    if (value === 1) {
      setLoading(true);
    }
    try {
      const response = await getFishes(param);
      if (response) {
        setFishes(response.data.fish);
        setOutStock(response.data.fish);
        setTotalOutStock(response.data.fish.length);

        if (value === 1) {
          setLoading(false);
        }
      }
    } catch (error) {
      if (value === 1) {
        setLoading(false);
      }
    }
  };

  const loadData = (value, page) => {
    if (value === 1) {
      const newQuery = { ...queyStock, skip: query.take * page };
      setQueryStock(newQuery);
      getFishList(newQuery);
    } else {
      const newQuery = { ...query, skip: query.take * page };
      setQueryInventory(newQuery);
      getListInventories(newQuery);
      getFishList({ inInventory: false });
    }

  }

  useEffect(() => {
    loadData(value, page);
  }, [value, page]);
  const handleGetFishById = (id, tankNo) => {
    if (!id) {
      setValidate(true);
    } else {
      setValidate(false);
    }
    const fishSelect = fishes?.find((item) => item?.id === +id);
    // const newListFish = fishes.filter((fish) => fish.id !== +id);
    // setFishes(newListFish);
    const indexSelect = inventories.findIndex((inventory) => inventory.tankNo === tankNo);
    selectToAddFish(tankNo);
    let newInventories;
    if (fishSelect) {
      newInventories = inventories.map((inventory, index) => {
        if (index === indexSelect) {
          setInventoryUpdate({
            ...inventory,
            fishId: fishSelect.id,
            Fish: fishSelect,
            isUpdate: true
          });
          return { ...inventory, fishId: fishSelect.id, Fish: fishSelect };
        }
        return inventory;
      });
    } else {
      newInventories = inventories.map((inventory, index) => {
        if (index === indexSelect) {
          setInventoryUpdate({ ...inventory, fishId: null, Fish: null });
          return { ...inventory, fishId: null, Fish: null };
        }
        return inventory;
      });
    }
    setInventories(newInventories);
  };

  const handleGetFishSecondById = (id, tankNo) => {
    if (!id) {
      setValidate(true);
    } else {
      setValidate(false);
    }
    const fishSelect = fishes?.find((item) => item?.id === +id);
    // const newListFish = fishes.filter((fish) => fish.id !== +id);
    // setFishes(newListFish);
    const indexSelect = inventories.findIndex((inventory) => inventory.tankNo === tankNo);
    selectToAddFish(tankNo);
    let newInventories;
    if (fishSelect) {
      newInventories = inventories.map((inventory, index) => {
        if (index === indexSelect) {
          setInventoryUpdate({
            ...inventory,
            secondFishId: fishSelect.id,
            isUpdate: true
          });
          return { ...inventory, secondFishId: fishSelect.id, secondFishId: fishSelect };
        }
        return inventory;
      });
    } else {
      newInventories = inventories.map((inventory, index) => {
        if (index === indexSelect) {
          setInventoryUpdate({ ...inventory, secondFishId: null, secondFishId: null });
          return { ...inventory, secondFishId: null, secondFishId: null };
        }
        return inventory;
      });
    }
    setInventories(newInventories);
  };

  const handleInputQuantity = (tankNo, e) => {
    const newInventories = inventories.map((inventory) => {
      if (inventory.tankNo === tankNo) {
        setInventoryUpdate({ ...inventory, quantity: e });
        return { ...inventory, quantity: e, isUpdate: true };
      }
      return inventory;
    });
    const listTank = [...inventoriesId];
    if (!listTank.includes(tankNo)) {
      listTank.push(tankNo);
    }
    setInventoriesId(listTank);
    setInventories(newInventories);
  };

  const handleUpdateSubmit = async () => {
    try {
      const res = await updateInventory(inventoryUpdate.tankNo, inventoryUpdate);

      toast.success('success', { autoClose: 2000, theme: 'colored' });
    } catch (e) {
      toast.error(e, { autoClose: 2000, theme: 'colored' });
    }
    inventoriesId.length > 1 ? handleUpdateManyInventories() : handleUpdateInventory();
  };

  const handleReOderFish = async (data) => {
    const idList = data.map((fish) => fish.id);
    try {
      const response = await updateReOder(idList, { reOrder: true });
      if (response) {
        return true;
      }
    } catch (error) {
      setLoading(false);
      return false;
    }
  };

  const handleNotReOderFish = async (data) => {
    const idList = data.map((fish) => fish.id);
    try {
      const response = await updateReOder(idList, { reOrder: false });
      if (response) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const handleOutOfStock = async () => {
    const reorderList = outStockUpdate.filter((fish) => fish.reOrder === true);
    const notReOrderList = outStockUpdate.filter((fish) => fish.reOrder === false);
    setLoading(true);
    let res1 = true,
      res2 = true;
    if (reorderList.length > 0) {
      res1 = await handleReOderFish(reorderList);
    }
    if (notReOrderList.length > 0) {
      res2 = await handleNotReOderFish(notReOrderList);
    }

    if (res1 && res2) {
      toast.success('Save successfully!', { autoClose: 2000, theme: 'colored' });
      getFishList(queyStock);
      setOutStockId([]);
      setOutStockUpdate([]);
    } else {
      toast.error('Something went wrong!', { autoClose: 2000, theme: 'colored' });
    }
    setLoading(false);
  };

  const getNoOfDay = (date, quantity) => {
    let diffInDays = 0;
    if (quantity > 0) {
      const startDay = new Date(date);
      const nowDate = new Date();
      const oneDay = 1000 * 60 * 60 * 24;
      const diffInTime = nowDate.getTime() - startDay.getTime();
      diffInDays = Math.ceil(diffInTime / oneDay);
      if (diffInDays.toString() === '0') {
        diffInDays = 1;
      }
    }
    return diffInDays;
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setInventoryUpdate(inventoryParam);
    setInventoriesId([]);
  };

  const selectToAddFish = (tankNo) => {
    const listTank = [...inventoriesId];
    if (!listTank.includes(tankNo)) {
      listTank.push(tankNo);
    }
    const rowSelect = document.querySelectorAll('.Inventory_page .MuiDataGrid-row');
    const inputSelect = document.querySelectorAll(
      '.Inventory_page .MuiDataGrid-row .input_inventory'
    );
    rowSelect.forEach((row, index) => {
      if (+row.getAttribute('data-id') === tankNo) {
        row.classList.add('row-select');
        inputSelect[index].focus();
      }
    });
    setInventoriesId(listTank);
  };

  const selectToUpdate = (fishId) => {
    let idList = [...outStockId];
    if (!idList.includes(fishId)) {
      idList.push(fishId);
    } else {
      idList = idList.filter((id) => id !== fishId);
    }
    const newList = outStock.map((item) => {
      if (item.id === fishId) {
        return {
          ...item,
          reOrder: !item.reOrder
        };
      }
      return item;
    });
    let updateList = [];
    newList.forEach((acc) => {
      idList.forEach((id) => {
        if (acc.id === id) {
          updateList.push(acc);
        }
      });
    });
    setOutStockUpdate(updateList);
    setOutStock(newList);
    setOutStockId(idList);
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleUpdateSubmit();
    }
  };
  const onKeyDownHandlerSecond = (e) => {
    if (e.keyCode === 13) {
      handleUpdateSubmit();
    }
  };

  useEffect(() => {
    getEmptyTanksNumber();
  }, []);

  const filterOptions = createFilterOptions({
    stringify: (option) => option.fishName
  });

  const defaultValue = (fish) => {
    if (fish.Fish) {
      return fish.Fish;
    } else {
      return null;
    }
  };
  const getDefaultFish = (fishName) => {
    const defaultFish = fishes.find((fish) => fish.fishName === fishName);
    return defaultFish || null;
  };

  const defaultValueAdd = (descriptionInput) => {
    inventories.map((item) => {
      if (item.Fish.fishName == descriptionInput) {
        return item;
      }
    });
  };
  const columns = [
    { field: 'orderTankNoId', headerName: 'Tank No.', minWidth: 80, type: 'number', flex: 0.5, align: 'right', headerAlign: 'left' },
    {
      field: 'fishName',
      headerName: 'Description',
      flex: 2,
      minWidth: 200,
      renderCell: (params) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              id="combo-box-add"
              options={fishes}
              getOptionLabel={(option) => option.fishName}
              filterOptions={filterOptions}
              defaultValue={() => defaultValue(params.row)}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.fishName} ({option.size})
                  </li>
                );
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              sx={{ width: '100%' }}
              onChange={(e, value) => {
                handleGetFishById(value?.id, params.row?.tankNo);
              }}
              className={`select-fish`}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Description" variant="outlined" />
              )}
            />
          </Grid>
          {data.map((item) => {
            if (item.tankInput == params.row?.tankNo) {
              return (
                <Grid item xs={12}>
                  <Autocomplete
                    id="combo-box-demo1"
                    options={fishes}
                    getOptionLabel={(option) => option.fishName}
                    filterOptions={filterOptions}
                    defaultValue={() => getDefaultFish(item.descriptionInput)}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.fishName} ({option.size})
                        </li>
                      );
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    sx={{ width: '100%' }}
                    onChange={(e, value) => {
                      handleGetFishById(value?.id, params.row?.tankNo);
                    }}
                    className={`select-fish`}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Description" variant="outlined" />
                    )}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      )
    },
    {
      field: 'size',
      headerName: 'Size',
      minWidth: 180,
      flex: 1,
      renderCell: (params) => (
        <Grid className="size-fish" container spacing={3}>
          <Grid className="information-size-fish">{params.row.Fish?.size}</Grid>
          {data.map((item, i) => {
            if (item.tankInput == params.row?.tankNo) {
              return <Grid className="information-size-fish">{item.sizeInput}</Grid>;
            }
          })}
        </Grid>
      )
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      type: 'number',
      flex: 1,
      minWidth: 160,
      renderCell: (params) => (
        <Grid container spacing={2}>
          <Grid item>
            <Box sx={{ p: 0.5, width: '100%' }} className="box_quantity_inventory">
              <input
                type="text"
                className="input_inventory"
                value={params.row.quantity || 0}
                placeholder="Input Quantity"
                onKeyDown={onKeyDownHandler}
                onChange={(e) => {
                  if (
                    (Number(e.target.value) || !e.target.value || e.target.value === '0') &&
                    params.row.fishId
                  )
                    handleInputQuantity(params.row.tankNo, +e.target.value);
                }}
              />
            </Box>
          </Grid>
          {data.map((item) => {
            if (item.tankInput == params.row?.tankNo) {
              return (
                <Grid item>
                  <Box sx={{ p: 0.5, width: '100%' }} className="box_quantity_inventory">
                    <input
                      type="text"
                      className="input_inventory"
                      value={item.quantityInput || 0}
                      placeholder="Input Quantity"
                      onKeyDown={onKeyDownHandler}
                      onChange={(e) => {
                        if (
                          (Number(e.target.value) || !e.target.value || e.target.value === '0') &&
                          params.row.fishId
                        )
                          handleInputQuantity(params.row.tankNo, +e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              );
            }
          })}
          {/* <Grid item>
            <Box sx={{ p: 0.5, width: '100%' }} className="box_quantity_inventory">
              <input
                type="text"
                className="input_inventory"
                defaultValue={params.row.secondFishQuantity}
                placeholder="Input Quantity"
                onKeyDown={onKeyDownHandlerSecond}
                onChange={(e) => {
                  if (
                    (Number(e.target.value) || !e.target.value || e.target.value === '0') &&
                    params.row.fishId
                  )
                    handleSecondQuantityChange(params.row.tankNo, +e.target.value);
                }}
              />
            </Box>
          </Grid> */}
        </Grid>
      )
    },

    {
      field: 'noOfDay',
      headerName: 'No. of Days',
      type: 'number',
      flex: 1,
      headerAlign: 'left',
      minWidth: 100
    },

    {
      field: 'action',
      headerName: 'Action',
      maxWidth: 80,
      minWidth: 80,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <DeleteIcon
              sx={{ color: '#d32f2f' }}
              onClick={() => {
                setOpenModalDelete(true);
                setDeletedItem(params.row);
              }}
            />
          </Box>
        );
      }
    }
  ];

  const columnOutStock = [
    {
      field: 'fishName',
      headerName: 'Description',
      flex: 3,
      minWidth: 200
    },
    {
      field: 'size',
      headerName: 'Size',
      flex: 3,
      minWidth: 150
    },
    {
      field: 'reOrder',
      headerName: 'Re-Order?',
      minWidth: 95,
      flex: 1,
      renderCell: (params) => (
        <div className="text-align checkbox-receiables">
          <label htmlFor={params.row.id} onClick={() => selectToUpdate(params.row.id)}></label>
          <input type="checkbox" id={params.row.id} readOnly checked={params.row.reOrder} />
        </div>
      )
    }
  ];

  const deleteFishToTank = () => {
    if (Array.isArray(inventories)) {
      let newArray = inventories.filter((item) => item.tankNo !== 1);
      setInventories(newArray);
    }
  };
  return (
    <div className="Inventory_page">
      {loading ? <Loading open={loading} /> : null}
      {openModalDelete ? (
        <ModalDelete
          open={openModalDelete}
          handleClose={() => {
            setOpenModalDelete(false);
            setDeletedItem(defaultDeletedItem);
          }}
          handleDelete={handleDeleteInventory}
        />
      ) : null}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Inventory" {...a11yProps(0)} />
            <Tab label="Out of Stock" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="OrderPage_search">
            <div className="OrderPage_search_box">
              <div>
                <SearchIcon />
              </div>
              <DebounceInput
                debounceTimeout={1000}
                placeholder="Input Search"
                value={queyInventory.tankNo}
                onChange={(e) => {
                  setQueryInventory((pre) => ({
                    ...pre,
                    txtSearch: e.target.value
                  }));
                  getListInventories({ ...queyInventory, txtSearch: e.target.value });
                }}
              />

              {queyInventory.tankNo.length > 0 && (
                <ClearIcon
                  sx={{ cursor: 'pointer', opacity: 0.5 }}
                  onClick={() => {
                    setQueryInventory((pre) => ({ ...pre, tankNo: '' }));
                    getListInventories({ ...queyInventory, tankNo: '' });
                  }}
                />
              )}
            </div>
            <div className="number_tank">
              <p>Empty Tanks</p>
              <p>{tankNumber}</p>
            </div>
          </div>
          <div className="add-fish-wrapper">
            <label className="label">Add fish to tank:</label>
            <input
              type="number"
              name="tankno"
              min={1}
              max={300}
              className="input-tank-no"
              placeholder="Tank No"
              value={tankInput}
              onChange={(e) => setTankInput(e.target.value)}
            />
            {fishes && (
              <Autocomplete
                options={fishes}
                getOptionLabel={(option) => option.fishName + ' (' + option.size + ')'}
                filterOptions={filterOptions}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.fishName} ({option.size})
                    </li>
                  );
                }}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                className={`select-fish`}
                renderInput={(input) => (
                  <TextField {...input} placeholder="Select Description" variant="outlined" />
                )}
                onChange={(e, value) => {
                  setNewFishId(value.id);
                }}
              />
            )}
            <input
              type="number"
              name="quality"
              min="1"
              placeholder="Quantity"
              value={quantityInput}
              onChange={(e) => setQuantityInput(e.target.value)}
            />
            <button
              type="button"
              className="button_add-fish"
              onClick={() =>
                handleAddFishToVirtualInventory({
                  tankNo: tankInput,
                  fishId: newFishId,
                  quantity: quantityInput
                })
              }
            >
              Add
            </button>
          </div>

          <div className="OrderPage_table">
            <Table
              columns={columns}
              rows={inventories}
              pageSize={query.take}
              totalRow={totalRow}
              setPage={setPage}
              hideFooterSelectedRowCount={true}
              hiddenSection={true}
              onSelectionCheckBox={() => { }}
              loading={loading}
              data={[]}
              getRowId={(row) => {
                return row.tankNo;
              }}
            />
          </div>

          <div className="OrderPage_btn btn_action">
            <Button
              variant="contained"
              className={`${!inventoryUpdate.tankNo || !Number(inventoryUpdate.quantity) ? 'disable' : ''
                }`}
              disabled={!inventoryUpdate.tankNo || !Number(inventoryUpdate.quantity)}
              onClick={handleUpdateSubmit}
            >
              Save
            </Button>
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div className="OrderPage_table table_outStock">
            <Table
              columns={columnOutStock}
              rows={outStock}
              pageSize={queyStock.take}
              totalRow={totalOutStock}
              setPage={setPage}
              hiddenSection={true}
              onSelectionCheckBox={() => { }}
              loading={loading}
              data={[]}
              getRowId={(row) => {
                return row.id;
              }}
            />
          </div>
          <div className="OrderPage_btn btn_action">
            <Button
              variant="contained"
              className={`${outStockId.length < 1 ? 'disable' : ''}`}
              disabled={outStockId.length < 1}
              onClick={handleOutOfStock}
            >
              Save
            </Button>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
