import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { login } from '../../Api/login';
import Logo from '../../Assets/Images/login-logo.jpg';
import { ACCESS, ROUTER } from '../../common/constants';
import Loading from '../../components/Loading/Loading';
import './Login.css';

export default function Login() {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [validatePass, setValidatePass] = useState(false);
  const [validateUser, setValidateUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [exist, setExist] = useState(false);
  let [action, setAction] = useState(false);
  const inputRef = useRef(null);
  const passRef = useRef(null);

  const handleCheckLogin = async () => {
    setValidatePass(true);
    setValidateUser(true);
    const dataLogin = {
      username: userName,
      password: password
    };
    if (!userName && !password) {
      inputRef.current.focus();
    } else if (!userName && password) {
      inputRef.current.focus();
    } else {
      passRef.current.focus();
    }
    if (userName && password) {
      if (action) {
        return;
      }
      action = true;
      setAction(true);
      handleLogin(dataLogin);
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleCheckLogin();
    }
  };

  const handleLogin = async (data) => {
    setLoading(true);
    try {
      const response = await login(data);
      if (response.data) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('access', response.data.user.access);
        localStorage.setItem('username', response.data.user.username);
        localStorage.setItem('userId', response.data.user.userId);
        localStorage.setItem('isLogin', true);
        setAction(false);
        setLoading(false);
        if (response.data.user.access === ACCESS.CREW) {
          navigate(ROUTER.FULFILLMENT);
        } else if (response.data.user.access === ACCESS.ACCOUNTANT) {
          navigate(ROUTER.ACCOUNT);
        } else {
          navigate(ROUTER.HOME);
        }
      }
    } catch (error) {
      setLoading(false);
      setAction(false);
      if (error.response.status === 401) {
        setExist(true);
        inputRef.current.focus();
      } else {
        toast.error('Login Fail!', { autoClose: 2000, theme: 'colored' });
      }
      console.log(error);
    }
  };

  const isLogout = localStorage.getItem('logout');

  useEffect(() => {
    if (isLogout) {
      toast.success('Logout successfully!', { autoClose: 2000, theme: 'colored' });
      localStorage.removeItem('logout');
    }
  }, [isLogout]);

  return (
    <div className="LoginPage">
      <ToastContainer />
      {loading ? <Loading open={loading} /> : null}
      <div className="LoginPage_container">
        <div className="LoginPage_logo">
          <img src={Logo} alt="" />
        </div>

        <div className="LoginPage_Form">
          <div className="username">
            <span></span>
            <span className="text-error">
              {exist && <p className="text-error">Username or Password is incorrect.</p>}
            </span>
          </div>
          <div className="username form_input">
            <span>Username</span>
            <input
              type="text"
              value={userName}
              ref={inputRef}
              onKeyDown={onKeyDownHandler}
              onChange={(e) => {
                setValidateUser(false);
                setExist(false);
                setUserName(e.target.value);
              }}
            />
          </div>
          <div className="username">
            <span></span>
            <span className="text-error">
              {validateUser && !userName && 'Please enter a username.'}
            </span>
          </div>
          <div className="password form_input">
            <span>Password</span>
            <input
              type={showPass ? 'text' : 'password'}
              value={password}
              ref={passRef}
              onKeyDown={onKeyDownHandler}
              onChange={(e) => {
                setValidatePass(false);
                setExist(false);
                setPassword(e.target.value);
              }}
            />
            <span className="showpass_icon" onClick={() => setShowPass(!showPass)}>
              {showPass ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
            </span>
          </div>
          {validatePass && !password && (
            <div className="password">
              <span></span>
              <span className="text-error  mb-0">Please enter a password.</span>
            </div>
          )}
          <div className="btn_submit btn_action">
            <Button
              variant="contained"
              sx={{ width: '50%', textTransform: 'capitalize' }}
              onClick={handleCheckLogin}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
