/* eslint-disable react-hooks/exhaustive-deps */
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getSupplier } from '../../Api/contact';
import { getFishes } from '../../Api/fish';
import { ACCESS, ROUTER } from '../../common/constants';
import { convertDate } from '../../utils/utils';
import FooterModal from './FooterModal/FooterModal';
import './ModalEdit.css';
import ModalEditCompany from './ModalEditCompany/ModalEditCompany';
import ModalEditContact from './ModalEditContact/ModalEditContact';
import ModalEditFish from './ModalEditFish/ModalEditFish';
import ModalEditOrder from './ModalEditOrder/ModalEditOrder';
import ModalEditPayable from './ModalEditPayable/ModalEditPayable';
import ModalEditStock from './ModalEditStock/ModalEditStock';
import ModalEditUser from './ModalEditUser/ModalEditUser';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '0.5rem',
  border: '0.0625rem solid #d1d7e0',
  boxShadow: 24,
  p: 1,
  pt: 0.5
};

export default function ModalEdit({
  open,
  handleClose,
  handleAction,
  data,
  setData,
  page,
  duplicate,
  setDuplicate,
  noDuplicate,
  viewOnly
}) {
  const [fakePass, setFakePass] = useState('');
  const [validate, setValidate] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validDate, setValidDate] = useState(false);
  const [maxLength, setMaxLength] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const [fishes, setFishes] = useState([]);
  const [dataSend, setDataSend] = useState({});
  const inputRef = useRef(null);
  const [noEdit, setNoEdit] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (page === 'ORDER' || page === 'STOCK') {
      getFishList({ skip: 0, take: 10, inInventory: true, orderBy: 'desc', isNoneLimit: true });
    } else {
      getFishList({ skip: 0, take: 10 });
    }
    if (page === 'PAYABLE') {
      getListSuppliers();
    } else if (page !== 'USER') {
      getListSuppliers();
    }
  }, []);

  useEffect(() => {
    if (page === 'USER' && data.userId) {
      setFakePass('**********');
      if (data.access === ACCESS.ADMIN) {
        setNoEdit(true);
      }
    }
    if (location.pathname === ROUTER.REFUNDS) {
      setNoEdit(true);
    }
  }, [page]);

  const selectFishStock = (id) => {
    const fishSelect = fishes.find((fish) => fish.id === id);
    const price = fishSelect?.priceOut ? fishSelect.priceOut : '';
    setData((pre) => ({ ...pre, pricing: price }));
    setDataSend((pre) => {
      return { ...pre, pricing: price };
    });
  };

  const getListSuppliers = async () => {
    try {
      const response = await getSupplier({
        skip: 0,
        take: 200,
        companyName: '',
        isNoneLimit: true,
        type: page === 'ORDER' ? 'customer' : 'supplier'
      });
      if (response) {
        setSupplier(response.data.suppliers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNoOfDay = (date) => {
    let diffInDays = 0;
    const startDay = new Date(date);
    const nowDate = new Date();
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = startDay.getTime() - nowDate.getTime();
    diffInDays = Math.ceil(diffInTime / oneDay);
    return diffInDays.toString();
  };

  const selectDeliveryOrder = (date) => {
    setValidDate(false);
    setData((pre) => ({
      ...pre,
      deliveryDate: date
    }));
  };

  const clickOutSide = (e) => {
    if (inputRef.current && inputRef.current?.contains(e.target)) {
      setFakePass('');
    }
  };

  document.addEventListener('mousedown', clickOutSide);

  const valueDeliveryDate = (deliveryDate, type) => {
    if (!deliveryDate) {
      return null;
    }
    return type === 'text'
      ? convertDate(deliveryDate, 'DD/MM/YYYY')
      : convertDate(deliveryDate, 'YYYY-MM-DD');
  };

  const handleSubmitAction = () => {
    const checkPayable = ['invoiceNo', 'supplierId', 'amount'];
    const checkOrder = ['supplierId', 'fishId', 'quantity'];

    switch (page) {
      case 'USER':
        if (
          (!data.password && !fakePass) ||
          !data.username ||
          (data.password && data.password.length < 8)
        ) {
          setValidate(true);
          return;
        }
        break;
      case 'PAYABLE':
        if (!data.paid) {
          data.paid = 'false';
          setData((pre) => ({ ...pre, paid: 'false' }));
        }
        checkKey(checkPayable);
        break;
      case 'ORDER':
        checkKey(checkOrder);
        break;
      case 'STOCK':
        delete data.quantity;
        handleCheckData();
        break;
      case 'FISH':
        handleCheckData();
        break;
      case 'CONTACT':
        checkContactPage();
        break;
      default:
        handleCheckData();
        break;
    }
    checkEmpty(page);
  };

  const handleCheckData = () => {
    let values = ['reOrder', 'priceIn', 'Company', 'orderQuantity'];
    values.forEach((value) => {
      if (!data[value]) {
        delete data[value];
      }
    });
  };

  const checkEmpty = (page) => {
    let valueArray = Object.values(data);
    const isEmpty = valueArray.some((value) => Boolean(value) === false);
    const noQuantity = data.OrderItem?.some((order) => Boolean(order.quantity) === false);
    if (page === 'DESCRIPTION') {
      const isEmptyDes = data.priceOut === '' || data.fishName === '';
      if (!isEmptyDes) {
        handleAction(data);
      }

      return;
    } else if (isEmpty || noQuantity) {
      setValidate(true);
    } else {
      handleAction(data);
    }
  };

  const checkKey = (value) => {
    let keys = Object.keys(data);
    keys.forEach((key) => {
      if (!value.includes(key) && !Boolean(data[key])) delete data[key];
    });
  };

  const checkContactPage = () => {
    if (page === 'CONTACT') {
      let values = ['contact', 'phoneNo', 'poc'];
      values.forEach((value) => {
        if (!data[value]) {
          delete data[value];
        }
      });
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleSubmitAction();
    }
  };

  const getFishList = async (param) => {
    try {
      const response = await getFishes(param);
      if (response) {
        setFishes(response.data.fish);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkPage = data.deliveryOrderNo ? 'UPDATE PAYABLE' : 'CREATE PAYABLE';

  const checkFunction =
    data.deliveryOrderNo || data.userId || (data.supplierId && page === 'CONTACT') || data.id
      ? `UPDATE ${page}`
      : `CREATE ${page}`;

  const getClassName = () => {
    let className = '';
    switch (location.pathname) {
      case ROUTER.ORDER:
        className = 'edit_modal_order';
        break;
      case ROUTER.FULFILLMENT:
        className = 'edit_modal_order disable-fish';
        break;
      case ROUTER.ACCOUNT:
        className = 'edit_modal_order disable disable-full';
        break;
      default:
        className = '';
        break;
    }
    return className;
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className={`edit_modal ${getClassName()} ${viewOnly ? 'disable disable-full' : ''}`}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              p: '4px 0.7rem',
              position: 'relative'
            }}
          >
            {noEdit ? null : <h4>{page === 'PAYABLE' ? checkPage : checkFunction}</h4>}
            <ClearIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
          </Box>
          <Box>
            {page === 'CONTACT' && (
              <ModalEditContact
                data={data}
                setData={setData}
                setDuplicate={setDuplicate}
                validate={validate}
                setValidEmail={setValidEmail}
                validEmail={validEmail}
                onKeyDownHandler={onKeyDownHandler}
                noDuplicate={noDuplicate}
                duplicate={duplicate}
              />
            )}

            {page === 'ORDER' && (
              <Box className="modal-common">
                <ModalEditOrder
                  data={data}
                  setData={setData}
                  validDate={validDate}
                  validate={validate}
                  onKeyDownHandler={onKeyDownHandler}
                  fishes={fishes}
                  companies={supplier}
                  inputRef={inputRef}
                  setValidate={setValidate}
                  valueDeliveryDate={valueDeliveryDate}
                  selectDeliveryOrder={selectDeliveryOrder}
                />
              </Box>
            )}

            {page === 'PAYABLE' && (
              <ModalEditPayable
                data={data}
                setData={setData}
                validate={validate}
                onKeyDownHandler={onKeyDownHandler}
                supplier={supplier}
                setDataSend={setDataSend}
              />
            )}

            {page === 'STOCK' && (
              <ModalEditStock
                data={data}
                setData={setData}
                validate={validate}
                onKeyDownHandler={onKeyDownHandler}
                fishes={fishes}
                setDataSend={setDataSend}
                selectFishStock={selectFishStock}
              />
            )}

            {page === 'USER' && (
              <ModalEditUser
                data={data}
                setData={setData}
                validate={validate}
                onKeyDownHandler={onKeyDownHandler}
                access={ACCESS}
                setValidate={setValidate}
                fakePass={fakePass}
                inputRef={inputRef}
                setFakePass={setFakePass}
                noEdit={noEdit}
              />
            )}

            {page === 'DESCRIPTION' && (
              <ModalEditFish
                data={data}
                setData={setData}
                validate={validate}
                onKeyDownHandler={onKeyDownHandler}
                setMaxLength={setMaxLength}
                setDuplicate={setDuplicate}
                setDataSend={setDataSend}
                maxLength={maxLength}
                companies={supplier}
                duplicate={duplicate}
              />
            )}

            {page === 'COMPANY' && (
              <ModalEditCompany
                data={data}
                setData={setData}
                validate={validate}
                onKeyDownHandler={onKeyDownHandler}
              />
            )}
            {noEdit ? null : (
              <FooterModal
                handleSubmitAction={handleSubmitAction}
                data={data}
                page={page}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
