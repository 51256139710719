import ClearIcon from '@mui/icons-material/Clear';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { getSupplier } from '../../Api/contact';
import '../../Page/Fulfillment/Fulfillment.css';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import StockLabel from '../StockLable/StockLabel';
import Table from '../Table/Table';
// import { getFishes } from '../../Api/fish';
import SearchIcon from '@mui/icons-material/Search';
import { DebounceInput } from 'react-debounce-input';
const FulfillmentCp = ({
  setQuery,
  query,
  rows,
  rowTotal,
  setPage,
  loading,
  orderSelect,
  selectOrder,
  colums,
  orderPrint,
  handlePrintOrder,
  labelRef,
  value
}) => {
  const [invoiceNo, setInvoiceNo] = useState(0);
  const [supplier, setSupplier] = useState([]);
  // const [fishs, setFishs] = useState([]);
  const getListSuppliers = async () => {
    try {
      const response = await getSupplier({
        skip: 0,
        take: 10,
        companyName: '',
        type: 'customer',
        isNoneLimit: true
      });
      if (response) {
        setSupplier(response.data.suppliers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListSuppliers();
  }, []);

  useEffect(() => {
    setQuery((pre) => ({
      ...pre,
      invoiceNo: invoiceNo
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceNo]);
  return (
    <>
      <div className="Orderpage_info">
        <div className="company info">
          <span>Company Name</span>
          <SelectDropdown
            data={supplier}
            selectType="filterCompanyByName"
            textDefault="All"
            row={{}}
            setData={(data) =>
              setQuery((pre) => ({
                ...pre,
                companyName: data,
                skip: 0
              }))
            }
          />
        </div>
        <div className="delivery_date info">
          <span>Delivery Date</span>
          <div className="input_date">
            <input type="text" placeholder="02/03/2023" />
            <input
              type="date"
              value={query.deliveryDate}
              onChange={(e) =>
                setQuery((pre) => {
                  return { ...pre, deliveryDate: e.target.value };
                })
              }
            />
            {query.deliveryDate && (
              <ClearIcon
                sx={{
                  cursor: 'pointer',
                  opacity: 0.5,
                  position: 'absolute',
                  height: '20px',
                  top: '50%',
                  right: '35px',
                  transform: 'translateY(-50%)'
                }}
                onClick={() => setQuery((pre) => ({ ...pre, deliveryDate: '' }))}
              />
            )}
          </div>
        </div>
        <div className="OrderPage_searchs">
          <div className="OrderPage_search_boxs">
            <span>Search</span>
            <div className="search-input-container">
              <div className="button-search">
                <SearchIcon />
              </div>
              <DebounceInput
                debounceTimeout={1000}
                placeholder="Invoice No."
                value={invoiceNo}
                onChange={(e) => setInvoiceNo(e.target.value)}
                className="debounce-input"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="OrderPage_table">
        <Table
          columns={colums}
          rows={rows}
          pageSize={query.take}
          totalRow={rowTotal}
          setPage={setPage}
          loading={loading}
          data={orderSelect}
          onSelectionCheckBox={selectOrder}
          getRowId={(row) => {
            return row.deliveryOrderNo;
          }}
        />
      </div>
      {value === 'printlabel' && (
        <Box>
          <div id="stock_print" ref={labelRef}>
            <StockLabel data={orderPrint} />
          </div>
          <div className="OrderPage_btn btn_action">
            <Button
              onClick={handlePrintOrder}
              className={`${orderSelect.length < 1 ? 'disable' : ''}`}
              disabled={orderSelect.length < 1}
              variant="contained"
            >
              Print Label
            </Button>
          </div>
        </Box>
      )}
      {value === 'reprint' && (
        <Box>
          <div id="stock_print" ref={labelRef}>
            <StockLabel data={orderPrint} />
          </div>
          <div className="OrderPage_btn btn_action">
            <Button
              onClick={handlePrintOrder}
              className={`${orderSelect.length < 1 ? 'disable' : ''}`}
              disabled={orderSelect.length < 1}
              variant="contained"
            >
              Reprint
            </Button>
          </div>
        </Box>
      )}
    </>
  );
};
export default FulfillmentCp;
