import './GlobalStyle.css'

const GlobalStyle = ({children}) => {
    return (
        <div className="container_global">
            {children}
        </div>
    )
}

export default GlobalStyle