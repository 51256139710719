import { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Login, ContainerPage, NotFound } from './Page';
import { routerAccount, routerAdmin, routerCrew } from './Router';
import { ACCESS, ROUTER } from './common/constants';

function App() {
  const navigate = useNavigate();
  const access = localStorage.getItem('access');
  useEffect(() => {
    if (!access) {
      navigate(ROUTER.LOGIN);
    }
  }, []);

  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case ROUTER.ORDER:
        document.title = 'Order | Mainland Aquarium';
        break;
      case ROUTER.LOGIN:
        document.title = 'Login | Mainland Aquarium';
        break;
      case ROUTER.ACCOUNT:
        document.title = 'Account | Mainland Aquarium';
        break;
      case ROUTER.COMPANY:
        document.title = 'Company | Mainland Aquarium';
        break;
      case ROUTER.FISH:
        document.title = 'Description | Mainland Aquarium';
        break;
      case ROUTER.CONTACTS:
        document.title = 'Contact | Mainland Aquarium';
        break;
      case ROUTER.FULFILLMENT:
        document.title = 'Fulfillment | Mainland Aquarium';
        break;
      case ROUTER.INVENTORY:
        document.title = 'Inventory | Mainland Aquarium';
        break;
      case ROUTER.STOCKS:
        document.title = 'Stock | Mainland Aquarium';
        break;
      case ROUTER.SETTING:
        document.title = 'Setting | Mainland Aquarium';
        break;
      default:
        document.title = 'Home | Mainland Aquarium';
        break;
    }
  }, [location]);

  return (
    <Routes>
      {access && access === ACCESS.ADMIN && (
        <Route path="/" element={<ContainerPage />}>
          {routerAdmin.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
      )}
      {access && access === ACCESS.CREW && (
        <Route path="/" element={<ContainerPage />}>
          {routerCrew.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
      )}

      {access && access === ACCESS.ACCOUNTANT && (
        <Route path="/" element={<ContainerPage />}>
          {routerAccount.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
      )}
      <Route path="/login" index element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
