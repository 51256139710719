/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import Logo from '../../Assets/Images/login-logo.jpg';
import Signature from '../../Assets/Images/signature.png';
import './Invoice.css';
import { roundNumber } from '../../utils/utils';
import { useMemo, useState } from 'react';
import { useEffect } from 'react';

export default function Invoice({ data, invoiceNo, gst }) {
  const [orderArray, setOrderArray] = useState([]);
  const [orderFinal, setOrderFinal] = useState([]);

  const calculateTotal = (order) => {
    let total = 0;
    // Lặp qua mỗi đơn hàng trong mảng Order
    for (let i = 0; i < order.length; i++) {
      const orderItem = order[i]?.OrderItem;

      // Lặp qua mỗi sản phẩm trong OrderItem
      for (let j = 0; j < orderItem?.length; j++) {
        const item = orderItem[j];

        // Tính tổng của originPrice và quantity cho mỗi sản phẩm
        const productTotal = item.originPrice * item.quantity;

        // Cộng vào tổng chung
        total += productTotal;
      }
    }

    return total;
  };
  const total = calculateTotal(data);

  const getGstNumber = (gst) => {
    let vat;
    if (gst.isGst) {
      vat = total * (Number(gst.default) / 100);
    } else {
      vat = 0;
    }
    return vat;
  };
  const isRemark = data?.some((item) => Boolean(item?.remark?.trim()) === true);

  function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array?.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    // Pad with empty arrays if needed
    const lastChunkSize = result[result.length - 1]?.length;

    if (lastChunkSize < chunkSize) {
      const remainingEmptyChunks = chunkSize - lastChunkSize;
      for (let i = 0; i < remainingEmptyChunks; i++) {
        result[result?.length - 1]?.push([]);
      }
    }
    return result;
  }

  useEffect(() => {
    let ordersList = [];
    data?.forEach((order) => {
      order?.OrderItem?.forEach((item) => {
        ordersList.push(item);
      });
    });
    setOrderArray(ordersList);
  }, [data]);

  useEffect(() => {
    let newOrderList = [];
    orderArray.forEach((orderItem) => {
      let indexDuplicate = newOrderList.findIndex(
        (item) =>
          item.fishId === orderItem.fishId &&
          item.originPrice === orderItem.originPrice &&
          item.marking === orderItem.marking
      );
      if (indexDuplicate !== -1) {
        newOrderList[indexDuplicate].quantity += orderItem.quantity;
      } else {
        newOrderList.push({ ...orderItem });
      }
    });
    const orderChunks = chunkArray(newOrderList, 18);
    setOrderFinal(orderChunks);
  }, [orderArray]);

  const calculateSubtotal = useMemo(() => {
    return roundNumber(!gst.isGst ? total - getGstNumber(gst) : total);
  }, [total, gst]);

  const calculateGst = useMemo(() => {
    return !gst.isGst
      ? roundNumber(getGstNumber(gst))
      : roundNumber((Number(roundNumber(total).replace(/,/g, '')) * gst.default) / 100);
  }, [total, gst]);

  const calculateTotalAmount = useMemo(() => {
    return roundNumber(+calculateSubtotal.replace(/,/g, '') + +calculateGst);
  }, [calculateSubtotal, calculateGst]);

  return (
    <div className="invoice">
      {orderFinal.map((orders, index) => (
        <div className="invoice-content" key={index}>
          <header>
            <div className="invoice_header">
              <div className="logo">
                <img src={Logo} alt="" />
              </div>
              <div className="infor">
                <h1>Mainland Aquarium Pte Ltd</h1>
                <div className="invoice_no">
                  <p>UEN:202207753H</p>
                  <p>GST No: 202207753H</p>
                </div>
                <p>Address: Northlink Building, 10 Admiralty Street #05-23 (S)757695</p>
                <p>Tel: (+65) 9187 8583</p>
                <p>Email: hello@mainlandaquarium.com</p>
              </div>
            </div>
            <h2>TAX INVOICE</h2>
            <div className="invoice_company">
              <p>
                <span>Company:</span>
                <span>{data[0]?.Supplier?.companyName}</span>
              </p>
              <p>
                <span>Invoice No:</span>
                <span>{invoiceNo}</span>
              </p>
            </div>
            <div className="invoice_company">
              <p>
                <span>Address:</span>
                <span>{data[0]?.Supplier?.address}</span>
              </p>
              <p>
                <span>Date:</span>
                <span>{dayjs(data[0]?.deliveryDate)?.format('DD/MM/YYYY')}</span>
              </p>
            </div>
          </header>
          <div className="invoice_table">
            <div className="invoice_table-header">
              <div>Marking</div>
              <div>Description</div>
              <div>Quantity</div>
              <div>Unit Price</div>
              <div>Amount</div>
            </div>
            <div>
              <div className="invoice_table-body">
                {orders?.map((order, index) => (
                  <div className="cell-data" key={index}>
                    <div>{order.marking}</div>
                    <div>{order.Fish?.fishName}</div>
                    <div className="row_quantity">{order.quantity}</div>
                    <div className="row_price">
                      {order?.originPrice && (
                        <>
                          <span>$</span>
                          <span>{roundNumber(order?.originPrice)}</span>
                        </>
                      )}
                    </div>
                    <div className="row_price">
                      {order?.originPrice && (
                        <>
                          <span>$</span>
                          <span>
                            {roundNumber(Number(roundNumber(order?.originPrice)) * order.quantity)}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <footer>
            <div className="payment_details">
              <div className="text">
                <div className="remark">
                  {isRemark && <h3>remark:</h3>}
                  {data.map((item, index) => (
                    <p key={index}>{item.remark}</p>
                  ))}
                </div>
                <h3>Payment Details</h3>
                <p>United Overseas Bank Account No: 3723114991</p>
                <p>PAYNOW UEN: 202207753H</p>
                <p>Made all Cheques payable to Mainland Aquarium Pte Ltd</p>
              </div>
              <div className="total">
                <div className="subTotal">
                  <p>Subtotal</p>
                  <div>
                    <span>$</span>
                    <span>{calculateSubtotal}</span>
                  </div>
                </div>
                <div className="gst">
                  {gst.isGst ? (
                    <p>GST ({gst.gst}%)</p>
                  ) : (
                    <p className="subTotal_left">GST Inclusive {gst.default}%</p>
                  )}
                  <div>
                    <span>$</span>
                    <span>{calculateGst}</span>
                  </div>
                </div>
                <div className="amount">
                  <p>Total Amount</p>
                  <div>
                    <span>$</span>
                    <span>{calculateTotalAmount}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="signature">
              <div className="customer">
                <p>Customer's Signature & Stamp</p>
              </div>
              <div className="author">
                <img src={Signature} alt="" />
                <p>Authorized Signature</p>
              </div>
            </div>
          </footer>
          <div className="count-page"></div>
        </div>
      ))}
    </div>
  );
}
