import { Box } from '@mui/material';
import '../ModalEdit.css';

export default function ModalEditFish({
  data,
  setData,
  validate,
  onKeyDownHandler,
  setMaxLength,
  setDuplicate,
  setDataSend,
  maxLength,
  companies,
  duplicate
}) {
  return (
    <div>
      <Box sx={{ p: '0 0.7rem', width:'500px', height:'300px' }}>
        <div className="addOrder_form_input">
          <div className="label">
            <p>
              Description <span>*</span>
            </p>
            <input
              onKeyDown={onKeyDownHandler}
              type="text"
              value={data.fishName}
              onChange={(e) => {
                if (e.target.value.length <= 50) {
                  setMaxLength(false);
                  setDuplicate(false);
                  setData((pre) => {
                    return { ...pre, fishName: e.target.value };
                  });
                  setDataSend((pre) => {
                    return { ...pre, fishName: e.target.value };
                  });
                } else {
                  setMaxLength(true);
                }
              }}
            />
            {validate && !data.fishName && (
              <span className="mess-error">Please enter Description</span>
            )}
            {maxLength && <span className="mess-error">Description not exceed 50 characters</span>}
          </div>
          {/* <div className="label">
            <p>
              Company Name <span>*</span>
            </p>
            <SelectDropdown
              data={companies}
              selectType="selectCompany"
              textDefault="Select Company"
              row={data}
              setData={(value) => {
                setData((pre) => {
                  return { ...pre, companyId: +value };
                });
                setDataSend((pre) => {
                  return { ...pre, companyId: +value };
                });
                setMaxLength(false);
                setDuplicate(false);
              }}
            />
            {validate && !data.companyId && (
              <span className="mess-error">Please enter company name</span>
            )}
          </div> */}
          <div className="label">
            <p>
              Size
            </p>
            <input
              onKeyDown={onKeyDownHandler}
              type="text"
              value={data.size}
              onChange={(e) => {
                setMaxLength(false);
                setDuplicate(false);
                setData((pre) => {
                  return { ...pre, size: e.target.value };
                });
                setDataSend((pre) => {
                  return { ...pre, size: e.target.value };
                });
              }}
            />
            {/* {validate && !data.size && <span className="mess-error">Please enter size</span>} */}
            {duplicate.size && <span className="mess-error">This size was existed already.</span>}
          </div>
          <div className="label">
            <p>
              Price<span>*</span>
            </p>
            <input
              onKeyDown={onKeyDownHandler}
              type="text"
              value={data.priceOut}
              onChange={(e) => {
                if (Number(e.target.value) >= 0 || !e.target.value) {
                  setMaxLength(false);
                  setDuplicate(false);
                  setData((pre) => {
                    return { ...pre, priceOut: e.target.value };
                  });
                  setDataSend((pre) => {
                    return { ...pre, priceOut: +e.target.value };
                  });
                }
              }}
            />
            {validate && data.priceOut === '' && (
              <span className="mess-error">Please enter price</span>
            )}
          </div>
        </div>
      </Box>
    </div>
  );
}
