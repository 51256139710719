import './StockLabel.css';
import { convertDate } from '../../utils/utils';

export default function StockLabel({ data }) {
  return (
    <>
      {data?.map((item, index) => (
        <div key={index}>
          {item?.OrderItem?.map((order, index) => (
            <div className="stock_label" key={index}>
              <div className="top item">
                <div className="d-flex">
                  <p>{item.Supplier?.companyName}</p>
                </div>
                <div className="d-flex">
                  <p> {order?.marking}</p>
                </div>
              </div>
              <div className="center item d-flex">
                <p>{order.Fish?.fishName || order.fishName}</p>
                <p>{convertDate(item.deliveryDate, 'DD/MM/YYYY')}</p>
              </div>
              <div className="bottom item">
                <div className="d-flex">
                  <p key={index}>{order.originSize || order.Fish.size}</p>
                </div>
                <div className="d-flex">
                  <p>{order.quantity}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
}
