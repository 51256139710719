import { useEffect, useRef, useState } from 'react';
import './SelectDropdown.css';
import SelectDropdownDefault from './SelectDropdownDefault/SelectDropdownDefault';
import SelectDropdownFiller from './SelectDropdownFiller/SelectDropdownFiller';
import SelectDropdownName from './SelectDropdownName/SelectDropdownName';

function SelectDropdown({ textDefault, data, row, handleChangeValue, setData, selectType }) {
  const [open, setOpen] = useState(false);
  const select = useRef(null);
  const container = useRef(null);
  const [textSelect, setTextSelect] = useState('');

  const closeOpenSelect = (e) => {
    const datePicker = document.querySelector('.flatpickr-calendar');
    if (
      select.current &&
      open &&
      !select.current?.contains(e.target) &&
      !container.current?.contains(e.target) &&
      !datePicker?.contains(e.target)
    ) {
      setOpen(false);
    }
  };

  document.addEventListener('mousedown', closeOpenSelect);
  const handleChange = (value, name) => {
    handleChangeValue(value, row.tankNo);
    setTextSelect(name);
    setOpen(false);
  };

  useEffect(() => {
    if (row?.Fish && selectType === 'selectFish') {
      setTextSelect(row?.Fish?.fishName);
    }
    if (row?.Supplier?.companyName && selectType === 'selectCompany') {
      setTextSelect(row?.Supplier?.companyName);
    }
    if (row?.Fish && !selectType) {
      setTextSelect(row?.Fish?.fishName);
    }
    if (row?.supplierId) {
      setTextSelect(row.Supplier.companyName);
    }
    if (row && selectType === 'selectAccount') {
      setTextSelect(row.charAt(0).toUpperCase() + row.slice(1));
    }
    if (row && selectType === 'selectSupplier') {
      setTextSelect(row.charAt(0).toUpperCase() + row.slice(1));
    }
  }, []);
  return (
    <div className="select_dropdown">
      <div className="text_show" ref={container} onClick={() => setOpen(!open)}>
        <p className="text_overflow">{textSelect || textDefault}</p>
      </div>
      {selectType === 'selectFish' && (
        <SelectDropdownName
          open={open}
          row={row}
          setOpen={setOpen}
          select={select}
          textDefault={textDefault}
          data={data}
          setData={setData}
          setTextSelect={setTextSelect}
          value="selectFish"
        />
      )}

      {selectType === 'selectCompany' && (
        <SelectDropdownName
          open={open}
          row={row}
          setOpen={setOpen}
          select={select}
          textDefault={textDefault}
          data={data}
          setData={setData}
          setTextSelect={setTextSelect}
          value="selectCompany"
        />
      )}

      {selectType === 'filterCompany' && (
        <SelectDropdownFiller
          open={open}
          row={row}
          setOpen={setOpen}
          select={select}
          textDefault={textDefault}
          data={data}
          setData={setData}
          setTextSelect={setTextSelect}
          value="filterCompany"
        />
      )}

      {selectType === 'filterCompanyByName' && (
        <SelectDropdownFiller
          open={open}
          row={row}
          setOpen={setOpen}
          select={select}
          textDefault={textDefault}
          data={data}
          setData={setData}
          setTextSelect={setTextSelect}
          value="filterCompanyByName"
        />
      )}

      {selectType === 'selectSupplier' && (
        <SelectDropdownDefault
          open={open}
          setOpen={setOpen}
          select={select}
          setData={setData}
          setTextSelect={setTextSelect}
          value="selectSupplier"
        />
      )}

      {selectType === 'filterDate' && (
        <SelectDropdownFiller
          open={open}
          setOpen={setOpen}
          select={select}
          data={data}
          setData={setData}
          setTextSelect={setTextSelect}
          value="filterDate"
        />
      )}

      {!selectType && (
        <SelectDropdownDefault
          open={open}
          select={select}
          data={data}
          textDefault={textDefault}
          handleChange={handleChange}
          value="default"
        />
      )}
      {selectType === 'selectAccount' && (
        <SelectDropdownDefault
          open={open}
          setOpen={setOpen}
          select={select}
          setData={setData}
          setTextSelect={setTextSelect}
          value="selectAccount"
        />
      )}
    </div>
  );
}

export default SelectDropdown;
