import { API_BASE, parseQuery } from './apiConfig';

export const getStocks = async (param) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.get(
    `api/stocks${parseQuery(param)}`,
    token
  );
  return data;
};

export const createStock = async (stock) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.post('api/stocks', stock, token);
  return data;
};

export const updateStock = async (stock) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.patch(`api/stocks/${stock.id}`, stock, token);
  return data;
};

export const deleteStock = async (stockId) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.delete(`api/stocks/${stockId}`, token);
  return data;
};

export const deleteStocks = async (listStockId) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  let url = '';
  listStockId.forEach((id, index) => {
    if (index < listStockId.length - 1) {
      url += `id=${id}&`;
    } else {
      url += `id=${id}`;
    }
  });
  const { data } = await API_BASE.delete(`api/stocks?${url}`, token);
  return data;
};
