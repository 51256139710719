/* eslint-disable react-hooks/exhaustive-deps */
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { useEffect } from 'react';
import '../ModalEdit/ModalEdit.css';
import { getGST } from '../../Api/order';
import { useState } from 'react';
import Loading from '../Loading/Loading';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '90%',
  maxWidth: '375px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '0.5rem',
  border: '0.0625rem solid #d1d7e0',
  boxShadow: 24,
  p: 1,
  pt: 0.5
};

export default function ModalGst({ open, handleClose, gstFinal, setGstFinal, handleAction }) {
  const [gstDefault, setGstDefault] = useState(0);
  const [loading, setLoading] = useState(false);
  const getValueGST = async () => {
    setLoading(true);
    try {
      const response = await getGST();
      if (response) {
        let value = response.data[0].value * 100;
        setGstFinal({ ...gstFinal, gst: value, default: value, isGst: true });
        setGstDefault(value);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getValueGST();
  }, []);

  return (
    <div>
      {loading ? <Loading open={loading} /> : null}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              p: '4px 0.7rem',
              position: 'relative'
            }}
          >
            <ClearIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
          </Box>
          <Box sx={{ p: '0 0.7rem' }}>
            <div className="label orderList" id="order">
              <Box
                className="label label-gst"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '16px 0'
                }}
              >
                <FormControl>
                  <Box
                    sx={{ color: '#262b40', fontSize: '1rem', fontWeight: 600 }}
                    id="demo-row-radio-buttons-group-label"
                  >
                    Select GST value for invoice
                  </Box>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={gstFinal.gst}
                    onChange={(e) => {
                      const value = +e.target.value;
                      setGstFinal({ ...gstFinal, gst: value, isGst: !!value });
                    }}
                  >
                    <FormControlLabel
                      value={gstDefault}
                      control={<Radio />}
                      label={`Default (${gstDefault}%)`}
                    />
                    <FormControlLabel value={0} control={<Radio />} label="None" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </div>
          </Box>
          <Box
            id="modal-modal-description"
            sx={{
              mt: 1,
              mb: 1,
              display: 'flex',
              gap: 3,
              justifyContent: 'center',
              '& .MuiButtonBase-root': {
                minWidth: '50px',
                lineHeight: 1.25
              }
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#262b40',
                fontWeight: 600,
                '&:hover': { opacity: '0.8', backgroundColor: '#262b40' }
              }}
              onClick={handleAction}
            >
              Print
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: '#262b40',
                fontWeight: 600,
                color: '#262b40',
                '&:hover': {
                  borderColor: '#262b40',
                  opacity: 0.8
                }
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
