import { API_BASE, parseQuery } from './apiConfig';

export const getSupplier = async (param) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.get(`api/suppliers${parseQuery(param)}`, token);
  return data;
};

export const createSupplierCustomer = async (body) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.post('api/supplier', body, token);
  return data;
};

export const updateSupplierCustomer = async (body) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.patch(`api/supplier/${body.supplierId}`, body, token);
  return data;
};

export const deleteSupplier = async (id) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.delete(`api/supplier/${id}`, token);
  return data;
};

export const deleteSuppliers = async (listId) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  let url = '';
  listId.forEach((id, index) => {
    if (index < listId.length - 1) {
      url += `id=${id}&`;
    } else {
      url += `id=${id}`;
    }
  });
  const { data } = await API_BASE.delete(`api/suppliers?${url}`, token);
  return data;
};
