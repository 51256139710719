import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { toast } from 'react-toastify';
import { createFish, deleteFish, deleteFishes, getFishes, updateFish } from '../../Api/fish';
import Loading from '../../components/Loading/Loading';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import ModalEdit from '../../components/ModalEdit/ModalEdit';
import Table from '../../components/Table/Table';
import './Fish.css';
import { roundNumber } from '../../utils/utils';

const Fish = () => {
  const query = {
    skip: 0,
    take: 10,
    orderBy: 'desc',
    fishName: ''
  };
  const [queyFish, setQueryFish] = useState(query);
  const [fishes, setFishes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [sameSize, setSameSize] = useState(false);
  const [fishId, setFishId] = useState([]);
  const dataFish = { fishName: '', size: '', priceOut: '' };
  const [fish, setFish] = useState(dataFish);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  let [action, setAction] = useState(false);

  const getFishList = async (param) => {
    setLoading(true);
    try {
      const response = await getFishes(param);
      if (response) {
        const data = response.data.fish.map((item, index) => ({
          ...item,
          no: page * query.take + index + 1
        }));
        setFishes(data);
        setCount(response.data.count);
        setLoading(false);
      }
    } catch (error) {
      toast.error('Something went wrong!', { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getFishList(queyFish);
  }, [queyFish]);

  useEffect(() => {
    const newQuery = { ...query, skip: query.take * page };
    if (fishes.length !== 0) {
      setQueryFish(newQuery);
    }
  }, [page]);

  const selectFish = (fishID) => {
    setFishId(fishID);
  };

  const handleDeleteFish = async () => {
    setLoading(true);
    let response;
    try {
      if (fishId.length > 1) {
        response = await deleteFishes(fishId);
      } else {
        response = await deleteFish(fishId[0] || fish.id);
      }
      if (response) {
        setFishId([]);
        setFish(dataFish);
        getFishList(queyFish);
        setOpenModalDelete(false);
        toast.success('Delete successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      if (mess.includes('server error')) {
        toast.error('This item was ordered. Please select another !', {
          autoClose: 2000,
          theme: 'colored'
        });
      } else {
        toast.error(mess, { autoClose: 2000, theme: 'colored' });
      }
      setOpenModalDelete(false);
      setLoading(false);
    }
  };

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
    setFish(dataFish);
  };

  const handleCreateFish = async (data) => {
    setLoading(true);
    try {
      const response = await createFish(data);
      if (response) {
        setFish(dataFish);
        const fish = { ...response.data, no: 1 };
        let newFishs = [...fishes];
        if (fishes.length === query.take) {
          newFishs.pop();
        }
        newFishs = newFishs.map((item) => ({ ...item, no: item.no + 1 }));
        newFishs.unshift(fish);
        setFishes(newFishs);
        setCount((pre) => pre + 1);
        setLoading(false);

        setOpenModalEdit(false);
        setAction(false);
        toast.success('Create successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      const mess = error.response.data.errors || 'Something went wrong!';
      setAction(false);
      if (mess.includes('exists')) {
        setSameSize(true);
      } else {
        toast.error(mess, { autoClose: 2000, theme: 'colored' });
      }
      setLoading(false);
    }
  };

  const handleUpdateFish = async (data) => {
    setLoading(true);
    try {
      const response = await updateFish(data.id, data);
      if (response) {
        setFish(dataFish);
        const updateFishes = fishes.map((value, index) => {
          if (value.id === response.data.id) {
            return { ...response.data, no: page * query.take + index + 1 };
          }
          return value;
        });
        setFishes(updateFishes);
        setFishId([]);
        setLoading(false);
        setOpenModalEdit(false);
        setAction(false);
        toast.success('Update successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      const mess = error.response.data.errors || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      setAction(false);
      setLoading(false);
    }
  };

  const handleAction = (dataSend) => {
    if (action) {
      return;
    }
    action = true;
    setAction(true);
    fish.id ? handleUpdateFish({ ...dataSend, id: fish.id }) : handleCreateFish(dataSend);
  };

  const columns = [
    { field: 'no', headerName: 'No.', type: 'number', minWidth: 50, maxWidth: 50 },
    {
      field: 'fishName',
      headerName: 'Description',
      flex: 5,
      minWidth: 140,
      valueGetter: (params) => `${params.row?.fishName} (${params.row.size})`
    },
    { field: 'size', headerName: 'Size', flex: 2, minWidth: 100 },
    {
      field: 'priceOut',
      headerName: 'Unit Price',
      type: 'number',
      flex: 2,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <div className="pricing-table">
            <span>$</span>
            <span>{params.row.priceOut}</span>
          </div>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <div className={`action_btn ${fishId.length > 1 ? 'disable' : ''}`}>
            <EditIcon
              onClick={() => {
                setFish(params.row);
                setOpenModalEdit(true);
              }}
            />
            <DeleteIcon
              sx={{ color: '#d32f2f' }}
              onClick={() => {
                selectFish(params.row.id);
                setFish(params.row);
                setOpenModalDelete(true);
              }}
            />
          </div>
        );
      }
    }
  ];

  return (
    <div className="Fish_page">
      {loading ? <Loading open={loading} /> : null}
      {openModalDelete ? (
        <ModalDelete
          open={openModalDelete}
          handleClose={() => {
            setOpenModalDelete(false);
            setFish(dataFish);
          }}
          handleDelete={handleDeleteFish}
        />
      ) : null}

      {openModalEdit ? (
        <ModalEdit
          open={openModalEdit}
          data={fish}
          setData={setFish}
          handleClose={handleCloseModalEdit}
          handleAction={handleAction}
          duplicate={{ size: sameSize }}
          setDuplicate={setSameSize}
          page="DESCRIPTION"
        />
      ) : null}
      <div className="OrderPage_search">
        <div className="Orderpage_info">
          <div className="info_user">
            <div className="input_info btn_action">
              <Button
                variant="contained"
                onClick={() => {
                  setOpenModalEdit(true);
                }}
              >
                <AddIcon /> CREATE DESCRIPTION
              </Button>
            </div>
          </div>
        </div>
        <div className="OrderPage_search_box">
          <div>
            <SearchIcon sx={{ color: '#d1d7e0' }} />
          </div>
          <DebounceInput
            debounceTimeout={1000}
            placeholder="Description"
            value={queyFish.fishName}
            onChange={(e) => setQueryFish((pre) => ({ ...pre, fishName: e.target.value }))}
          />
          {queyFish.fishName.length > 0 && (
            <ClearIcon
              sx={{ cursor: 'pointer', opacity: 0.5 }}
              onClick={() => setQueryFish((pre) => ({ ...pre, fishName: '' }))}
            />
          )}
        </div>
      </div>
      <div className="OrderPage_table">
        <Table
          columns={columns}
          rows={fishes}
          pageSize={queyFish.take}
          setPage={setPage}
          totalRow={count}
          loading={loading}
          data={fishId}
          onSelectionCheckBox={setFishId}
          getRowId={(row) => {
            return row.id;
          }}
        />
      </div>
      <div className="OrderPage_btn btn_action">
        <Button
          className={`${fishId.length < 1 ? 'disable' : ''}`}
          sx={{ backgroundColor: '#d32f2f' }}
          variant="contained"
          disabled={fishId.length < 1}
          color="error"
          onClick={() => {
            setOpenModalDelete(true);
          }}
        >
          Delete selected
        </Button>
      </div>
    </div>
  );
};

export default Fish;
