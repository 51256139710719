import { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../components/Navbar/Navbar';
import './ContainerPage.css';
import NavbarMobile from '../../components/NavbarMobile/NavbarMobile';
import { ACCESS, ROUTER } from '../../common/constants';

const ContainerPage = () => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const access = localStorage.getItem('access');
  const isLogin = localStorage.getItem('isLogin');

  useEffect(() => {
    if (access === ACCESS.ACCOUNTANT && window.location.pathname === ROUTER.HOME) {
      navigate(ROUTER.ACCOUNT);
    }
    if (access === ACCESS.CREW && window.location.pathname === ROUTER.HOME) {
      navigate(ROUTER.FULFILLMENT);
    }
    if (isLogin) {
      toast.success('Login successfully!', { autoClose: 2000, theme: 'colored' });
      localStorage.removeItem('isLogin');
    }
  }, [window.location.pathname, access]);

  return (
    <div className="ContainerPage">
      <ToastContainer />
      <div className={`ContainerPage_content ${openMenu ? 'openMenu' : ''}`}>
        <Navbar setOpenMenu={setOpenMenu} />
        <NavbarMobile setOpenMenu={setOpenMenu} />
        <div className="ContainerPage_content_outlet">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ContainerPage;
