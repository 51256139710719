import '../SelectDropdown.css';

export default function SelectDropdownName({
  open,
  row,
  setOpen,
  select,
  textDefault,
  data,
  setData,
  setTextSelect,
  value
}) {
  return (
    <div>
      {value === 'selectFish' && (
        <ul className={`list_item ${open ? 'show' : ''}`} ref={select}>
          {!row?.Fish && <li onClick={() => setOpen(false)}>{textDefault}</li>}
          {data.map((item) => (
            <li
              key={item.id}
              onClick={() => {
                setData(item.id);
                setTextSelect(item.fishName);
                setOpen(false);
              }}
            >
              <p className="text_overflow">{item.fishName}</p>
            </li>
          ))}
        </ul>
      )}
      {value === 'selectCompany' && (
        <ul className={`list_item ${open ? 'show' : ''}`} ref={select}>
          {!row?.Supplier?.companyName && <li onClick={() => setOpen(false)}>{textDefault}</li>}
          {data.map((item) => (
            <li
              key={item.id || item.supplierId}
              onClick={() => {
                setData(item.id || item.supplierId);
                setTextSelect(item.companyName);
                setOpen(false);
              }}
            >
              <p className="text_overflow">{item.companyName}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
