import './Navbar.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import Logo from '../../Assets/Images/login-logo.jpg';
import { ACCESS, ROUTER } from '../../common/constants';

const Navbar = ({ setOpenMenu }) => {
  const navigate = useNavigate();

  const crewMenu = [
    { href: ROUTER.FULFILLMENT, text: 'Fulfillment' },
    { href: ROUTER.INVENTORY, text: 'Inventory' },
    { href: ROUTER.STOCKS, text: 'Stocklist' }
  ];

  const adminMenu = [
    { href: ROUTER.HOME, text: 'Home' },
    { href: ROUTER.ORDER, text: 'Order' },
    { href: ROUTER.FULFILLMENT, text: 'Fulfillment' },
    // { href: ROUTER.REFUNDS, text: 'Refunds' },
    { href: ROUTER.INVENTORY, text: 'Inventory' },
    { href: ROUTER.STOCKS, text: 'Stocklist' },
    { href: ROUTER.ACCOUNT, text: 'Accounts' },
    { href: ROUTER.CONTACTS, text: 'Contacts' },
    { href: ROUTER.FISH, text: 'Description' },
    { href: ROUTER.SETTING, text: 'Setting' }
    // { href: ROUTER.COMPANY, text: 'Company' }
  ];

  const accountantMenu = [{ href: ROUTER.ACCOUNT, text: 'Accounts' }];

  const [menus, setMenus] = useState(adminMenu);

  const access = localStorage.getItem('access');
  const username = localStorage.getItem('username');
  useEffect(() => {
    switch (access) {
      case ACCESS.ADMIN:
        setMenus(adminMenu);
        break;
      case ACCESS.ACCOUNTANT:
        setMenus(accountantMenu);
        break;
      case ACCESS.CREW:
        setMenus(crewMenu);
        break;
      default:
        break;
    }
  }, [localStorage.getItem('access')]);

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('access');
    localStorage.removeItem('token');
    localStorage.setItem('logout', true);
  };

  return (
    <div className="navbar_menu">
      <div className="navbar_user">
        <div className="navbar_user_logo">
          <img
            src={Logo}
            alt=""
            onClick={() => {
              setOpenMenu(false);
              navigate('/');
            }}
          />
          <div className="close_menu" onClick={() => setOpenMenu(false)}>
            <ClearIcon sx={{ cursor: 'pointer', fontSize: '2rem', color: '#fff' }} />
          </div>
        </div>
        <div className="navbar_user_info">
          <span>{username}</span>
        </div>
      </div>
      <ul className="navbar_menu_list">
        {menus.map((menu, index) => (
          <li key={index} onClick={() => setOpenMenu(false)}>
            <NavLink to={menu.href}>{menu.text}</NavLink>
          </li>
        ))}
        <li onClick={handleLogout}>
          <NavLink to="/login">Logout</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
