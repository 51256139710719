/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import html2pdf from 'html2pdf.js';
import { useEffect, useRef, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import { getSupplier } from '../../Api/contact';
import {
  addTaxOrder,
  createOrder,
  deleteOrder,
  deleteOrders,
  getOrders,
  printOrder,
  updateOrder
} from '../../Api/order';
import { ORDER_STATUS } from '../../common/constants';
import Invoice from '../../components/Invoice/Invoice';
import Loading from '../../components/Loading/Loading';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import ModalEdit from '../../components/ModalEdit/ModalEdit';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import TabPanel from '../../components/TabPane/TabPane';
import Table from '../../components/Table/Table';
import './Order.css';
import ModalGst from '../../components/ModalGST/ModalGST';
import { roundNumber } from '../../utils/utils';

export default function Order() {
  const queryOrder = {
    skip: 0,
    take: 10,
    orderBy: 'desc',
    deliveryOrderNo: '',
    fishName: '',
    deliveryDate: '',
    companyName: '',
    orderStatus: [ORDER_STATUS.ORDER_CREATED, ORDER_STATUS.ORDER_PENDING]
  };
  const dataOrder = {
    supplierId: '',
    OrderItem: [{ fishId: '', quantity: '' }]
  };
  const navigate = useNavigate();
  const componentRef = useRef();
  const [query, setQuery] = useState(queryOrder);
  const [supplier, setSupplier] = useState([]);
  const [dataCreateOrder, setDataCreateOrder] = useState(dataOrder);
  const [loading, setLoading] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalGst, setOpenModalGst] = useState(false);
  const [page, setPage] = useState(0);
  const [value, setValue] = useState(0);
  const [orderSelect, setOrderSelect] = useState([]);
  const [orderPrints, setOrderPrints] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowTotal, setRowTotal] = useState(0);
  let [action, setAction] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [gstFinal, setGstFinal] = useState({ gst: '', isGst: true, default: '' });

  useEffect(() => {
    const newQuery = { ...query, skip: query.take * page };
    getOrdersList(newQuery);
  }, [page, query]);

  useEffect(() => {
    getListSuppliers();
  }, []);

  useEffect(() => {
    if (invoiceNo) {
      createInvoice();
      navigate('/fulfillment');
      toast.success('Create invoice successfully!', { autoClose: 2000, theme: 'colored' });
    }
  }, [invoiceNo]);

  const createInvoice = useReactToPrint({
    content: () => componentRef.current,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName('html')[0];
        await html2pdf()
          .set({
            margin: [1, 6, 0, 6],
            filename:
              `Invoice_${invoiceNo}` || `Invoice_${Math.floor(100000 + Math.random() * 900000)}`
          })
          .from(html)
          .toPdf()
          .get('pdf')
          .then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10.5);
              pdf.setTextColor('#000');
              pdf.text(
                'Page ' + i,
                pdf.internal.pageSize.getWidth() / 2.15,
                pdf.internal.pageSize.getHeight() - 5.85
              );
            }
          })
          .save();
      }
    }
  });

  const getListSuppliers = async () => {
    try {
      const response = await getSupplier({
        skip: 0,
        take: 10,
        companyName: '',
        type: 'customer',
        isNoneLimit: true
      });
      if (response) {
        setSupplier(response.data.suppliers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOrdersList = async (param) => {
    setLoading(true);
    try {
      const response = await getOrders(param);
      if (response) {
        const data = response.data.orders.map((item, index) => ({
          ...item,
          no: page * query.take + index + 1
        }));
        setRowTotal(response.data.count);
        setRows(data);
        setLoading(false);
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const handleCreateOrder = async () => {
    setLoading(true);
    const newOrder = { ...dataCreateOrder, items: dataCreateOrder.OrderItem };
    delete newOrder.OrderItem;
    try {
      const response = await createOrder(newOrder);
      if (response) {
        setDataCreateOrder(dataOrder);
        let newOrders = [...rows];
        if (rows.length === query.take) {
          newOrders.pop();
        }
        const order = { ...response.data, no: 1 };
        newOrders = newOrders.map((item) => ({ ...item, no: item.no + 1 }));
        newOrders.unshift(order);
        setRows(newOrders);
        setRowTotal((pre) => pre + 1);
        setLoading(false);
        setOpenModalEdit(false);
        toast.success('Create successfully!', { autoClose: 2000, theme: 'colored' });
        setAction(false);
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
      setAction(false);
    }
  };

  const handleUpdateOrder = async () => {
    setLoading(true);
    const newOrder = { ...dataCreateOrder, items: dataCreateOrder.OrderItem };
    delete newOrder.OrderItem;
    try {
      const response = await updateOrder(dataCreateOrder.deliveryOrderNo, newOrder);
      if (response) {
        const updateNewOrder = rows.map((order) => {
          if (order.deliveryOrderNo === response.data.deliveryOrderNo) {
            return { ...response.data, no: dataCreateOrder.no };
          }
          return order;
        });
        setDataCreateOrder(dataOrder);
        setRows(updateNewOrder);
        setLoading(false);
        setOrderSelect([]);
        setOpenModalEdit(false);
        toast.success('Update successfully!', { autoClose: 2000, theme: 'colored' });
        setAction(false);
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setAction(false);
      setLoading(false);
    }
  };

  const print = async (orderPrint, orderIdList) => {
    setLoading(true);
    try {
      const response = await printOrder(orderPrint, orderIdList);
      if (response) {
        handleTaxOrder(response.data.invoiceNo, { tax: !!gstFinal.gst });
        setInvoiceNo(response.data.invoiceNo);
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const handleTaxOrder = async (orderId, body) => {
    try {
      await addTaxOrder(orderId, body);
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
    }
  };

  const handlePrintOrder = async () => {
    let orderList = [];
    let orderIdList = [];
    rows.forEach((row) => {
      orderSelect.forEach((orderId) => {
        if (row.deliveryOrderNo === orderId) {
          orderList.push(row);
          orderIdList.push(orderId);
        }
      });
    });
    const company = orderList[0].Supplier.supplierId;
    const differentCompany = orderList.some((order) => order.Supplier.supplierId !== company);
    if (differentCompany) {
      toast.error('Please select the orders in the same company to print invoice!', {
        autoClose: 2000,
        theme: 'colored'
      });
    } else {
      if (!openModalGst) {
        setOpenModalGst(true);
        return;
      }
      const orderPrint = {
        orderStatus: ORDER_STATUS.FULFILLMENT_CREATED
      };
      print(orderPrint, orderIdList);
    }
  };

  const handleDeleteOrder = async () => {
    setLoading(true);
    let response;
    try {
      if (orderSelect.length > 1) {
        response = await deleteOrders(orderSelect);
      } else {
        response = await deleteOrder(orderSelect[0] || dataCreateOrder.deliveryOrderNo);
      }
      if (response) {
        setDataCreateOrder(dataOrder);
        setOrderSelect([]);
        setOpenModalDelete(false);
        getOrdersList(query);
        toast.success('Delete successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
    setDataCreateOrder(dataOrder);
  };

  const handleAction = () => {
    if (action) {
      return;
    }
    action = true;
    setAction(true);
    dataCreateOrder.deliveryOrderNo ? handleUpdateOrder() : handleCreateOrder();
  };

  const selectOrder = (orderId) => {
    setOrderSelect(orderId);
    let orderPrintList = [];
    rows.forEach((row) => {
      orderId.forEach((orderID) => {
        if (row.deliveryOrderNo === orderID) {
          orderPrintList.push(row);
        }
      });
    });
    setOrderPrints(orderPrintList);
  };

  const handleCreateInvoice = () => {
    handlePrintOrder();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setOrderSelect([]);
    setQuery(queryOrder);
  };

  const BtnAction = ({ params, isPrint }) => {
    return (
      <div className={`action_btn ${orderSelect.length > 1 ? 'disable' : ''}`}>
        <EditIcon
          onClick={() => {
            setDataCreateOrder(params.row);
            setOpenModalEdit(true);
          }}
        />
        {isPrint && (
          <PrintIcon
            onClick={() => {
              setOpenModalGst(true);
              setOrderSelect([params.row.deliveryOrderNo]);
              setOrderPrints([params.row]);
            }}
          />
        )}
        <DeleteIcon
          sx={{ color: '#d32f2f' }}
          onClick={() => {
            setDataCreateOrder(params.row);
            setOpenModalDelete(true);
          }}
        />
      </div>
    );
  };

  const AmountOrder = ({ params }) => {
    const amount = params.row.OrderItem.reduce(
      (total, order) => total + order?.quantity * order?.originPrice,
      0
    );
    return <p style={{ textAlign: 'right' }}>{roundNumber(amount)}</p>;
  };

  const columnsOrder = [
    { field: 'no', headerName: 'No.', minWidth: 50, maxWidth: 50, type: 'number' },
    {
      field: 'fishName',
      headerName: 'Description',
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p key={index}>{order.Fish.fishName}</p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      type: 'number',
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return (
          <div className="order_quantity">
            {params.row.OrderItem.map((order, index) => (
              <p key={index} style={{ textAlign: 'right' }}>
                {order.quantity.toLocaleString('vi-VN')}
              </p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'size',
      headerName: 'Size',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p key={index}>{order.originSize || order.Fish.size}</p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'priceOut',
      headerName: 'Unit Price',
      type: 'number',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p key={index} style={{ textAlign: 'right' }}>
                {roundNumber(order?.originPrice)}
              </p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 80,
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        return <AmountOrder params={params} />;
      }
    },
    {
      field: 'marking',
      headerName: 'Marking',
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return (
          <div>
            {params.row.OrderItem.map((order, index) => (
              <p key={index}>{order.marking}</p>
            ))}
          </div>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      maxWidth: 80,
      minWidth: 80,
      renderCell: (params) => {
        return <BtnAction params={params} />;
      }
    }
  ];

  const columnsDelivery = [
    { field: 'no', headerName: 'Delivery Order No.', minWidth: 150, maxWidth: 150, type: 'number' },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.Supplier?.companyName
    },
    {
      field: 'orderDate',
      headerName: 'Order Date',
      minWidth: 120,
      maxWidth: 120,
      valueGetter: (params) => new Date(params.row.orderDate).toLocaleDateString()
    },
    {
      field: 'deliveryDate',
      headerName: 'Delivery Date',
      minWidth: 120,
      maxWidth: 120,
      valueGetter: (params) => new Date(params.row.deliveryDate).toLocaleDateString()
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      minWidth: 100,
      maxWidth: 150,
      renderCell: (params) => {
        return <AmountOrder params={params} />;
      }
    },
    {
      field: 'remark',
      headerName: 'Remarks',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => params.row.remark
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 120,
      maxWidth: 120,
      renderCell: (params) => {
        return <BtnAction params={params} isPrint />;
      }
    }
  ];

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  return (
    <div className="OrderPage orders">
      {loading ? <Loading open={loading} /> : null}
      {openModalDelete ? (
        <ModalDelete
          open={openModalDelete}
          handleClose={() => {
            setOpenModalDelete(false);
            setDataCreateOrder(dataOrder);
          }}
          handleDelete={handleDeleteOrder}
        />
      ) : null}
      {openModalEdit ? (
        <ModalEdit
          open={openModalEdit}
          handleClose={handleCloseModalEdit}
          data={dataCreateOrder}
          setData={setDataCreateOrder}
          handleAction={handleAction}
          page="ORDER"
        />
      ) : null}
      {openModalGst ? (
        <ModalGst
          open={openModalGst}
          handleClose={() => setOpenModalGst(false)}
          handleAction={handleCreateInvoice}
          setGstFinal={setGstFinal}
          gstFinal={gstFinal}
        />
      ) : null}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Order" {...a11yProps(0)} />
            <Tab label="Delivery" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="Orderpage_info">
            <div className="info_user">
              <div className="input_info btn_action">
                <Button variant="contained" onClick={() => setOpenModalEdit(true)}>
                  <AddIcon />
                  <span>CREATE ORDER</span>
                </Button>
              </div>
              <div className="Orderpage_filter">
                <div className="OrderPage_search">
                  <div className="OrderPage_search_box">
                    <div>
                      <SearchIcon />
                    </div>
                    <DebounceInput
                      debounceTimeout={1000}
                      placeholder="Description"
                      value={query.fishName}
                      onChange={(e) => {
                        setQuery((pre) => ({ ...pre, fishName: e.target.value }));
                      }}
                    />
                    {query.fishName.length > 0 && (
                      <ClearIcon
                        sx={{ cursor: 'pointer', opacity: 0.5 }}
                        onClick={() => setQuery((pre) => ({ ...pre, fishName: '' }))}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="OrderPage_table">
              <Table
                columns={columnsOrder}
                rows={rows}
                loading={loading}
                pageSize={query.take}
                totalRow={rowTotal}
                data={orderSelect}
                setPage={setPage}
                onSelectionCheckBox={selectOrder}
                getRowId={(row) => {
                  return row.deliveryOrderNo;
                }}
              />
            </div>
            <div className="OrderPage_btn btn_action">
              <Button
                sx={{ backgroundColor: '#d32f2f' }}
                variant="contained"
                className={`${orderSelect.length < 1 ? 'disable' : ''}`}
                disabled={orderSelect.length < 1}
                color="error"
                onClick={() => {
                  setOpenModalDelete(true);
                }}
              >
                Delete selected
              </Button>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="Orderpage_info">
            <div className="Orderpage_filter">
              <div className="company info">
                <span>Company Name</span>
                <SelectDropdown
                  data={supplier}
                  selectType="filterCompanyByName"
                  textDefault="All"
                  row={{}}
                  setData={(data) =>
                    setQuery((pre) => ({
                      ...pre,
                      companyName: data,
                      skip: 0
                    }))
                  }
                />
              </div>
              <div className="delivery_date info">
                <span>Delivery Date</span>
                <input
                  type="date"
                  value={query.deliveryDate}
                  onChange={(e) => setQuery((pre) => ({ ...pre, deliveryDate: e.target.value }))}
                />
                {query.deliveryDate && (
                  <ClearIcon
                    sx={{
                      cursor: 'pointer',
                      opacity: 0.5,
                      position: 'absolute',
                      height: '20px',
                      top: '50%',
                      right: '30px',
                      transform: 'translateY(-50%)'
                    }}
                    onClick={() => setQuery((pre) => ({ ...pre, deliveryDate: '' }))}
                  />
                )}
              </div>
            </div>

            <div className="OrderPage_table">
              <Table
                columns={columnsDelivery}
                rows={rows}
                pageSize={query.take}
                totalRow={rowTotal}
                setPage={setPage}
                loading={loading}
                data={orderSelect}
                hideFooterSelectedRowCount={true}
                hiddenSection={true}
                onSelectionCheckBox={() => {}}
                // onSelectionCheckBox={selectOrder}
                getRowId={(row) => {
                  return row.deliveryOrderNo;
                }}
              />
            </div>
            <div id="content" ref={componentRef}>
              <Invoice data={orderPrints} gst={gstFinal} invoiceNo={invoiceNo} />
            </div>
            {/* <div className="OrderPage_btn btn_action">
              <Button
                variant="contained"
                className={`${orderSelect.length < 1 ? 'disable' : ''}`}
                disabled={orderSelect.length < 1}
                onClick={handlePrintOrder}
              >
                Create Invoice
              </Button>
            </div> */}
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
