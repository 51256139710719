import { API_BASE, parseQuery } from './apiConfig';

export const getInventories = async (param) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.get(`/api/inventories${parseQuery(param)}`, token);
  return data;
};

export const getInventoryById = async (id) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.get(`/api/inventory/${id}`, token);
  return data;
};

export const getEmptyTanks = async () => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.get(`/api/inventories?countEmptyTank=${true}`, token);
  return data;
};

export const updateInventory = async (id, inventory) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.patch(`api/inventory/${id}`, inventory, token);
  return data;
};

export const updateInventoryByFish = async (fishId, quantity) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.get(`api/inventory/update-inventories-by-fish?fishId=${fishId}&quantity=${quantity}`, token);
  return data;
};

export const updateManyInventories = async (inventoryList) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.patch('api/inventory', inventoryList, token);
  return data;
};

export const deleteInventory = async (id) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.delete(`api/inventory/${id}`, token);
  return data;
};