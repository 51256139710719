import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createUser, deleteUser, deleteUsers, getUsers, updateUser } from '../../Api/user';
import { ACCESS } from '../../common/constants';
import Loading from '../../components/Loading/Loading';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import ModalEdit from '../../components/ModalEdit/ModalEdit';
import ModalCalendar from '../../components/ModalCalendar/ModalCalendar';
import Table from '../../components/Table/Table';
import './Home.css';
import React from 'react';
import Calendar from '../../components/Calendar/Calendar';

export default function Home() {
  const event = {
    summary: '',
    description: '',
    id: '',
    start: {
      dateTime: new Date()
    },
    end: {
      dateTime: new Date()
    }
  };
  const [query, setQuery] = useState({ skip: 0, take: 5 });
  const [listAccount, setListAccount] = useState([]);
  const dataCreateUser = { username: '', password: '', access: ACCESS.ADMIN };
  const [user, setUser] = useState(dataCreateUser);
  const [userId, setUserId] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventSelect, setEventSelect] = useState(event);
  const [loading, setLoading] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalCalendar, setOpenModalCalendar] = useState(false);
  const [countUser, setCountUser] = useState(0);
  const [invalidTime, setInvalidTime] = useState(false);
  const [page, setPage] = useState(0);
  let [action, setAction] = useState(false);
  let [actionCalendar, setActionCalendar] = useState(false);
  const gapi = window.gapi;

  useEffect(() => {
    const newQuery = { ...query, skip: query.take * page };
    setQuery(newQuery);
    getListAccount(newQuery);
  }, [page]);

  const columns = [
    { field: 'no', headerName: 'No.', minWidth: 50, maxWidth: 50, type: 'number' },
    { field: 'access', headerName: 'Access', flex: 1, minWidth: 100 },
    { field: 'username', headerName: 'Username', flex: 1, minWidth: 100 },
    {
      field: 'password',
      headerName: 'Password',
      flex: 1,
      minWidth: 100,
      renderCell: () => {
        return <p>**********</p>;
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <div className={`action_btn ${userId.length > 1 ? 'disable' : ''}`}>
            <EditIcon
              onClick={() => {
                setUser(params.row);
                setOpenModalEdit(true);
              }}
            />
            <DeleteIcon
              sx={{ color: '#d32f2f' }}
              onClick={() => {
                setUserId(params.row);
                setUser(params.row);
                setOpenModalDelete(true);
              }}
            />
          </div>
        );
      }
    }
  ];

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
    setUser(dataCreateUser);
  };

  const selectUsers = (userSelect) => {
    setUserId(userSelect);
  };

  const handleCreateUser = async () => {
    setLoading(true);
    try {
      const response = await createUser(user);
      if (response) {
        setUser(dataCreateUser);
        if (listAccount.length < query.take) {
          const newAccount = [...listAccount, { ...response.data, no: countUser + 1 }];
          setListAccount(newAccount);
        }
        setCountUser((pre) => pre + 1);
        setLoading(false);
        setOpenModalEdit(false);
        toast.success('Create user successfully!', { autoClose: 2000, theme: 'colored' });
        setAction(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.errors.includes('Unique constraint')) {
        toast.error('Username already exists!', { autoClose: 2000, theme: 'colored' });
      } else {
        const mess =
          error?.response?.data?.errors ||
          error?.response?.data?.message ||
          'Something went wrong!';
        toast.error(mess, { autoClose: 2000, theme: 'colored' });
      }
      setLoading(false);
      setAction(false);
    }
  };

  const handleUpdateUser = async () => {
    setLoading(true);
    try {
      const response = await updateUser(user.userId, user);
      if (response) {
        setUser(dataCreateUser);
        const updateAccount = listAccount.map((acc, index) => {
          if (acc.userId === response.data.userId) {
            return { ...response.data, no: page * query.take + index + 1 };
          }
          return acc;
        });
        setListAccount(updateAccount);
        setUserId([]);
        setLoading(false);
        setOpenModalEdit(false);
        toast.success('Update user successfully!', { autoClose: 2000, theme: 'colored' });
        setAction(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        toast.error('Unable to change access permission!', {
          autoClose: 2000,
          theme: 'colored'
        });
      } else {
        const mess =
          error?.response?.data?.errors ||
          error?.response?.data?.message ||
          'Something went wrong!';
        toast.error(mess, { autoClose: 2000, theme: 'colored' });
      }
      setAction(false);
      setLoading(false);
    }
  };

  const getListAccount = async (param) => {
    setLoading(true);
    try {
      const response = await getUsers(param);
      if (response) {
        const data = response.data.users.map((item, index) => ({
          ...item,
          no: page * query.take + index + 1
        }));
        setListAccount(data);
        setCountUser(response.data.count);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      setLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    let response;
    try {
      if (userId.length > 1) {
        response = await deleteUsers(userId);
      } else {
        response = await deleteUser(userId[0] || user.userId);
      }
      if (response) {
        if (listAccount.length === 1 && query.skip !== 0) {
          getListAccount({ skip: query.skip - query.take, take: query.take });
        } else {
          getListAccount(query);
        }
        setUserId([]);
        setUser(dataCreateUser);
        setOpenModalDelete(false);
        toast.success('Delete user successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const handleAction = () => {
    if (action) {
      return;
    }
    action = true;
    setAction(true);
    user.userId ? handleUpdateUser() : handleCreateUser();
  };

  const handleDelete = () => {
    eventSelect.id ? handleDeleteEvent() : handleDeleteUser();
  };

  const handleDeleteEvent = async () => {
    setLoading(true);
    var request = gapi.client.calendar.events.delete({
      calendarId: 'primary',
      eventId: eventSelect.id,
      sendUpdates: 'all'
    });
    request.execute(
      (event) => {
        setEventSelect(event);
        setOpenModalDelete(false);
        setOpenModalCalendar(false);
        setLoading(false);
        listUpcomingEvents();
        toast.success('Delete event successfully!', { autoClose: 2000, theme: 'colored' });
      },
      (error) => {
        setEventSelect(event);
        setOpenModalDelete(false);
        setOpenModalCalendar(false);
        setLoading(false);
        const mess =
          error?.response?.data?.errors ||
          error?.response?.data?.message ||
          'Something went wrong!';
        toast.error(mess, { autoClose: 2000, theme: 'colored' });
      }
    );
  };

  const handleActionEvent = (e) => {
    if (actionCalendar) {
      return;
    }
    const start = new Date(eventSelect.start.dateTime);
    const end = new Date(eventSelect.end.dateTime);
    const diffInTime = end.getTime() - start.getTime();
    if (diffInTime < 0) {
      setInvalidTime(true);
      return;
    }
    actionCalendar = true;
    setActionCalendar(true);
    eventSelect.id ? handleUpdateEventCalendar() : handleCreateEventCalendar();
  };

  const handleUpdateEventCalendar = async () => {
    setLoading(true);
    var request = gapi.client.calendar.events.update({
      calendarId: 'primary',
      eventId: eventSelect.id,
      resource: eventSelect,
      sendUpdates: 'all'
    });
    request.execute(
      (event) => {
        setLoading(false);
        setEventSelect(event);
        setOpenModalCalendar(false);
        setActionCalendar(false);
        listUpcomingEvents();
        toast.success('Update event successfully!', { autoClose: 2000, theme: 'colored' });
      },
      (error) => {
        setActionCalendar(false);
        setLoading(false);
        setInvalidTime(true);
      }
    );
  };

  async function listUpcomingEvents() {
    let response;
    const date = new Date();
    const timeMin = new Date(new Date().setDate(date.getDate() - 60)).toISOString();
    try {
      const request = {
        calendarId: 'primary',
        timeMin: timeMin,
        showDeleted: false,
        singleEvents: true,
        orderBy: 'startTime'
      };
      response = await gapi.client.calendar.events.list(request);
    } catch (err) {
      console.log(err);
      if (err.status === 401) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_in');
      }
      return;
    }

    const events = response.result.items;
    if (!events || events.length === 0) {
      // document.getElementById('content').innerText = 'No events found.';
      return;
    }
    // Flatten to string to display
    const eventList = response?.result?.items?.map((e) => ({
      title: e.summary ? e.summary : '',
      start: e.start.dateTime,
      end: e.end.dateTime,
      allDay: false,
      description: e.description,
      id: e.id
    }));
    setEvents(eventList);
  }

  function handleCreateEventCalendar() {
    setLoading(true);
    var request = gapi.client.calendar.events.insert({
      calendarId: 'primary',
      resource: eventSelect,
      sendUpdates: 'all'
    });
    request.execute(
      (event) => {
        setLoading(false);
        setEventSelect(event);
        setOpenModalCalendar(false);
        setActionCalendar(false);
        listUpcomingEvents();
        toast.success('Create event successfully!', { autoClose: 2000, theme: 'colored' });
      },
      (error) => {
        setActionCalendar(false);
        setLoading(false);
        setInvalidTime(true);
      }
    );
  }

  return (
    <div className="HomePage">
      {loading ? <Loading open={loading} /> : null}
      {openModalDelete ? (
        <ModalDelete
          open={openModalDelete}
          openCalendar={openModalCalendar}
          user={user}
          handleClose={() => {
            setOpenModalDelete(false);
            setUser(dataCreateUser);
          }}
          handleDelete={handleDelete}
          data={userId}
          listAccount={listAccount}
          page="home"
        />
      ) : null}

      {openModalEdit ? (
        <ModalEdit
          open={openModalEdit}
          data={user}
          setData={setUser}
          handleClose={handleCloseModalEdit}
          handleAction={handleAction}
          page="USER"
        />
      ) : null}

      {openModalCalendar ? (
        <ModalCalendar
          open={openModalCalendar}
          handleClose={() => {
            setOpenModalCalendar(false);
            setEventSelect(event);
            setInvalidTime(false);
          }}
          page="USER"
          invalidTime={invalidTime}
          setInvalidTime={setInvalidTime}
          event={eventSelect}
          setOpenModalDelete={setOpenModalDelete}
          setEvent={setEventSelect}
          handleActionEvent={handleActionEvent}
        />
      ) : null}
      <Calendar
        setOpenModalCalendar={setOpenModalCalendar}
        eventSelect={eventSelect}
        setEventSelect={setEventSelect}
        events={events}
        event={event}
        listUpcomingEvents={listUpcomingEvents}
      />
      <div className="addUser_form">
        <div className="btn_addUser btn_action">
          <Button
            sx={{ fontWeight: 600, backgroundColor: '#262b40', color: '#fff' }}
            variant="contained"
            onClick={() => setOpenModalEdit(true)}
          >
            <AddIcon />
            <span>CREATE USER</span>
          </Button>
        </div>
      </div>
      <div className="OrderPage_table">
        <Table
          columns={columns}
          rows={listAccount}
          pageSize={query.take}
          totalRow={countUser}
          setPage={setPage}
          loading={loading}
          data={userId}
          onSelectionCheckBox={selectUsers}
          getRowId={(row) => {
            return row.userId;
          }}
        />
      </div>
      <div className="OrderPage_btn btn_action">
        <Button
          variant="contained"
          sx={{ backgroundColor: '#d32f2f' }}
          className={`${userId.length < 1 ? 'disable' : ''}`}
          disabled={userId.length < 1}
          color="error"
          onClick={() => setOpenModalDelete(true)}
        >
          Delete selected
        </Button>
      </div>
    </div>
  );
}
