import { Box, Button } from '@mui/material';

export default function FooterModal({ handleSubmitAction, data, page, handleClose }) {
  const checkbtnPage = data.deliveryOrderNo ? 'UPDATE' : 'CREATE';

  const checkbtnFunction =
    data.deliveryOrderNo || data.userId || (data.supplierId && page === 'CONTACT') || data.id
      ? 'Update'
      : 'Create';
  return (
    <div>
      <Box
        id="modal-modal-description"
        sx={{
          mt: 1,
          mb: 1,
          display: 'flex',
          gap: 3,
          justifyContent: 'center',
          '& .MuiButtonBase-root': {
            minWidth: '50px',
            lineHeight: 1.25
          }
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#262b40',
            fontWeight: 600,
            '&:hover': { opacity: '0.8', backgroundColor: '#262b40' }
          }}
          onClick={handleSubmitAction}
        >
          {page === 'PAYABLE' ? checkbtnPage : checkbtnFunction}
        </Button>
        <Button
          variant="outlined"
          sx={{
            borderColor: '#262b40',
            fontWeight: 600,
            color: '#262b40',
            '&:hover': {
              borderColor: '#262b40',
              opacity: 0.8
            }
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Box>
    </div>
  );
}
