import dayjs from 'dayjs';
import Logo from '../../Assets/Images/login-logo.jpg';
import Signature from '../../Assets/Images/signature.png';
import './AccountPrint.css';
import { roundNumber } from '../../utils/utils';
import { useMemo } from 'react';

export default function AccountPrint({ data, orderLastMonth, gstDefault }) {
  const getTotal = (array) => {
    return array.reduce(
      (pre, item) =>
        pre +
        Number(
          roundNumber(
            Number(roundNumber(item.amount).replace(/,/g, '')) -
              Number(gstValue(item).replace(/,/g, ''))
          ).replace(/,/g, '')
        ),
      0
    );
  };

  const getTotalGst = (array) => {
    return array.reduce(
      (pre, item) => pre + Number(roundNumber(gstValue(item)).replace(/,/g, '')),
      0
    );
  };

  const sumTotal = (array) => {
    return array.reduce((pre, item) => pre + Number(roundNumber(item.amount).replace(/,/g, '')), 0);
  };

  const sortArray = useMemo(() => {
    const sortAmountOrder = data.sort((item1, item2) => +item1.invoiceNo - +item2.invoiceNo);
    const sortDateOrder = sortAmountOrder.sort(
      (item1, item2) => new Date(item1.deliveryDate) - new Date(item2.deliveryDate)
    );
    return sortDateOrder;
  }, [data]);

  const groupOrdersByMonth = (orders) => {
    const groupedOrders = {};
    orders.forEach((order) => {
      const month = order.deliveryDate.substring(0, 7);
      if (!groupedOrders[month]) {
        groupedOrders[month] = [];
      }
      groupedOrders[month].push(order);
    });
    return Object.values(groupedOrders);
  };

  function getLastDayOfMonth(date) {
    // Lấy tháng hiện tại
    var month = date.getMonth();
    // Thiết lập mốc thời gian tại ngày đầu tiên của tháng tiếp theo
    date.setMonth(month + 1, 0);
    // Trả về ngày cuối cùng của tháng hiện tại
    return date.getDate();
  }

  const sortOrderLastMonth = useMemo(() => {
    const ordersSort = orderLastMonth.sort(
      (item1, item2) => +item1.deliveryOrderNo - +item2.deliveryOrderNo
    );
    return groupOrdersByMonth(ordersSort);
  }, [orderLastMonth]);

  const gstValue = (invoice) => {
    return invoice.amountPreTax === invoice.amount
      ? roundNumber(invoice.amountPreTax - invoice.amountPreTax / (1 + invoice?.gst / 100))
      : roundNumber((invoice.amountPreTax * invoice?.gst) / 100);
  };

  const sumTotalOrderLastMonth = (orders, callback) => {
    let amountTotal = 0;
    orders.forEach((order) => {
      amountTotal += Number(roundNumber(callback(order)).replace(/,/g, ''));
    });
    return amountTotal;
  };

  const date = dayjs();
  return (
    <div className="invoice invoice-account">
      <div className="invoice_header">
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        <div className="infor">
          <h1>Mainland Aquarium Pte Ltd</h1>
          <div className="invoice_no">
            <p>UEN: 202207753H</p>
            <p>GST No: 202207753H</p>
          </div>
          <p>Address: Northlink Building, 10 Admiralty Street #05-23 (S)757695</p>
          <p>Tel: (+65) 9187 8583</p>
          <p>Email: hello@mainlandaquarium.com</p>
        </div>
      </div>
      <div className="invoice_company">
        <p>
          <span>Company:</span>
          <span>{sortArray[0]?.Supplier?.companyName}</span>
        </p>
        <p></p>
      </div>
      <div className="invoice_company">
        <p>
          <span>Address:</span>
          <span>{sortArray[0]?.Supplier?.address}</span>
        </p>
        <p>
          <span>Date:</span>
          <span>{date.format('DD/MM/YYYY')}</span>
        </p>
      </div>
      <h2>Statement of account</h2>
      <div className="invoice_table">
        <table id="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Invoice No.</th>
              <th>Sales S($)</th>
              <th>GST S($)</th>
              <th>Total S($)</th>
            </tr>
          </thead>
          <tbody>
            {sortArray.map((item) => (
              <tr key={item.invoiceNo}>
                <td>{dayjs(item.deliveryDate).format('DD/MM/YYYY')}</td>
                <td>{`${
                  item?.remark ? item?.invoiceNo + ` (${item.remark})` : item?.invoiceNo
                }`}</td>
                <td>
                  <div className="account-amount">
                    <span>$</span>
                    <span>
                      {roundNumber(
                        Number(roundNumber(item.amount).replace(/,/g, '')) - Number(gstValue(item))
                      )}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="account-amount">
                    <span>$</span>
                    <span>{gstValue(item)}</span>
                  </div>
                </td>
                <td>
                  <div className="account-amount">
                    <span>$</span>
                    <span>{roundNumber(item.amount)}</span>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td>Sub-total</td>
              <td>
                <div className="account-amount">
                  <span>$</span>
                  <span>{roundNumber(getTotal(sortArray))}</span>
                </div>
              </td>
              <td>
                <div className="account-amount">
                  <span>$</span>
                  <span>{roundNumber(getTotalGst(sortArray))}</span>
                </div>
              </td>
              <td>
                <div className="account-amount">
                  <span>$</span>
                  <span>{roundNumber(sumTotal(sortArray))}</span>
                </div>
              </td>
            </tr>
          </tbody>
          {sortOrderLastMonth.length ? (
            <tbody>
              {sortOrderLastMonth.map((order) => (
                <tr key={order[0].invoiceNo}>
                  <td>
                    {getLastDayOfMonth(new Date(order[0].deliveryDate)) +
                      '/' +
                      dayjs(order[0].deliveryDate).format('MM/YYYY')}
                  </td>
                  <td></td>
                  <td>
                    <div className="account-amount">
                      <span>$</span>
                      <span>{roundNumber(getTotal(order))}</span>
                    </div>
                  </td>
                  <td>
                    <div className="account-amount">
                      <span>$</span>
                      <span>{roundNumber(getTotalGst(order))}</span>
                    </div>
                  </td>
                  <td>
                    <div className="account-amount">
                      <span>$</span>
                      <span>{roundNumber(sumTotal(order))}</span>
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td>Sub-total</td>
                <td>
                  <div className="account-amount">
                    <span>$</span>
                    <span>{roundNumber(sumTotalOrderLastMonth(sortOrderLastMonth, getTotal))}</span>
                  </div>
                </td>
                <td>
                  <div className="account-amount">
                    <span>$</span>
                    <span>
                      {roundNumber(sumTotalOrderLastMonth(sortOrderLastMonth, getTotalGst))}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="account-amount">
                    <span>$</span>
                    <span>{roundNumber(sumTotalOrderLastMonth(sortOrderLastMonth, sumTotal))}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : null}
        </table>
      </div>
      {sortOrderLastMonth.length ? (
        <div className="payment_sum">
          <div className="date-invoice">
            <span>Total outstanding @ {date.format('DD/MM/YYYY')}</span>
          </div>
          <div className="value-invoice">
            <span>SGD</span>
            <p>
              <span>$</span>
              <span>
                {roundNumber(
                  sumTotalOrderLastMonth(sortOrderLastMonth, sumTotal) + sumTotal(sortArray)
                )}
              </span>
            </p>
          </div>
        </div>
      ) : null}

      <div className="signature">
        <div className="author">
          <img src={Signature} alt="" />
          <p>Authorized Signature</p>
          <p className="verify">
            Please verify above statement promptly and notify us of any discrepancies.
          </p>
        </div>
      </div>
    </div>
  );
}
