import TextField from '@material-ui/core/TextField';
import { Autocomplete, Box } from '@mui/material';
import '../ModalEdit.css';

export default function ModalEditStock({
  data,
  setData,
  validate,
  onKeyDownHandler,
  fishes,
  setDataSend,
  selectFishStock
}) {
  return (
    <div>
      <Box sx={{ p: '0 0.7rem' }}>
        <div className="addOrder_form_input">
          <div className="label">
            <p>
              Description <span>*</span>
            </p>
            <Autocomplete
              id="combo-box-demo"
              options={fishes}
              getOptionLabel={(option) =>
                `${option?.fishName} (${option?.size})` 
              }
              defaultValue={data.id ? data.Fish : null}
              style={{ width: '100%' }}
              onChange={(e, value) => {
                setData((pre) => {
                  return { ...pre, fishId: value?.id };
                });
                setDataSend((pre) => {
                  return { ...pre, fishId: value?.id };
                });
                selectFishStock(value?.id);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Description" variant="outlined" />
              )}
            />

            {validate && !data.fishId && (
              <span className="mess-error">Please enter Description</span>
            )}
          </div>
          <div className="label">
            <p>
              Pricing <span>*</span>
            </p>
            <input type="text" readOnly value={data.pricing} className="disable" />
          </div>
          <div className="label">
            <p>Order Quantity</p>
            <input
              onKeyDown={onKeyDownHandler}
              type="text"
              placeholder="Input Order Quantity"
              value={data.orderQuantity || ''}
              onChange={(e) => {
                if (
                  (Number(e.target.value) && !e.target.value.includes('.')) ||
                  !e.target.value ||
                  e.target.value === '0'
                )
                  setData((pre) => {
                    return {
                      ...pre,
                      orderQuantity: e.target.value
                    };
                  });
                setDataSend((pre) => {
                  return { ...pre, orderQuantity: e.target.value };
                });
              }}
            />
          </div>
        </div>
      </Box>
    </div>
  );
}
