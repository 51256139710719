import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  createSupplierCustomer,
  deleteSupplier,
  deleteSuppliers,
  getSupplier,
  updateSupplierCustomer
} from '../../Api/contact';
import Loading from '../../components/Loading/Loading';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import ModalEdit from '../../components/ModalEdit/ModalEdit';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import Table from '../../components/Table/Table';
import './Customer.css';

export default function Customer() {
  const [loading, setLoading] = useState(false);
  const query = { skip: 0, take: 10, companyName: '', orderBy: 'desc', type: 'supplier' };
  const dataSupplier = {
    address: '',
    companyName: '',
    companyCode: '',
    contact: '',
    phoneNo: '',
    poc: '',
    type: 'supplier'
  };
  const [param, setParam] = useState(query);
  let [supplierId, setSupplierId] = useState([]);
  const [createSupplier, setCreateSupplier] = useState(dataSupplier);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [countTotal, setCountTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  let [action, setAction] = useState(false);
  const duplicate = { companyName: false, phoneNo: false, email: false };
  const [duplicateError, setDuplicateError] = useState(duplicate);

  useEffect(() => {
    const newQuery = { ...param, skip: query.take * page };
    getListSuppliers(newQuery);
  }, [page]);

  const getListSuppliers = async (par) => {
    setLoading(true);
    setParam(par);
    try {
      const response = await getSupplier(par);
      if (response) {
        const data = response.data.suppliers.map((item, index) => ({
          ...item,
          no: page * query.take + index + 1
        }));
        setRows(data);
        setCountTotal(response.data.count);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      setLoading(false);
    }
  };

  const duplicateClassification = (mess) => {
    if (mess.includes('(`companyName`)')) {
      setDuplicateError((pre) => ({ ...pre, companyName: true }));
    } else if (mess.includes('(`phoneNo`)')) {
      setDuplicateError((pre) => ({ ...pre, phoneNo: true }));
    } else if (mess.includes('(`email`)')) {
      setDuplicateError((pre) => ({ ...pre, email: true }));
    } else {
      const messenger = 'Something went wrong!';
      toast.error(messenger, { autoClose: 2000, theme: 'colored' });
    }
  };

  const columns = [
    { field: 'no', headerName: 'No.', minWidth: 50, maxWidth: 50, type: 'number' },
    { field: 'companyName', headerName: 'Company Name', flex: 1, minWidth: 140 },
    { field: 'companyCode', headerName: 'Company Code', flex: 1, minWidth: 140 },
    { field: 'poc', headerName: 'POC', flex: 0.5, minWidth: 100 },
    { field: 'phoneNo', headerName: 'Phone No', type: 'number', flex: 0.5, minWidth: 100 },
    { field: 'address', headerName: 'Address', flex: 2, minWidth: 200 },
    { field: 'contact', headerName: 'Contact', flex: 1, minWidth: 120 },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <div className={`action_btn ${supplierId.length > 1 ? 'disable' : ''}`}>
            <EditIcon
              onClick={() => {
                setCreateSupplier(params.row);
                setOpenModalEdit(true);
              }}
            />
            <DeleteIcon
              sx={{ color: '#d32f2f' }}
              onClick={() => {
                selectUsers(params.row.supplierId);
                setCreateSupplier(params.row);
                setOpenModalDelete(true);
              }}
            />
          </div>
        );
      }
    }
  ];

  const handleCreate = async () => {
    setLoading(true);
    try {
      const response = await createSupplierCustomer(createSupplier);
      if (response) {
        setCreateSupplier(dataSupplier);
        let newCustomer = [...rows];
        const contact = { ...response.data, no: +1 };
        if (rows.length === query.take) {
          newCustomer.pop();
        }
        newCustomer = newCustomer.map((item) => ({ ...item, no: item.no + 1 }));
        newCustomer.unshift(contact);
        setRows(newCustomer);
        setCountTotal((pre) => pre + 1);
        setLoading(false);

        setOpenModalEdit(false);
        setAction(false);
        toast.success('Create successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      setAction(false);
      duplicateClassification(error.response.data.errors);
      setLoading(false);
    }
  };

  const handleDeleteSupplier = async () => {
    let response;
    setLoading(true);
    try {
      if (supplierId.length > 1) {
        response = await deleteSuppliers(supplierId);
      } else {
        response = await deleteSupplier(supplierId[0] || createSupplier.supplierId);
      }
      if (response) {
        getListSuppliers(param);
        setCreateSupplier(dataSupplier);
        setSupplierId([]);
        setOpenModalDelete(false);
        toast.success('Delete successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setAction(false);
      setLoading(false);
    }
  };

  const handleUpdateSupplierCustomer = async () => {
    setLoading(true);
    try {
      const response = await updateSupplierCustomer(createSupplier);
      if (response) {
        setCreateSupplier(dataSupplier);
        const updateUserContact = rows.map((contact, index) => {
          if (contact.supplierId === response.data.supplierId) {
            return { ...response.data, no: page * query.take + index + 1 };
          }
          return contact;
        });
        setRows(updateUserContact);
        setSupplierId([]);
        setLoading(false);

        setOpenModalEdit(false);
        setAction(false);
        toast.success('Update successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      setAction(false);
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const selectUsers = (idSupplier) => {
    setSupplierId(idSupplier);
  };

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
    setCreateSupplier(dataSupplier);
  };

  const handleAction = () => {
    if (action) {
      return;
    }
    action = true;
    setAction(true);
    createSupplier.supplierId ? handleUpdateSupplierCustomer() : handleCreate();
  };

  return (
    <div className="customerPage">
      {loading ? <Loading open={loading} /> : null}
      {openModalDelete ? (
        <ModalDelete
          open={openModalDelete}
          handleClose={() => {
            setOpenModalDelete(false);
            setCreateSupplier(dataSupplier);
          }}
          handleDelete={handleDeleteSupplier}
        />
      ) : null}
      {openModalEdit ? (
        <ModalEdit
          open={openModalEdit}
          handleClose={handleCloseModalEdit}
          data={createSupplier}
          setData={setCreateSupplier}
          duplicate={duplicateError}
          setDuplicate={setDuplicateError}
          noDuplicate={duplicate}
          handleAction={handleAction}
          page="CONTACT"
        />
      ) : null}
      <div className="Orderpage_info">
        <div className="info_user">
          <div className="input_info btn_action">
            <Button variant="contained" onClick={() => setOpenModalEdit(true)}>
              <AddIcon />
              <span>CREATE CONTACT</span>
            </Button>
          </div>
        </div>
        <div>
          <SelectDropdown
            selectType="selectSupplier"
            textDefault="Supplier"
            setData={(value) => {
              setParam((pre) => {
                return { ...pre, type: value };
              });
              setCreateSupplier((pre) => ({ ...pre, type: value }));
              setSupplierId([]);
              getListSuppliers({ ...param, type: value });
            }}
          />
        </div>
      </div>
      <div className="OrderPage_table">
        <Table
          columns={columns}
          rows={rows}
          pageSize={param.take}
          setPage={setPage}
          totalRow={countTotal}
          data={supplierId}
          loading={loading}
          onSelectionCheckBox={selectUsers}
          getRowId={(row) => {
            return row.supplierId;
          }}
        />
      </div>
      <div className="OrderPage_btn btn_action">
        <Button
          sx={{ backgroundColor: '#d32f2f' }}
          className={`${supplierId.length < 1 ? 'disable' : ''}`}
          disabled={supplierId.length < 1}
          variant="contained"
          color="error"
          onClick={() => {
            setOpenModalDelete(true);
          }}
        >
          Delete selected
        </Button>
      </div>
    </div>
  );
}
