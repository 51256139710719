import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };

export const parseQuery = (payload) => {
  let keys = Object.keys(payload);
  keys = keys.filter(
    (key) =>
      payload[key] !== undefined &&
      payload[key] !== null &&
      payload[key].toString().trim() !== '' &&
      !Array.isArray(payload[key])
  );

  let strQuery = keys.map((key) => `${key}=${payload[key]}`).join('&');
  return strQuery ? `?${strQuery}` : '';
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      if (!window.location.href.includes('/login')) {
        window.location.href = '/login';
        localStorage.removeItem('username');
        localStorage.removeItem('access');
        localStorage.removeItem('token');
      }
    }
    throw error;
  }
);

export const API_BASE = axios;
