import 'flatpickr/dist/themes/material_green.css';
import flatpickr from 'flatpickr';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment-timezone';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import './DatePicker.css';

const DatePicker = ({ setOpen, setDate, setData, date, setTextSelect }) => {
  const [calendarFlatpickr, setCalendarFlatpickr] = useState();

  useEffect(() => {
    let calendar = flatpickr('.flatpickr__picker', {
      dateFormat: 'Y/m',
      mode: 'range',
      locale: 'en',
      clickOpens: false,
      disableMobile: true,
      onChange: function (_selectedDates, dateStr, _instance) {
        if (_selectedDates.length > 1) {
          setOpen(false);
          const fromDate = moment(_selectedDates[0]).format('YYYY-MM-DD');
          const startDate = moment(_selectedDates[1]).format('YYYY-MM-DD');
          const timeValue = fromDate + ' / ' + startDate;
          setData(timeValue);
          setTextSelect(timeValue);
          setDate({
            from_date: fromDate,
            to_date: startDate
          });
        }
      }
    });
    setCalendarFlatpickr(calendar);

    return () => {
      setCalendarFlatpickr(undefined);
    };
  }, []);

  const dateValue = () => {
    let result = 'Select time';
    if (date?.from_date && date?.to_date) {
      result =
        moment(date?.from_date).format('YY-MM-DD') +
        ' / ' +
        moment(date?.to_date).format('YY-MM-DD');
    }
    return result;
  };

  return (
    <Box className="date_picker">
      <input
        type="text"
        onClick={() => {
          calendarFlatpickr?.toggle?.();
        }}
        className="flatpickr__picker"
      />
      <p>Custom range</p>
      {/* <p>{dateValue()}</p> */}
      <Box>
        {/* {date?.from_date ? (
          <ClearIcon
            className="icon"
            sx={{ zIndex: '9' }}
            onClick={() => {
              setDate({
                from_date: '',
                to_date: ''
              });
              setData('today');
              setTextSelect('Today');
            }}
          />
        ) : ( */}
        <CalendarMonthIcon className="icon" />
        {/* )} */}
      </Box>
    </Box>
  );
};

export default DatePicker;
