import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import html2pdf from 'html2pdf.js';
import { useEffect, useRef, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import { getSupplier } from '../../Api/contact';
import AccountPrint from '../AccountPrint/AccountPrint';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import Table from '../Table/Table';
import './Payable.css';

const PayableComponent = ({
  setOpenModalDelete,
  loading,
  setReceivablesId,
  receivablesId,
  rowTotal,
  setQueryAccount,
  queyAccount,
  setOpenModalEdit,
  columnsPayable,
  setPage,
  receivables,
  selectAccountId,
  prints,
  orderLastMonth,
  totalRecei
}) => {
  const [supplier, setSupplier] = useState([]);
  const PrintRef = useRef();

  const printPayable = useReactToPrint({
    content: () => PrintRef.current,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName('html')[0];
        await html2pdf()
          .set({
            margin: 5,
            filename: `payable ${Math.floor(100000 + Math.random() * 900000)}`
          })
          .from(html)
          .save();
        toast.success('Print successfully!', {
          autoClose: 2000,
          theme: 'colored'
        });
      }
    }
  });

  const handlePrint = () => {
    const company = prints[0]?.Supplier?.supplierId;
    const differentCompany = prints.some((order) => order?.Supplier?.supplierId !== company);
    if (differentCompany) {
      toast.error('Please select the orders in the same company to print invoice!', {
        autoClose: 2000,
        theme: 'colored'
      });
    } else {
      printPayable();
    }
  };

  useEffect(() => {
    getListSuppliers();
  }, []);

  const getListSuppliers = async () => {
    try {
      const response = await getSupplier({
        skip: 0,
        take: 10,
        companyName: '',
        type: 'supplier',
        isNoneLimit: true
      });
      if (response) {
        setSupplier(response.data.suppliers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="OrderPage_table payable">
        <div className="Orderpage_info">
          <div className="filter_action">
            <div className="company info">
              <span>Company Name</span>
              <div className="dropdown">
                <SelectDropdown
                  data={supplier}
                  selectType="filterCompany"
                  textDefault="All"
                  row={{}}
                  setData={(value) =>
                    setQueryAccount((pre) => {
                      return { ...pre, supplierId: +value };
                    })
                  }
                />
              </div>
            </div>
            <div className="OrderPage_search">
              <div className="OrderPage_search_box">
                <div>
                  <SearchIcon />
                </div>
                <DebounceInput
                  debounceTimeout={1000}
                  placeholder="Invoice No."
                  value={queyAccount.invoiceNo}
                  onChange={(e) => {
                    setQueryAccount((pre) => ({ ...pre, invoiceNo: e.target.value }));
                  }}
                />
                {queyAccount.invoiceNo.length > 0 && (
                  <ClearIcon
                    sx={{ cursor: 'pointer', opacity: 0.5 }}
                    onClick={() => setQueryAccount((pre) => ({ ...pre, invoiceNo: '' }))}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div id="payable" type="payable" ref={PrintRef}>
          <AccountPrint data={prints} orderLastMonth={orderLastMonth} />
        </div>
        <div className="info_user">
          <div className="input_info btn_action">
            <Button variant="contained" onClick={() => setOpenModalEdit(true)}>
              <AddIcon />
              <span>CREATE PAYABLE</span>
            </Button>
          </div>
        </div>
        <Table
          columns={columnsPayable}
          rows={receivables}
          pageSize={queyAccount.take}
          setPage={setPage}
          totalRow={rowTotal}
          loading={loading}
          data={receivablesId}
          onSelectionCheckBox={selectAccountId}
          getRowId={(row) => {
            return row.deliveryOrderNo;
          }}
        />
      </div>
      <Box
        className="account-bottom payable"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div className="OrderPage_btn btn_action btn_payable">
          <Button
            variant="contained"
            sx={{ backgroundColor: '#d32f2f' }}
            className={`${receivablesId.length < 1 ? 'disable' : ''}`}
            disabled={receivablesId.length < 1}
            color="error"
            onClick={() => setOpenModalDelete(true)}
          >
            Delete selected
          </Button>
          <Button
            className={`${receivablesId.length === 0 ? 'disable' : ''}`}
            disabled={receivablesId.length === 0}
            variant="contained"
            onClick={handlePrint}
          >
            Print
          </Button>
        </div>
        <div className="total_box">
          <h3>Total Amount:</h3>
          <div>${totalRecei?.toLocaleString()}</div>
        </div>
      </Box>
    </>
  );
};

export default PayableComponent;
