import { Box } from '@mui/material';
import SelectDropdown from '../../SelectDropdown/SelectDropdown';

export default function ModalEditPayable({
  data,
  setData,
  validate,
  onKeyDownHandler,
  supplier,
  setDataSend
}) {
  return (
    <div>
      <Box sx={{ p: '0 0.7rem' }}>
        <div className="addOrder_form_input">
          <div className="label">
            <p>
              Company Name <span>*</span>
            </p>
            <SelectDropdown
              data={supplier}
              selectType="selectCompany"
              textDefault="Select Company"
              row={data}
              setData={(value) => {
                setData((pre) => {
                  return { ...pre, supplierId: +value };
                });
                setDataSend((pre) => {
                  return { ...pre, supplierId: +value };
                });
              }}
            />
            {validate && !data.supplierId && (
              <span className="mess-error">Please enter company name</span>
            )}
          </div>
          <div className="label">
            <p>
              Invoice No <span>*</span>
            </p>
            <input
              onKeyDown={onKeyDownHandler}
              type="text"
              placeholder="Please Input Invoice No"
              value={data.invoiceNo}
              onChange={(e) => {
                setData((pre) => {
                  return {
                    ...pre,
                    invoiceNo: e.target.value
                  };
                });
                setDataSend((pre) => {
                  return {
                    ...pre,
                    invoiceNo: e.target.value
                  };
                });
              }}
            />
            {validate && !data.invoiceNo && (
              <span className="mess-error">Please enter invoice no</span>
            )}
          </div>
          <div className="label">
            <p>
              Amount <span>*</span>
            </p>
            <input
              onKeyDown={onKeyDownHandler}
              type="text"
              placeholder="Please Input Amount"
              value={data.amount}
              onChange={(e) => {
                if (Number(e.target.value) || !e.target.value) {
                  setData((pre) => {
                    return {
                      ...pre,
                      amount: +e.target.value
                    };
                  });
                  setDataSend((pre) => {
                    return {
                      ...pre,
                      amount: +e.target.value
                    };
                  });
                }
              }}
            />
            {validate && !data.amount && <span className="mess-error">Please enter amount</span>}
          </div>
          <div className="label">
            <p>Paid</p>
            <div className="paid">
              <input
                type="checkbox"
                onChange={(e) => setData((pre) => ({ ...pre, paid: !data.paid }))}
                defaultChecked={data.paid}
              />
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}
