/* eslint-disable react-hooks/exhaustive-deps */
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import Box from '@mui/material/Box';
import html2pdf from 'html2pdf.js';
import { useEffect, useRef, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';

import { getSupplier } from '../../Api/contact';
import { addTaxOrder, getOrdersRefunds } from '../../Api/order';
import Invoice from '../../components/Invoice/Invoice';
import Loading from '../../components/Loading/Loading';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import Table from '../../components/Table/Table';
import '../Order/Order.css';
import ModalGst from '../../components/ModalGST/ModalGST';
import ModalEdit from '../../components/ModalEdit/ModalEdit';

export default function Refunds() {
  const queryOrder = {
    skip: 0,
    take: 10,
    orderBy: 'desc',
    invoiceNo: '',
    deliveryDate: '',
    companyName: ''
  };
  const componentRef = useRef();
  const [query, setQuery] = useState(queryOrder);
  const [supplier, setSupplier] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModalGst, setOpenModalGst] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [orderPrints, setOrderPrints] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowTotal, setRowTotal] = useState(0);
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [gstFinal, setGstFinal] = useState({ gst: '', isGst: true, default: '' });
  const [dataCreateOrder, setDataCreateOrder] = useState({});

  useEffect(() => {
    const newQuery = { ...query, skip: query.take * page };
    getRefunds(newQuery);
  }, [page, query]);

  useEffect(() => {
    getListSuppliers();
  }, []);

  const createInvoice = useReactToPrint({
    content: () => componentRef.current,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName('html')[0];
        await html2pdf()
          .set({
            margin: [1, 6, 0, 6],
            filename:
              `Invoice_${invoiceNo}` || `Invoice_${Math.floor(100000 + Math.random() * 900000)}`
          })
          .from(html)
          .toPdf()
          .get('pdf')
          .then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10.5);
              pdf.setTextColor('#000');
              pdf.text(
                'Page ' + i,
                pdf.internal.pageSize.getWidth() / 2.15,
                pdf.internal.pageSize.getHeight() - 5.85
              );
            }
          })
          .save();
      }
    }
  });

  const getListSuppliers = async () => {
    try {
      const response = await getSupplier({
        skip: 0,
        take: 10,
        companyName: '',
        type: 'customer',
        isNoneLimit: true
      });
      if (response) {
        setSupplier(response.data.suppliers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRefunds = async (params) => {
    setLoading(true);
    try {
      const response = await getOrdersRefunds(params);
      if (response) {
        const data = response?.data?.orders?.map((item, index) => ({
          ...item,
          no: page * query.take + index + 1
        }));
        setRowTotal(response.data.count);
        setRows(data);
        setLoading(false);
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      setLoading(false);
    }
  };

  const handleTaxOrder = async (orderId, body) => {
    try {
      await addTaxOrder(orderId, body);
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
    }
  };

  const handleCreateInvoice = () => {
    createInvoice();
    handleTaxOrder(invoiceNo, { tax: !!gstFinal.gst });
    setOpenModalGst(false);
    toast.success('Create invoice successfully!', { autoClose: 2000, theme: 'colored' });
  };

  const AmountOrder = ({ params }) => {
    const amount = params.row?.OrderItem.reduce(
      (total, order) => total + order?.quantity * order?.Fish?.priceOut,
      0
    );
    return <p>{amount.toLocaleString('vi-VN')}</p>;
  };

  const BtnAction = ({ params }) => {
    return (
      <Box sx={{ justifyContent: 'center', gap: '16px' }} className="action_btn">
        <VisibilityIcon
          onClick={() => {
            setDataCreateOrder(params.row);
            setOpenModalEdit(true);
          }}
        />
        <PrintIcon
          onClick={() => {
            setOpenModalGst(true);
            setOrderPrints([params.row]);
            setInvoiceNo(params.row?.invoiceNo);
          }}
        />
      </Box>
    );
  };

  const columnsDelivery = [
    { field: 'no', headerName: 'No.', minWidth: 30, maxWidth: 50, type: 'number' },
    {
      field: 'invoiceNo',
      headerName: 'invoice No.',
      minWidth: 100,
      maxWidth: 100,
      valueGetter: (params) => params.row?.invoiceNo
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row?.Supplier?.companyName
    },
    {
      field: 'orderDate',
      headerName: 'Order Date',
      minWidth: 120,
      maxWidth: 120,
      valueGetter: (params) => new Date(params.row?.orderDate)?.toLocaleDateString()
    },
    {
      field: 'deliveryDate',
      headerName: 'Delivery Date',
      minWidth: 120,
      maxWidth: 120,
      valueGetter: (params) => new Date(params.row?.deliveryDate)?.toLocaleDateString()
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      minWidth: 100,
      maxWidth: 150,
      renderCell: (params) => {
        return <AmountOrder params={params} />;
      }
    },
    {
      field: 'remark',
      headerName: 'Remarks',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => params.row?.remark
    },
    {
      field: 'action',
      headerName: 'Action',
      maxWidth: 100,
      minWidth: 100,
      renderCell: (params) => {
        return <BtnAction params={params} />;
      }
    }
  ];

  return (
    <div className="OrderPage orders">
      {loading ? <Loading open={loading} /> : null}
      {openModalGst ? (
        <ModalGst
          open={openModalGst}
          handleClose={() => setOpenModalGst(false)}
          handleAction={handleCreateInvoice}
          setGstFinal={setGstFinal}
          gstFinal={gstFinal}
        />
      ) : null}
      {openModalEdit ? (
        <ModalEdit
          open={openModalEdit}
          handleClose={() => setOpenModalEdit(false)}
          data={dataCreateOrder}
          page="ORDER"
        />
      ) : null}
      <Box sx={{ width: '100%' }}>
        <div className="Orderpage_info">
          <div className="Orderpage_filter">
            <div className="company info">
              <span>Company Name</span>
              <SelectDropdown
                data={supplier}
                selectType="filterCompanyByName"
                textDefault="All"
                row={{}}
                setData={(data) => {
                  setQuery((pre) => ({
                    ...pre,
                    companyName: data,
                    skip: 0
                  }));
                }}
              />
            </div>
            <div className="delivery_date info">
              <span>Delivery Date</span>
              <input
                type="date"
                value={query.deliveryDate}
                onChange={(e) => setQuery((pre) => ({ ...pre, deliveryDate: e.target.value }))}
              />
              {query.deliveryDate && (
                <ClearIcon
                  sx={{
                    cursor: 'pointer',
                    opacity: 0.5,
                    position: 'absolute',
                    height: '20px',
                    top: '50%',
                    right: '30px',
                    transform: 'translateY(-50%)'
                  }}
                  onClick={() => setQuery((pre) => ({ ...pre, deliveryDate: '' }))}
                />
              )}
            </div>
            <Box className="OrderPage_search info">
              <span>Invoice No</span>
              <Box sx={{ maxWidth: '180px' }} className="OrderPage_search_box">
                <div>
                  <SearchIcon />
                </div>
                <DebounceInput
                  debounceTimeout={1000}
                  placeholder="Invoice No."
                  value={query.invoiceNo}
                  onChange={(e) => {
                    setQuery((pre) => ({ ...pre, invoiceNo: e.target.value }));
                  }}
                />
                {query?.invoiceNo?.length > 0 && (
                  <ClearIcon
                    sx={{ cursor: 'pointer', opacity: 0.5 }}
                    onClick={() => setQuery((pre) => ({ ...pre, invoiceNo: '' }))}
                  />
                )}
              </Box>
            </Box>
          </div>

          <div className="OrderPage_table">
            <Table
              columns={columnsDelivery}
              rows={rows}
              pageSize={query.take}
              totalRow={rowTotal}
              setPage={setPage}
              loading={loading}
              hideFooterSelectedRowCount={true}
              hiddenSection={true}
              onSelectionCheckBox={() => {}}
              getRowId={(row) => {
                return row.deliveryOrderNo;
              }}
            />
          </div>
          <div id="content" ref={componentRef}>
            <Invoice data={orderPrints} gst={gstFinal} invoiceNo={invoiceNo} />
          </div>
        </div>
      </Box>
    </div>
  );
}
