import { API_BASE, parseQuery } from './apiConfig';

export const getUsers = async (query) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.get(
    `api/users${parseQuery(query)}`,
    token
  );
  return data;
};

export const createUser = async (user) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.post('api/user', user, token);
  return data;
};

export const updateUser = async (userid, user) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.patch(`api/user/${userid}`, user, token);
  return data;
};

export const deleteUser = async (userId) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.delete(`api/user/${userId}`, token);
  return data;
};

export const deleteUsers = async (listUserId) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  let url = '';
  listUserId.forEach((id, index) => {
    if (index < listUserId.length - 1) {
      url += `id=${id}&`;
    } else {
      url += `id=${id}`;
    }
  });
  const { data } = await API_BASE.delete(`api/users?${url}`, token);
  return data;
};
