import Refunds from '../Page/Refund/Refund';
import Setting from '../Page/Setting/Setting';
import {
  Home,
  Stocks,
  Account,
  Customer,
  Fulfillment,
  Inventory,
  Company,
  Order,
  Fish
} from '../Page/index';
import { ROUTER } from '../common/constants';

export const routerAccount = [{ path: ROUTER.ACCOUNT, element: <Account /> }];

export const routerCrew = [
  { path: ROUTER.HOME, element: <Home /> },
  { path: ROUTER.FULFILLMENT, element: <Fulfillment /> },
  { path: ROUTER.STOCKS, element: <Stocks /> },
  { path: ROUTER.INVENTORY, element: <Inventory /> }
];

export const routerAdmin = [
  { path: ROUTER.HOME, element: <Home /> },
  { path: ROUTER.ACCOUNT, element: <Account /> },
  { path: ROUTER.ORDER, element: <Order /> },
  { path: ROUTER.REFUNDS, element: <Refunds /> },
  { path: ROUTER.FULFILLMENT, element: <Fulfillment /> },
  { path: ROUTER.CONTACTS, element: <Customer /> },
  { path: ROUTER.STOCKS, element: <Stocks /> },
  { path: ROUTER.INVENTORY, element: <Inventory /> },
  { path: ROUTER.FISH, element: <Fish /> },
  { path: ROUTER.SETTING, element: <Setting /> }
  // { path: ROUTER.COMPANY, element: <Company /> }
];
