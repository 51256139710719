import './NavbarMobile.css';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../Assets/Images/login-logo.jpg';

const NavbarMobile = ({ setOpenMenu }) => {
  const navigate = useNavigate();

  return (
    <div className="navbar_menu_mobile">
      <div className="navbar_user_logo">
        <img
          src={Logo}
          alt=""
          onClick={() => {
            setOpenMenu(false);
            navigate('/');
          }}
        />
      </div>
      <div
        className="menu_mobile"
        onClick={() => {
          setOpenMenu(true);
          window.scrollTo(0, 0);
        }}
      >
        <MenuIcon sx={{ fontSize: '2rem', color: '#fff', cursor: 'pointer' }} />
      </div>
    </div>
  );
};

export default NavbarMobile;
