import ClearIcon from '@mui/icons-material/Clear';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import './ModalCalendar.css';
import DeleteIcon from '@mui/icons-material/Delete';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  borderRadius: '0.5rem',
  border: '0.0625rem solid #d1d7e0',
  boxShadow: 24,
  p: 1,
  pt: 0.5
};

export default function ModalCalendar({
  open,
  handleClose,
  handleActionEvent,
  setEvent,
  event,
  setOpenModalDelete,
  setInvalidTime,
  invalidTime
}) {
  const [validate, setValidate] = useState(false);

  const handleSubmit = async () => {
    if (!event.summary) {
      setValidate(true);
      return;
    }
    handleActionEvent();
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        onKeyDown={onKeyDownHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="edit_modal calendar_modal">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              p: '4px 0.7rem',
              position: 'relative'
            }}
          >
            <h4>{event.id ? 'Update Event' : 'Create Event'}</h4>
            {event.id ? (
              <DeleteIcon
                sx={{ color: '#d32f2f', marginRight: '8px', cursor: 'pointer' }}
                onClick={() => {
                  setOpenModalDelete(true);
                }}
              />
            ) : null}
            <ClearIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
          </Box>
          <Box>
            <Box sx={{ p: '0 0.7rem' }}>
              <div className="addOrder_form_input">
                <div className="label">
                  <p>
                    Event Name <span>*</span>
                  </p>
                  <textarea
                    type="text"
                    placeholder="Please Input Event Name"
                    value={event.summary}
                    onKeyDown={onKeyDownHandler}
                    onChange={(e) => {
                      setValidate(false);
                      setEvent((pre) => ({ ...pre, summary: e.target.value }));
                    }}
                  />
                  {validate && !event.summary && (
                    <span className="mess-error">Please enter event name</span>
                  )}
                </div>
                <div className="label">
                  <p>Description</p>
                  <input
                    type="text"
                    onKeyDown={onKeyDownHandler}
                    placeholder="Please Input Description"
                    value={event.description}
                    onChange={(e) => setEvent((pre) => ({ ...pre, description: e.target.value }))}
                  />
                </div>
                <div className="label">
                  <p>Start of Your Event</p>
                  <DatePicker
                    selected={new Date(event.start.dateTime)}
                    onChange={(date) => {
                      setEvent((pre) => ({
                        ...pre,
                        start: {
                          dateTime: date
                        }
                      }));
                      setInvalidTime(false);
                    }}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    timeFormat="p"
                    timeIntervals={15}
                    dateFormat="Pp"
                  />
                </div>
                <div className="label">
                  <p>End of Your Event</p>
                  <DatePicker
                    selected={new Date(event.end.dateTime)}
                    onChange={(date) => {
                      setEvent((pre) => ({
                        ...pre,
                        end: {
                          dateTime: date
                        }
                      }));
                      setInvalidTime(false);
                    }}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    timeFormat="p"
                    timeIntervals={15}
                    dateFormat="Pp"
                  />
                  {invalidTime && <span className="mess-error">Invalid date and time</span>}
                </div>
              </div>
            </Box>
            <Box
              id="modal-modal-description"
              sx={{
                mt: 2,
                mb: 1,
                display: 'flex',
                gap: 3,
                justifyContent: 'center',
                '& .MuiButtonBase-root': {
                  minWidth: '50px',
                  lineHeight: 1.25
                }
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#262b40',
                  fontWeight: 600,
                  '&:hover': { backgroundColor: '#262b40', opacity: 0.8 }
                }}
                onClick={handleSubmit}
              >
                {event.id ? 'Update' : 'Create'}
              </Button>
              <Button
                sx={{
                  borderColor: '#262b40',
                  fontWeight: 600,
                  color: '#262b40',
                  '&:hover': {
                    borderColor: '#262b40',
                    opacity: 0.8
                  }
                }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
