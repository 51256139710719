import { API_BASE, parseQuery } from './apiConfig';

export const getAccounts = async (param) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.get(`api/accounts${parseQuery(param)}`, token);
  return data;
};

export const getReports = async (param) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.get(`api/accounts/report?reportBy=${param}`, token);
  return data;
};

export const createAccount = async (body) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.post('api/accounts', body, token);
  return data;
};

export const updateAccount = async (body) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.patch(`api/accounts/${body.deliveryOrderNo}`, body, token);
  return data;
};

export const updateAccountPaid = async (listID, body) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  let url = '';
  listID.forEach((id, index) => {
    if (index < listID.length - 1) {
      url += `invoiceNo=${id}&`;
    } else {
      url += `invoiceNo=${id}`;
    }
  });
  const { data } = await API_BASE.patch(`api/accounts?${url}&paid=${body.paid}`, body, token);
  return data;
};

export const deletePayable = async (id) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.delete(`api/accounts/${id}`, token);
  return data;
};

export const deleteManyPayable = async (listId) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  let url = '';
  listId.forEach((id, index) => {
    if (index < listId.length - 1) {
      url += `id=${id}&`;
    } else {
      url += `id=${id}`;
    }
  });
  const { data } = await API_BASE.delete(`api/accounts?${url}`, token);
  return data;
};
