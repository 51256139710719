import '../SelectDropdown.css';

export default function SelectDropdownDefault({
  open,
  setOpen,
  select,
  setData,
  setTextSelect,
  value,
  data,
  textDefault,
  handleChange
}) {
  const newUser = [
    { text: 'Admin', access: 'admin' },
    { text: 'Accountant', access: 'accountant' },
    { text: 'Crew', access: 'crew' }
  ];
  return (
    <div>
      {value === 'selectSupplier' && (
        <ul className={`list_item ${open ? 'show' : ''}`} ref={select}>
          <li
            onClick={() => {
              setData('supplier');
              setTextSelect('Supplier');
              setOpen(false);
            }}
          >
            <p className="text_overflow">Supplier</p>
          </li>
          <li
            onClick={() => {
              setData('customer');
              setTextSelect('Customer');
              setOpen(false);
            }}
          >
            <p className="text_overflow">Customer</p>
          </li>
        </ul>
      )}
      {value === 'default' && (
        <ul className={`list_item ${open ? 'show' : ''}`} ref={select}>
          {data.length > 0 && (
            <li>
              <p className="text_overflow">{textDefault}</p>
            </li>
          )}
          {!data.length && <li>Out of Fish</li>}
          {data.map((item) => (
            <li
              key={item.id}
              onClick={() => {
                handleChange(item.id, item.fishName);
              }}
            >
              <p className="text_overflow">{item.fishName}</p>
            </li>
          ))}
        </ul>
      )}
      {value === 'selectAccount' && (
        <ul className={`list_item ${open ? 'show' : ''}`} ref={select}>
          {newUser.map((item) => (
            <li
              key={item.access}
              onClick={() => {
                setData(item.access);
                setTextSelect(item.text);
                setOpen(false);
              }}
            >
              <p className="text_overflow">{item.text}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
