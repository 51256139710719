import { useEffect, useRef, useState } from 'react';

import Loading from '../../components/Loading/Loading';
import './Setting.css';
import { Button } from '@mui/material';
import { getGST, updateGST } from '../../Api/order';
import { toast } from 'react-toastify';

export default function Setting() {
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [gst, setGst] = useState(0);
  const inputRef = useRef(null);

  const getValueGST = async () => {
    try {
      setLoading(true);
      const response = await getGST();
      if (response) {
        let value = response.data[0].value * 100;
        setGst(value);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleUpdateGst = async () => {
    if (!update || !gst) {
      setUpdate(true);
      inputRef.current.focus();
      return;
    }
    setLoading(true);
    try {
      const response = await updateGST({ value: Number(gst) / 100 });
      if (response) {
        toast.success('Update successfully!', { autoClose: 2000, theme: 'colored' });
        inputRef.current.blur();
      }
    } catch (error) {
      toast.success('Something went wrong!', { autoClose: 2000, theme: 'colored' });
    } finally {
      setUpdate(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    getValueGST();
  }, []);

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleUpdateGst();
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="SettingPage">
        <div className="box">
          <p>GST</p>
          <input
            className={`input-quantity ${!gst && gst !== 0 ? 'no-quantity' : ''} ${
              update ? '' : 'disable'
            }`}
            type="text"
            ref={inputRef}
            value={gst}
            onChange={(e) => {
              if (Number(e.target.value) || !e.target.value || Number(e.target.value) >= 0)
                setGst(e.target.value);
            }}
            onKeyDown={onKeyDownHandler}
          />
          <p>%</p>
        </div>
        <Button
          variant="contained"
          onClick={handleUpdateGst}
          sx={{
            backgroundColor: '#262b40',
            fontWeight: 600,
            '&:hover': { opacity: '0.8', backgroundColor: '#262b40' }
          }}
        >
          {update ? 'Save' : 'Update'}
        </Button>
      </div>
    </>
  );
}
