import { Box } from '@mui/material';
import '../ModalEdit.css';

export default function ModalEditCompany({ data, setData, validate, onKeyDownHandler }) {
  return (
    <div>
      <Box>
        <div className="addUser_form_input">
          <div className="label">
            <p>
              Company Name <span>*</span>
            </p>
            <input
              onKeyDown={onKeyDownHandler}
              type="text"
              value={data.companyName}
              onChange={(e) =>
                setData((pre) => {
                  return { ...pre, companyName: e.target.value };
                })
              }
            />
            {validate && !data.companyName && (
              <span className="mess-error">Please enter company name</span>
            )}
          </div>
          <div className="label">
            <p>
              Address <span>*</span>
            </p>
            <textarea
              onKeyDown={onKeyDownHandler}
              type="text"
              value={data.address}
              onChange={(e) =>
                setData((pre) => {
                  return { ...pre, address: e.target.value };
                })
              }
            />
            {validate && !data.address && <span className="mess-error">Please enter address</span>}
          </div>
          <div className="label">
            <p>
              Code <span>*</span>
            </p>
            <input
              onKeyDown={onKeyDownHandler}
              type="text"
              value={data.code}
              onChange={(e) => {
                if (Number(e.target.value) || !e.target.value || e.target.value.startsWith('0'))
                  setData((pre) => {
                    return { ...pre, code: e.target.value };
                  });
              }}
            />
            {validate && !data.code && <span className="mess-error">Please enter code</span>}
          </div>
        </div>
      </Box>
    </div>
  );
}
