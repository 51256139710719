import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import './Loading.css';

const Loading = ({ open }) => {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: '999999' }} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default Loading;
