/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

const Calendar = ({ setOpenModalCalendar, setEventSelect, events, listUpcomingEvents, event }) => {
  const gapi = window.gapi;
  const google = window.google;

  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const API_KEY = process.env.REACT_APP_API_KEY;

  const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
  const SCOPES = 'https://www.googleapis.com/auth/calendar';

  const [tokenClient, setTokenClient] = useState();
  const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'));
  const [expiresIn, setExpiresIn] = useState(localStorage.getItem('expires_in'));

  let gapiInited = false,
    gisInited = false;

  useEffect(() => {
    //const expiryTime = new Date().getTime() + expiresIn * 1000;
    gapiLoaded();
    gisLoaded();
  }, []);

  useEffect(() => {
    if (tokenClient && !accessToken && !expiresIn) {
      handleAuthClick();
    }
  }, [tokenClient]);

  useEffect(() => {
    if (accessToken) {
      listUpcomingEvents();
    }
  }, [accessToken]);

  useEffect(() => {
    setAccessToken(localStorage.getItem('access_token'));
  }, [localStorage.getItem('access_token')]);

  useEffect(() => {
    setExpiresIn(localStorage.getItem('expires_in'));
  }, [localStorage.getItem('expires_in')]);

  function gapiLoaded() {
    gapi?.load('client', initializeGapiClient);
  }

  async function initializeGapiClient() {
    await gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC]
    });
    gapiInited = true;

    if (accessToken && expiresIn) {
      gapi.client.setToken({
        access_token: accessToken,
        expires_in: expiresIn
      });
      listUpcomingEvents();
    }
  }

  function gisLoaded() {
    const token = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: '' // defined later
    });
    gisInited = true;
    setTokenClient(token);
  }

  //Enables user interaction after all libraries are loaded.

  function handleAuthClick() {
    tokenClient.callback = async (resp) => {
      if (resp.error) {
        throw resp;
      }
      await listUpcomingEvents();
      const { access_token, expires_in } = gapi.client.getToken();
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('expires_in', expires_in);
    };

    if (!(accessToken && expiresIn)) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  //Sign out the user upon button click.

  const getEvent = (e) => {
    const eventRef = JSON.parse(JSON.stringify(e));
    const newEvent = {
      summary: eventRef.title,
      description: eventRef.extendedProps.description,
      id: eventRef.id,
      start: {
        dateTime: eventRef.start
      },
      end: {
        dateTime: eventRef.end || eventRef.start
      }
    };
    setEventSelect(newEvent);
    let modalEvent = document.querySelector('.fc-popover.fc-more-popover');
    if (modalEvent) {
      modalEvent.style.display = 'none';
    }
    setOpenModalCalendar(true);
  };

  return (
    <div>
      <FullCalendar
        firstDay={1}
        plugins={[dayGridPlugin]}
        customButtons={{
          myCustomButton: {
            text: 'Create Event',
            click: function () {
              setEventSelect(event);
              setOpenModalCalendar(true);
            }
          }
        }}
        initialView="dayGridMonth"
        headerToolbar={{
          start: 'prev,next',
          center: 'title',
          end: accessToken ? 'myCustomButton' : null
        }}
        locales={'vi'}
        dayMaxEvents={3}
        events={events}
        displayEventEnd={true}
        eventClick={(e) => getEvent(e.event)}
        contentHeight={755}
      />
    </div>
  );
};

export default Calendar;
