import { Box } from '@mui/material';

export default function ModalEditContact({
  data,
  setData,
  setDuplicate,
  validate,
  setValidEmail,
  validEmail,
  onKeyDownHandler,
  noDuplicate,
  duplicate
}) {
  return (
    <div>
      <Box sx={{ p: '0 0.7rem' }}>
        <div className="info_user">
          <div>
            <div className="input_info">
              <span>
                Company Name <span>*</span>
              </span>
              <input
                onKeyDown={onKeyDownHandler}
                type="text"
                value={data.companyName}
                onChange={(e) => {
                  setData((pre) => ({
                    ...pre,
                    companyName: e.target.value
                  }));
                  setDuplicate(noDuplicate);
                }}
              />
              {validate && !data.companyName && (
                <span className="mess-error">Please enter company name</span>
              )}
              {duplicate.companyName && <span className="mess-error">Company already exists</span>}
            </div>
            <div className="input_info">
              <span>
                Code <span>*</span>
              </span>
              <input
                onKeyDown={onKeyDownHandler}
                type="text"
                value={data.companyCode}
                onChange={(e) => {
                  setData((pre) => ({
                    ...pre,
                    companyCode: e.target.value
                  }));
                  setDuplicate(noDuplicate);
                }}
              />
              {validate && !data.companyCode && (
                <span className="mess-error">Please enter code</span>
              )}
            </div>
            <div className="input_info">
              <span>Point of Contact</span>
              <input
                onKeyDown={onKeyDownHandler}
                type="text"
                value={data.poc}
                onChange={(e) =>
                  setData((pre) => ({
                    ...pre,
                    poc: e.target.value
                  }))
                }
              />
            </div>
            <div className="input_info">
              <span>Phone No</span>
              <input
                onKeyDown={onKeyDownHandler}
                type="text"
                value={data.phoneNo}
                onChange={(e) => {
                  if (Number(e.target.value) || !e.target.value || Number(e.target.value) === 0) {
                    setData((pre) => ({
                      ...pre,
                      phoneNo: e.target.value
                    }));
                    setDuplicate(noDuplicate);
                  }
                }}
              />
              {duplicate.phoneNo && <span className="mess-error">Phone no already exists</span>}
            </div>
            <div className="input_info">
              <span>
                Address <span>*</span>
              </span>
              <textarea
                onKeyDown={onKeyDownHandler}
                type="text"
                value={data.address}
                onChange={(e) =>
                  setData((pre) => ({
                    ...pre,
                    address: e.target.value
                  }))
                }
              />
              {validate && !data.address && (
                <span className="mess-error">Please enter address</span>
              )}
            </div>
            <div className="input_info">
              <span>Email / WhatApp</span>
              <input
                onKeyDown={onKeyDownHandler}
                type="text"
                value={data.contact}
                onChange={(e) => {
                  setValidEmail(false);
                  setDuplicate(noDuplicate);
                  setData((pre) => ({
                    ...pre,
                    contact: e.target.value
                  }));
                }}
              />
              {duplicate.email && <span className="mess-error">Email already exists</span>}
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}
