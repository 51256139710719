import '../SelectDropdown.css';
import DatePicker from '../../DatePicker/DatePicker';
import { useState } from 'react';

export default function SelectDropdownFiller({
  open,
  setOpen,
  select,
  textDefault,
  data,
  setData,
  setTextSelect,
  value
}) {
  const [date, setDate] = useState();
  const handleClickTextDefault = () => {
    setOpen(false);
    setData('');
    setTextSelect('All');
  };

  return (
    <div>
      {value === 'filterCompany' && (
        <ul className={`list_item ${open ? 'show' : ''}`} ref={select}>
          <li
            onClick={() => {
              handleClickTextDefault();
            }}
          >
            {textDefault}
          </li>
          {data?.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                setData(item.id || item.supplierId);
                setTextSelect(item.companyName);
                setOpen(false);
              }}
            >
              <p className="text_overflow">{item.companyName}</p>
            </li>
          ))}
        </ul>
      )}
      {value === 'filterCompanyByName' && (
        <ul className={`list_item ${open ? 'show' : ''}`} ref={select}>
          <li
            onClick={() => {
              handleClickTextDefault();
            }}
          >
            {textDefault}
          </li>
          {data?.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                setData(item.companyName);
                setTextSelect(item.companyName);
                setOpen(false);
              }}
            >
              <p className="text_overflow">{item.companyName}</p>
            </li>
          ))}
        </ul>
      )}
      {value === 'filterDate' && (
        <ul className={`list_item ${open ? 'show' : ''}`} ref={select}>
          {data.map((item, index) =>
            item.value === 'custom' ? (
              <li key={index}>
                <DatePicker
                  setOpen={setOpen}
                  setData={setData}
                  setDate={setDate}
                  date={date}
                  setTextSelect={setTextSelect}
                />
              </li>
            ) : (
              <li
                key={index}
                onClick={() => {
                  setData(item.value);
                  setTextSelect(item.text);
                  setOpen(false);
                }}
              >
                <p className="text_overflow">{item.text}</p>
              </li>
            )
          )}
        </ul>
      )}
    </div>
  );
}
