import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import html2pdf from 'html2pdf.js';
import { createStock, deleteStock, deleteStocks, getStocks, updateStock } from '../../Api/stocks';
import Loading from '../../components/Loading/Loading';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import ModalEdit from '../../components/ModalEdit/ModalEdit';
import StockPrint from '../../components/StockPrint/StockPrint';
import Table from '../../components/Table/Table';
import './Stocks.css';
import { roundNumber } from '../../utils/utils';

const Stocks = () => {
  const queryStock = {
    skip: 0,
    take: 10,
    orderBy: 'desc'
  };
  const dataStock = { fishId: '', pricing: '', orderQuantity: '' };
  const [stockList, setStockList] = useState([]);
  const [stockListId, setStockListId] = useState([]);
  const [stockPrint, setStockPrint] = useState([]);
  const [query, setQuery] = useState(queryStock);
  const [stock, setStock] = useState(dataStock);
  const [loading, setLoading] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [countTotal, setCountTotal] = useState(0);
  const [deleteOne, setDeleteOne] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  let [action, setAction] = useState(false);
  const labelRef = useRef(null);

  const columns = [
    { field: 'no', headerName: 'No.', maxWidth: 50, type: 'number', minWidth: 50 },
    {
      field: 'fishName',
      headerName: 'Description',
      flex: 2,
      minWidth: 140,
      valueGetter: (params) => params.row.Fish?.fishName
    },
    {
      field: 'size',
      headerName: 'Size',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row.Fish?.size
    },
    {
      field: 'pricing',
      headerName: 'Pricing',
      type: 'number',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <div className="pricing-table">
            <span>$</span>
            <span>{roundNumber(params.row.pricing)}</span>
          </div>
        );
      }
    },
    {
      field: 'orderQuantity',
      headerName: 'Order Quantity	',
      type: 'number',
      flex: 1,
      minWidth: 130,
      valueGetter: (params) => params.row?.orderQuantity
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <div className={`action_btn ${stockListId.length > 1 ? 'disable' : ''}`}>
            <EditIcon
              onClick={() => {
                setStock(params.row);
                setOpenModalEdit(true);
              }}
            />
            <DeleteIcon
              sx={{ color: '#d32f2f' }}
              onClick={() => {
                selectStock(params.row.id);
                setStock(params.row);
                setDeleteOne(true);
                setOpenModalDelete(true);
              }}
            />
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    const newQuery = {
      ...query,
      skip: query.take * page
    };
    setQuery(newQuery);
    getAllStock(newQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const createInvoice = useReactToPrint({
    content: () => labelRef.current,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName('html')[0];
        await html2pdf()
          .set({
            filename: `Stock_${Math.floor(100000 + Math.random() * 900000)}`
          })
          .from(html)
          .save();
      }
    }
  });

  const getAllStock = async (param) => {
    setLoading(true);
    try {
      const response = await getStocks(param);
      if (response) {
        setStockList(response.data.stocks);
        const data = response.data.stocks.map((item, index) => ({
          ...item,
          no: page * query.take + index + 1
        }));
        setRows(data);
        setCountTotal(response.data.count);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
    setStock(dataStock);
  };

  const handleCreateStock = async (data) => {
    setLoading(true);
    try {
      const response = await createStock(data);
      if (response) {
        setStock(dataStock);
        let newStocks = [...rows];
        const newstock = { ...response.data, no: 1 };
        if (rows.length === query.take) {
          newStocks.pop();
        }
        newStocks = newStocks.map((item) => ({ ...item, no: item.no + 1 }));
        newStocks.unshift(newstock);
        setRows(newStocks);
        setStockList(newStocks);
        setAction(false);
        setCountTotal((pre) => pre + 1);
        setLoading(false);
        setOpenModalEdit(false);
        toast.success('Create successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      setAction(false);
      const mess = error?.response?.data?.errors || error?.response?.data?.message || "Something went wrong!";
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const handleUpdateStock = async (data) => {
    setLoading(true);
    try {
      const response = await updateStock(data);
      if (response) {
        setStock(dataStock);
        const updateStocks = rows.map((sto, index) => {
          if (sto.id === response.data.id) {
            return { ...response.data, no: page * query.take + index + 1 };
          }
          return sto;
        });

        setRows(updateStocks);
        setStockListId([]);
        setOpenModalEdit(false);
        setStockList(updateStocks);
        setLoading(false);
        setAction(false);
        toast.success('Update successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      const mess = error?.response?.data?.errors || error?.response?.data?.message || "Something went wrong!";
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setAction(false);
      setLoading(false);
    }
  };

  const selectStock = (stockId) => {
    setStockListId(stockId);
    if (Array.isArray(stockId)) {
      let stockListPrint = [];
      stockList.forEach((stocks) => {
        stockId.forEach((stockID) => {
          if (stocks.id === stockID) {
            stockListPrint.push(stocks);
          }
        });
      });
      setStockPrint(stockListPrint);
    }
  };

  const handleAction = (data) => {
    if (action) {
      return;
    }
    const newStock = { ...stock, orderQuantity: data?.orderQuantity ? +data?.orderQuantity : null };

    action = true;
    setAction(true);
    stock.id ? handleUpdateStock(newStock) : handleCreateStock(newStock);
  };

  const handleDeleteOneStock = async () => {
    setLoading(true);
    try {
      const response = await deleteStock(stock.id);
      if (response) {
        getAllStock(query);
        setStock(dataStock);
        setStockListId([]);
        setDeleteOne(false);
        setOpenModalDelete(false);
        toast.success('Delete successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      const mess = error?.response?.data?.errors || error?.response?.data?.message || "Something went wrong!";
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteStock = async () => {
    setLoading(true);
    let response;
    try {
      if (stockListId.length > 1) {
        response = await deleteStocks(stockListId);
      } else {
        response = await deleteStock(stockListId[0] || stock.id);
      }
      if (response) {
        if (stockList.length === 1 && query.skip !== 0) {
          getAllStock({ skip: query.skip - query.take, take: query.take });
        } else {
          getAllStock(query);
        }
        setStockListId([]);
        setOpenModalDelete(false);
        toast.success('Delete successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      const mess = error?.response?.data?.errors || error?.response?.data?.message || "Something went wrong!";
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const handleDelete = () => {
    deleteOne ? handleDeleteOneStock() : handleDeleteStock();
  };

  const handlePrint = () => {
    createInvoice();
  };

  return (
    <div className="Stocklist_page">
      {loading ? <Loading open={loading} /> : null}
      {openModalDelete ? (
        <ModalDelete
          open={openModalDelete}
          handleClose={() => {
            setOpenModalDelete(false);
            setStock(dataStock);
            setDeleteOne(false);
          }}
          handleDelete={handleDelete}
        />
      ) : null}
      {openModalEdit ? (
        <ModalEdit
          open={openModalEdit}
          data={stock}
          setData={setStock}
          handleClose={handleCloseModalEdit}
          handleAction={handleAction}
          page="STOCK"
        />
      ) : null}
      <div className="Orderpage_info">
        <div className="info_user">
          <div className="input_info btn_action">
            <Button
              variant="contained"
              onClick={() => {
                setOpenModalEdit(true);
              }}
            >
              <AddIcon /> CREATE STOCK
            </Button>
          </div>
        </div>
      </div>
      <div className="OrderPage_table">
        <Table
          columns={columns}
          loading={loading}
          rows={rows}
          setPage={setPage}
          pageSize={query.take}
          totalRow={countTotal}
          data={stockListId}
          onSelectionCheckBox={selectStock}
          getRowId={(row) => {
            return row.id;
          }}
        />
      </div>
      <div id="label_stock" ref={labelRef}>
        <StockPrint data={stockPrint} />
      </div>
      <div className="OrderPage_btn btn_action">
        <Button
          variant="contained"
          className={`${stockListId.length < 1 ? 'disable' : ''}`}
          disabled={stockListId.length < 1}
          onClick={handlePrint}
        >
          Print
        </Button>
        <Button
          sx={{ backgroundColor: '#d32f2f' }}
          variant="contained"
          color="error"
          className={`${stockListId.length < 1 ? 'disable' : ''}`}
          disabled={stockListId.length < 1}
          onClick={() => {
            setOpenModalDelete(true);
          }}
        >
          Delete selected
        </Button>
      </div>
    </div>
  );
};

export default Stocks;
