export const ACCESS = Object.freeze({
  ADMIN: 'admin',
  ACCOUNTANT: 'accountant',
  CREW: 'crew'
});

export const ORDER_STATUS = Object.freeze({
  ORDER_CREATED: 'order_created',
  ORDER_PENDING: 'order_pending',
  FULFILLMENT_CREATED: 'fulfillment_created',
  FULFILLMENT_PRINT: 'fulfillment_print'
});

export const ROUTER = Object.freeze({
  LOGIN: '/login',
  HOME: '/',
  FULFILLMENT: '/fulfillment',
  STOCKS: '/stocks',
  ORDER: '/order',
  REFUNDS: '/refunds',
  INVENTORY: '/inventory',
  ACCOUNT: '/accounts',
  CONTACTS: '/contacts',
  FISH: '/description',
  COMPANY: '/company',
  SETTING: '/setting'
});
