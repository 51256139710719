/* eslint-disable react-hooks/exhaustive-deps */
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import html2pdf from 'html2pdf.js';
import { useEffect, useRef, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import {
  createAccount,
  deleteManyPayable,
  deletePayable,
  getAccounts,
  getReports,
  updateAccount,
  updateAccountPaid
} from '../../Api/account';
import { getSupplier } from '../../Api/contact';
import Revenue from '../../Assets/Images/icons8-revenue-64.svg';
import Order from '../../Assets/Images/orders-icon.svg';
import Payable from '../../Assets/Images/payable.svg';
import Profit from '../../Assets/Images/profit.svg';
import Reciever from '../../Assets/Images/reciever.svg';
import AccountPrint from '../../components/AccountPrint/AccountPrint';
import Loading from '../../components/Loading/Loading';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import ModalEdit from '../../components/ModalEdit/ModalEdit';
import PayableComponent from '../../components/Payable/PayableComponent';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import TabPanel from '../../components/TabPane/TabPane';
import Table from '../../components/Table/Table';
import './Account.css';
import { convertDate, roundNumber } from '../../utils/utils';
import Refunds from '../Refund/Refund';
import { getGST } from '../../Api/order';

export default function Account() {
  const accountQuery = {
    skip: 0,
    take: 10,
    orderBy: 'desc',
    supplierId: '',
    invoiceNo: '',
    orderType: 'receivables'
  };
  const [queryStatistic, setQueryStatistic] = useState('today');
  const payable = { supplierId: '', invoiceNo: '', amount: '', paid: false, orderBy: 'desc' };
  const [queyAccount, setQueryAccount] = useState(accountQuery);
  const [dataPayable, setDataPayable] = useState(payable);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [receivables, setReceivables] = useState([]);
  const [page, setPage] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [receivablesId, setReceivablesId] = useState([]);
  const [accountUpdate, setAccountUpdate] = useState([]);
  const [accountID, setAccountID] = useState([]);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [value, setValue] = useState(0);
  const [supplier, setSupplier] = useState([]);
  const [prints, setPrints] = useState([]);
  const [toPrints, setToPrints] = useState([]);
  const [orderLastMonth, setOrderLastMonth] = useState([]);
  const [report, setReports] = useState({});
  const [render, setRender] = useState(false);
  const [gstDefault, setGstDefault] = useState(0);

  let [action, setAction] = useState(false);
  const componentRef = useRef();
  const time = [
    { text: 'Today', value: 'today' },
    { text: 'This Month', value: 'month' },
    { text: 'Year to Date', value: 'year' },
    { text: 'Custom', value: 'custom' }
  ];

  const printReceivables = useReactToPrint({
    content: () => componentRef.current,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName('html')[0];
        await html2pdf()
          .set({
            margin: [10, 10, 10, 10], 
            filename: `receivables ${Math.floor(100000 + Math.random() * 900000)}`,
            html2canvas: { scale: 2 } 
          })
          .from(html)
          .toPdf()
          .get('pdf')
          .then((pdf) => {
            // Add a page break to ensure the content fits well within pages
            // const totalPages = pdf.internal.getNumberOfPages();
            // for (let i = 1; i <= totalPages; i++) {
            //   pdf.setPage(i);
            //   pdf.text(
            //     `Page ${i} of ${totalPages}`,
            //     pdf.internal.pageSize.getWidth() - 40,
            //     pdf.internal.pageSize.getHeight() - 10
            //    );
            // }
          })
          .save();
        toast.success('Print successfully!', {
          autoClose: 2000,
          theme: 'colored'
        });
      }
    }
  });

  const getTime = (date) => {
    const month = new Date(date).getMonth();
    const year = new Date(date).getFullYear();
    return `${month + 1}/${year}`;
  };

  const handlePrint = () => {
    const company = toPrints[0].Supplier.supplierId;
    const time = getTime(toPrints[0].deliveryDate);
    const differentCompany = toPrints.some((order) => order.Supplier.supplierId !== company);
    const differentTime = toPrints.some((order) => getTime(order.deliveryDate) !== time);
    if (differentCompany) {
      toast.error('Please select the orders in the same company to print invoice!', {
        autoClose: 2000,
        theme: 'colored'
      });
    } else if (differentTime) {
      toast.error('Please select the orders in the same month to print invoice!', {
        autoClose: 2000,
        theme: 'colored'
      });
    } else {
      printReceivables();
    }
  };

  useEffect(() => {
    getListSuppliers();
  }, []);

  useEffect(() => {
    getListAccount(queyAccount);
    setAccountUpdate([]);
  }, [queyAccount]);

  useEffect(() => {
    getListReports();
  }, [queryStatistic]);

  useEffect(() => {
    if (render) {
      const newQuery = { ...queyAccount, skip: queyAccount.take * page };
      setQueryAccount(newQuery);
    }
  }, [page]);

  useEffect(() => {
    let newPrints = [];
    prints.forEach((item) => {
      receivablesId.forEach((id) => {
        if (item.invoiceNo === id || item.deliveryOrderNo === id) {
          const index = newPrints.findIndex(
            (order) => order.invoiceNo === id || order.deliveryOrderNo === id
          );
          if (index === -1) {
            newPrints.push(item);
          }
        }
      });
    });
    setToPrints(newPrints);
    if (newPrints.length) {
      getListAccountBySupplier(newPrints[0]);
    }
  }, [receivablesId]);

  const getValueGST = async () => {
    try {
      const response = await getGST();
      if (response) {
        let value = response.data[0].value * 100;
        setGstDefault(value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getValueGST();
  }, []);

  const selectAccountId = (accountId) => {
    if (Array.isArray(accountId)) {
      let receivablePrint = [];
      receivables.forEach((item) => {
        accountId.forEach((id) => {
          if (item.invoiceNo === id || item.deliveryOrderNo === id) {
            receivablePrint.push(item);
          }
        });
      });
      setReceivablesId(accountId);
      receivablePrint = receivablePrint.map((item) => ({
        ...item,
        noOfDay: item.deliveryDate ? getNoOfDay(item.deliveryDate) : getNoOfDay(item.orderDate)
      }));
      receivablePrint = [...prints, ...receivablePrint];
      setPrints(receivablePrint);
    }
  };

  function monthDiff(dateFrom, dateTo) {
    return (
      new Date(dateTo).getMonth() -
      new Date(dateFrom).getMonth() +
      12 * (new Date(dateTo).getFullYear() - new Date(dateFrom).getFullYear())
    );
  }

  const selectToUpdate = (invoiceNo) => {
    let idList = [...accountID];
    let updateList = [];
    if (!idList.includes(invoiceNo)) {
      idList.push(invoiceNo);
    } else {
      idList = idList.filter((id) => id !== invoiceNo);
    }
    const newList = receivables.map((item) => {
      if (item.invoiceNo === invoiceNo) {
        return {
          ...item,
          paid: !item.paid
        };
      }
      return item;
    });
    newList.forEach((acc) => {
      idList.forEach((id) => {
        if (acc.invoiceNo === id) {
          updateList.push({ invoiceNo: id, paid: acc.paid });
        }
      });
    });
    setAccountUpdate(updateList);
    setReceivables(newList);
    setAccountID(idList);
  };

  const handlePaid = async (data) => {
    const idList = data.map((account) => account.invoiceNo);
    try {
      const response = await updateAccountPaid(idList, { paid: true });
      if (response) {
        return true;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      return false;
    }
  };

  const handleNotPaid = async (data) => {
    const idList = data.map((account) => account.invoiceNo);
    try {
      const response = await updateAccountPaid(idList, { paid: false });
      if (response) {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleCreate = async () => {
    setLoading(true);
    try {
      const response = await createAccount(dataPayable);
      if (response) {
        setDataPayable(payable);
        let newAccounts = [...receivables];
        if (receivables.length === queyAccount.take) {
          newAccounts.pop();
        }
        const account = { ...response.data, no: 1 };
        newAccounts = newAccounts.map((item) => ({ ...item, no: item.no + 1 }));
        newAccounts.unshift(account);
        setReceivables(newAccounts);
        setTotalAmount((amount) => amount + response.data.amount);
        setCount((pre) => pre + 1);
        getListReports();
        setOpenModalEdit(false);
        setLoading(false);
        toast.success('Create successfully!', { autoClose: 2000, theme: 'colored' });
        setAction(false);
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      if (error.response.status === 400) {
        toast.error('Invoice No already exist!', { autoClose: 2000, theme: 'colored' });
      } else {
        toast.error(mess, { autoClose: 2000, theme: 'colored' });
      }
      console.log(error);
      setLoading(false);
      setAction(false);
    }
  };

  const handleUpdatePayable = async () => {
    setLoading(true);
    try {
      const response = await updateAccount(dataPayable);
      if (response) {
        let amount = totalAmount - dataPayable.amount;
        const updatePay = receivables.map((pay) => {
          if (pay.deliveryOrderNo === response.data.deliveryOrderNo) {
            amount = totalAmount - pay.amount;
            return { ...response.data, no: dataPayable.no };
          }
          return pay;
        });
        amount = amount + response.data.amount;
        setTotalAmount(amount);
        setDataPayable(payable);
        setReceivablesId([]);
        setReceivables(updatePay);
        getListReports();
        setLoading(false);
        setOpenModalEdit(false);
        toast.success('Update successfully!', { autoClose: 2000, theme: 'colored' });
        setAction(false);
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setAction(false);
      setLoading(false);
    }
  };

  const handleUpdatePaid = async () => {
    setLoading(true);
    const paidList = accountUpdate.filter((paid) => paid.paid === true);
    const notPaidList = accountUpdate.filter((paid) => paid.paid === false);
    let res1 = true,
      res2 = true;
    if (paidList.length > 0) {
      res1 = await handlePaid(paidList);
    }
    if (notPaidList.length > 0) {
      res2 = await handleNotPaid(notPaidList);
    }
    if (res1 && res2) {
      setReceivablesId([]);
      setAccountUpdate([]);
      setAccountID([]);
      getListReports();
      setLoading(false);
      setOpenModalEdit(false);
      toast.success('Save successfully!', { autoClose: 2000, theme: 'colored' });
      setAction(false);
    } else {
      const mess = 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      setAction(false);
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    setLoading(true);
    let response;
    try {
      if (receivablesId.length > 1) {
        response = await deleteManyPayable(receivablesId);
      } else {
        response = await deletePayable(receivablesId[0] || dataPayable.deliveryOrderNo);
      }
      if (response) {
        setDataPayable(payable);
        setOpenModalDelete(false);
        setReceivablesId([]);
        getListReports();
        await getListAccount(queyAccount);
        toast.success('Delete successfully!', { autoClose: 2000, theme: 'colored' });
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const handleAction = () => {
    if (action) {
      return;
    }
    action = true;
    setAction(true);
    if (dataPayable.paid == 'false') {
      dataPayable.paid = false;
    }
    dataPayable.deliveryOrderNo ? handleUpdatePayable() : handleCreate();
  };

  const getListAccount = async (param) => {
    setLoading(true);
    try {
      const response = await getAccounts(param);
      if (response) {
        const data = response.data.orders.map((item, index) => ({
          ...item,
          no: page * queyAccount.take + index + 1
        }));
        setTotalAmount(response.data.amount);
        setReceivables(data);
        setRender(true);
        setCount(response.data.count);
        setLoading(false);
      }
    } catch (error) {
      const mess =
        error?.response?.data?.errors || error?.response?.data?.message || 'Something went wrong!';
      toast.error(mess, { autoClose: 2000, theme: 'colored' });
      console.log(error);
      setLoading(false);
    }
  };

  const getListAccountBySupplier = async (order) => {
    const param = {
      skip: 0,
      take: 500,
      orderBy: 'desc',
      supplierId: order?.Supplier?.supplierId,
      orderType: 'receivables'
    };
    try {
      const response = await getAccounts(param);
      if (response) {
        let data = [];
        response.data.orders.forEach((item, index) => {
          if (monthDiff(item.deliveryDate, order.deliveryDate) > 0 && !item.paid) {
            data.push(item);
          }
        });
        setOrderLastMonth(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getListReports = async () => {
    if (render) {
      setLoading(true);
    }
    const time = queryStatistic.includes('/');
    const query = time
      ? `range&startDate=${queryStatistic.split('/')[0].trim()}&endDate=${queryStatistic
          .split('/')[1]
          .trim()}`
      : queryStatistic;
    try {
      const response = await getReports(query);
      if (response) {
        setReports(response.data);
        if (render) {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      if (render) {
        setLoading(false);
      }
    }
  };

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
    setDataPayable(payable);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setReceivablesId([]);
    setReceivables([]);
    setAccountUpdate([]);
    setPrints([]);
    if (newValue === 0) {
      const newQuery = { ...accountQuery, orderType: 'receivables' };
      setQueryAccount(newQuery);
      setQueryStatistic('today');
    }
    if (newValue === 2) {
      const newQuery = { ...accountQuery, orderType: 'payable' };
      setQueryAccount(newQuery);
      setOrderLastMonth([]);
    }
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  const checkProfix =
    report?.profit >= 0
      ? '$' + roundNumber(report?.profit)
      : '-$' + roundNumber(Math.abs(report?.profit));

  const getNoOfDay = (date) => {
    let diffInDays = 0;
    if (date) {
      const startDay = new Date(date);
      const nowDate = new Date();
      const oneDay = 1000 * 60 * 60 * 24;
      const diffInTime = nowDate.getTime() - startDay.getTime();
      diffInDays = Math.ceil(diffInTime / oneDay);
    }
    if (diffInDays === 0) {
      diffInDays += 1;
    }
    return diffInDays.toString();
  };

  const getListSuppliers = async () => {
    try {
      const response = await getSupplier({
        skip: 0,
        take: 10,
        companyName: '',
        type: 'customer',
        isNoneLimit: true
      });
      if (response) {
        setSupplier(response.data.suppliers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columnsReceivables = [
    { field: 'no', headerName: 'No.', type: 'number', minWidth: 50, maxWidth: 50 },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 5,
      minWidth: 140,
      valueGetter: (params) => params.row.Supplier?.companyName
    },
    {
      field: 'invoiceNo',
      headerName: 'Invoice No.',
      flex: 3,
      minWidth: 150
    },
    {
      field: 'createInvoiceAt',
      headerName: 'Create Time',
      type: 'number',
      flex: 2,
      minWidth: 110,
      valueGetter: (params) => convertDate(params.row.deliveryDate, 'DD/MM/YYYY')
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      flex: 2,
      minWidth: 120,
      valueGetter: (params) => roundNumber(params.row.amount)
    },
    {
      field: 'noOfDay',
      headerName: 'No. of Days',
      flex: 2,
      type: 'number',
      minWidth: 120,
      valueGetter: (params) => getNoOfDay(params.row.deliveryDate)
    },
    {
      field: 'paid',
      headerName: 'Paid?',
      minWidth: 90,
      maxWidth: 90,
      renderCell: (params) => (
        <div className="text-align checkbox-receiables">
          <label
            htmlFor={params.row.invoiceNo}
            onClick={() => selectToUpdate(params.row.invoiceNo)}
          ></label>
          <input type="checkbox" id={params.row.invoiceNo} readOnly checked={params.row.paid} />
        </div>
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      maxWidth: 80,
      minWidth: 80,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            className={`${receivablesId.length > 1 ? 'disable' : ''}`}
          >
            <DeleteIcon
              sx={{ color: '#d32f2f' }}
              onClick={() => {
                setOpenModalDelete(true);
                setDataPayable(params.row);
              }}
            />
          </Box>
        );
      }
    }
  ];

  const columnsPayable = [
    { field: 'no', headerName: 'No.', type: 'number', minWidth: 50, maxWidth: 50 },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 5,
      minWidth: 140,
      valueGetter: (params) => params.row.Supplier?.companyName || params.row.Supplier?.companyName
    },
    {
      field: 'invoiceNo',
      headerName: '	Invoice No.',
      flex: 2,
      minWidth: 120
    },
    {
      field: 'amount',
      headerName: '	Amount',
      type: 'number',
      flex: 2,
      minWidth: 120,
      valueGetter: (params) => roundNumber(params.row.amount)
    },
    {
      field: 'noOfDay',
      headerName: 'No. of Days',
      flex: 5,
      type: 'number',
      minWidth: 120,
      valueGetter: (params) => getNoOfDay(params.row.orderDate)
    },
    {
      field: 'paid',
      headerName: 'Paid?',
      minWidth: 90,
      maxWidth: 90,
      renderCell: (params) => (
        <div className="text-align">{params.row.paid ? 'Paid' : 'Unpaid'}</div>
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      maxWidth: 80,
      minWidth: 80,
      renderCell: (params) => {
        return (
          <div className={`action_btn ${receivablesId.length > 1 ? 'disable' : ''}`}>
            <EditIcon
              onClick={() => {
                setOpenModalEdit(true);
                setDataPayable(params.row);
              }}
            />
            <DeleteIcon
              sx={{ color: '#d32f2f' }}
              onClick={() => {
                setOpenModalDelete(true);
                setDataPayable(params.row);
              }}
            />
          </div>
        );
      }
    }
  ];

  return (
    <div className="AccountPage">
      {loading ? <Loading open={loading} /> : null}
      {openModalDelete ? (
        <ModalDelete
          open={openModalDelete}
          handleClose={() => {
            setOpenModalDelete(false);
            setDataPayable(payable);
          }}
          handleDelete={handleDeleteAccount}
          page="account"
        />
      ) : null}
      {openModalEdit ? (
        <ModalEdit
          open={openModalEdit}
          handleClose={handleCloseModalEdit}
          data={dataPayable}
          setData={setDataPayable}
          handleAction={handleAction}
          page="PAYABLE"
        />
      ) : null}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Receivables" {...a11yProps(0)} />
            <Tab label="Refunds" {...a11yProps(1)} />
            <Tab label="Payable" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} sx={{ pt: '16px' }} index={0}>
          <div className="Orderpage_info">
            <Box sx={{ width: '135px' }}>
              <SelectDropdown
                data={time}
                selectType="filterDate"
                textDefault="Today"
                setData={(data) => setQueryStatistic(data)}
              />
            </Box>
          </div>
          <div className="total_table">
            <div className="total_revenue_item item">
              <div className="content">
                <img src={Revenue} alt="" />
                <div className="content_info">
                  <p>Total Revenue</p>
                  <h3>${report.totalRevenue ? roundNumber(report.totalRevenue) : 0}</h3>
                </div>
              </div>
            </div>
            <div className="number_of_item item">
              <div className="content">
                <img src={Order} alt="" />
                <div className="content_info">
                  <p>No. of Orders</p>
                  <h3>{report?.noOfOrders ? report.noOfOrders : 0}</h3>
                </div>
              </div>
            </div>
            <div className="number_of_item item">
              <div className="content">
                <img src={Reciever} alt="" />
                <div className="content_info">
                  <p>No. of Receivables</p>
                  <h3>{report?.noOfReceivable ? report.noOfReceivable : 0}</h3>
                </div>
              </div>
            </div>
            <div className="number_of_item item">
              <div className="content">
                <img src={Payable} alt="" />
                <div className="content_info">
                  <p>No. of Payable</p>
                  <h3>{report.noOfPayable ? report.noOfPayable : 0}</h3>
                </div>
              </div>
            </div>
            <div className="profit_item item">
              <div className="content">
                <img src={Profit} alt="" />
                <div className="content_info">
                  <p>Profit/Loss</p>
                  <h3>{report?.profit ? checkProfix : 0}</h3>
                </div>
              </div>
            </div>
            <div className="profit_item item">
              <div className="content">
                <img src={Reciever} alt="" />
                <div className="content_info">
                  <p>Receivables ($)</p>
                  <h3>${report?.receivable ? roundNumber(report?.receivable) : 0}</h3>
                </div>
              </div>
            </div>
            <div className="profit_item item">
              <div className="content">
                <img src={Payable} alt="" />
                <div className="content_info">
                  <p>Payable ($)</p>
                  <h3>${report?.payable ? roundNumber(report?.payable) : 0}</h3>
                </div>
              </div>
            </div>
          </div>
          <div id="receivables" ref={componentRef}>
            <AccountPrint data={toPrints} gstDefault={gstDefault} orderLastMonth={orderLastMonth} />
          </div>
          <div className="Orderpage_info">
            <div className="filter_action">
              <div className="company info">
                <span>Company Name</span>
                <div className="dropdown">
                  <SelectDropdown
                    data={supplier}
                    selectType="filterCompany"
                    textDefault="All"
                    row={{}}
                    setData={(e) =>
                      setQueryAccount((pre) => {
                        return { ...pre, supplierId: e ? +e : '' };
                      })
                    }
                  />
                </div>
              </div>
              <div className="OrderPage_search">
                <div className="OrderPage_search_box">
                  <div>
                    <SearchIcon />
                  </div>
                  <DebounceInput
                    debounceTimeout={1000}
                    placeholder="Invoice No."
                    value={queyAccount.invoiceNo}
                    onChange={(e) => {
                      setQueryAccount((pre) => ({ ...pre, invoiceNo: e.target.value }));
                    }}
                  />
                  {queyAccount.invoiceNo.length > 0 && (
                    <ClearIcon
                      sx={{ cursor: 'pointer', opacity: 0.5 }}
                      onClick={() => setQueryAccount((pre) => ({ ...pre, invoiceNo: '' }))}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="OrderPage_table">
            <Table
              columns={columnsReceivables}
              rows={receivables}
              pageSize={queyAccount.take}
              setPage={setPage}
              totalRow={count}
              loading={loading}
              data={receivablesId}
              onSelectionCheckBox={selectAccountId}
              getRowId={(row) => {
                return row.deliveryOrderNo || row.no;
              }}
            />
          </div>
          <Box
            className="account-bottom"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '16px',
              flexWrap: 'wrap',
              marginTop: '16px'
            }}
          >
            <div className="OrderPage_btn btn_action">
              <Button
                onClick={handleUpdatePaid}
                className={`${accountID.length < 1 ? 'disable' : ''}`}
                disabled={accountID.length < 1}
                variant="contained"
              >
                Save
              </Button>
              <Button
                className={`${receivablesId.length === 0 ? 'disable' : ''}`}
                disabled={receivablesId.length === 0}
                onClick={handlePrint}
                variant="contained"
              >
                Print
              </Button>
              <Button
                className={`${receivablesId.length === 0 ? 'disable' : ''}`}
                disabled={receivablesId.length === 0}
                onClick={() => {
                  setOpenModalDelete(true);
                }}
                color="error"
                sx={{ backgroundColor: '#d32f2f' }}
                variant="contained"
              >
                Delete selected
              </Button>
            </div>
            <div className="total_box">
              <h3>Total Amount:</h3>
              <div>${roundNumber(totalAmount)}</div>
            </div>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Refunds />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PayableComponent
            companies={supplier}
            loading={loading}
            setReceivablesId={setReceivablesId}
            receivablesId={receivablesId}
            rowTotal={count}
            setQueryAccount={setQueryAccount}
            queyAccount={queyAccount}
            setOpenModalEdit={setOpenModalEdit}
            setOpenModalDelete={setOpenModalDelete}
            columnsPayable={columnsPayable}
            setPage={setPage}
            selectAccountId={selectAccountId}
            receivables={receivables}
            prints={toPrints}
            orderLastMonth={orderLastMonth}
            totalRecei={totalAmount}
          />
        </TabPanel>
      </Box>
    </div>
  );
}
