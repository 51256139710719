import { API_BASE, parseQuery } from './apiConfig';

export const getOrders = async (query) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  let orderStatus = null;
  if (query.orderStatus.length > 0) {
    orderStatus = '&' + query.orderStatus.map((status) => `orderStatus=${status}`).join('&');
  }
  const url = `api/orders${parseQuery(query)}${orderStatus}`;

  const { data } = await API_BASE.get(url, token);
  return data;
};

export const createOrder = async (order) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.post('api/order', order, token);
  return data;
};

export const deleteOrder = async (orderId) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.delete(`api/order/${orderId}`, token);
  return data;
};

export const deleteOrders = async (listorderId) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  let url = '';
  listorderId.forEach((id, index) => {
    if (index < listorderId.length - 1) {
      url += `id=${id}&`;
    } else {
      url += `id=${id}`;
    }
  });
  const { data } = await API_BASE.delete(`api/order?${url}`, token);
  return data;
};

export const updateOrder = async (orderId, order) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.patch(`api/order/${orderId}`, order, token);
  return data;
};

export const updateOrders = async (listOrderId, orderList) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  let url = '';
  listOrderId.forEach((id, index) => {
    if (index < listOrderId.length - 1) {
      url += `id=${id}&`;
    } else {
      url += `id=${id}`;
    }
  });
  const { data } = await API_BASE.patch(`api/order?${url}`, orderList, token);
  return data;
};

export const printOrder = async (order, orderIdList) => {
  let url = '';
  orderIdList.forEach((id, index) => {
    if (index < orderIdList.length - 1) {
      url += `id=${id}&`;
    } else {
      url += `id=${id}`;
    }
  });
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.patch(`api/orders/order-status?${url}`, order, token);
  return data;
};

export const refundOrder = async (orderId, order) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const userId = localStorage.getItem('userId');
  const { data } = await API_BASE.patch(
    `api/order/refundfish/${orderId}?userId=${userId}`,
    order,
    token
  );
  return data;
};

export const getOrdersRefunds = async (query) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const url = `api/accounts/refund-history${parseQuery(query)}`;
  const { data } = await API_BASE.get(url, token);
  return data;
};

export const getGST = async () => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.get('api/tax', token);
  return data;
};

export const updateGST = async (body) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.patch('api/tax', body, token);
  return data;
};

export const addTaxOrder = async (orderId, body) => {
  const token = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  const { data } = await API_BASE.patch(`api/order/add-tax-order/${orderId}`, body, token);
  return data;
};
